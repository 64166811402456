import React from 'react';

// PROTOTYPE
import ListPrototype from '../prototype/ListPrototype';

// PARTS
import List from 'parts/interface/list/List';
const { Col } = List;

// COLUMNS
const COLUMNS = ({
  application_name,
  config_name,
  config_key,
  config_value
}) => [
  <Col
    cols={{ lg: 6 }}
    label="Application Name"
    value={application_name}
  />,
  <Col
    cols={{ lg: 6 }}
    label="Configuration Name"
    value={config_name}
  />,
  <Col
    cols={{ lg: 6 }}
    label="Configuration Unique Key"
    value={config_key}
  />,
  <Col
    cols={{ lg: 6 }}
    label="Configuration Value"
    value={config_value}
  />,
]

// MAIN COMPONENT
const ApplicationConfiguration = () => <ListPrototype
  routeKey="applicationConfiguration"
  idKey="dppc_config_id"
  title="Application Configuration"
  columns={COLUMNS}
/>

export default ApplicationConfiguration
