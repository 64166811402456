import React, { useCallback, useContext } from 'react';

// DEPENDENCIES
import { compose } from 'redux';

// GLOBAL VARIABLES
import { DATE } from 'defaults.js';
import { NUMBER_FORMATS } from 'numberFormats.js';

// GLOBAL FUNCTIONS
import { doCallback } from 'functions.js';

// CONTEXT
import { DropdownContext } from 'core/tools/dropdown/helpers/dropdownContext';

// HELPERS
import { dateFormat } from '../helpers/dateFormat.js';
import { dateValidation } from '../helpers/dateValidation.js';

// CORE COMPONENTS
import Calendar from 'core/tools/calendar/Calendar';

// LOCAL COMPONENTS
import Select from '../../select/Select';

// STYLES
import styles from './datePicker.module.scss';

// MAIN COMPONENT
const DatePicker = compose(
  dateFormat,
  dateValidation
)(({
  useDateRange,
  allowCustom = DATE.allowCustom,
  dropdown = {},
  toggle = {},
  ...props
}) => (
  <Select
    className={styles.select}
    value={props.currentDate}
    allowCustom={allowCustom}
    dropdown={{
      fullWidth: false,
      ...dropdown
    }}
    toggle={{
      icon: 'calendar',
      placeholder: NUMBER_FORMATS.date.placeholder,
      input: {
        htmlSize: props.inline ? 10 : undefined
      },
      useDummy: false,
      ...toggle
    }}
    {...props}
  >
    <DateDropdown {...props} />
  </Select>
))

// CHILD COMPONENTS
const DateDropdown = ({
  currentDate,
  startDate,
  endDate,
  minDate,
  maxDate,
  onSelect
}) => {

  // CONTEXT
  const { show, setShow } = useContext(DropdownContext) || {};

  // CALLBACKS
  const handleSelect = useCallback(
    value => {
      doCallback(onSelect, value);
      setShow(false);
    },
    [onSelect, setShow]
  )

  // RENDER
  return (
    <Calendar
      className={styles.picker}
      show={show}
      currentDate={currentDate}
      startDate={startDate}
      endDate={endDate}
      minDate={minDate}
      maxDate={maxDate}
      onSelect={handleSelect}
    />
  )
}

// EXPORT
export default DatePicker;
