import React from 'react';

// DEPENDENCIES
import { isFunction } from 'lodash';

// GLOBAL VARIABLES
import { BUTTON } from 'defaults.js';

// CORE COMOPNENTS
import Form from 'core/form/Form';

// LOCAL COMPONENTS
import CardBody from './CardBody';
import CardFooter from './CardFooter';

// STYLES
import styles from './cardForm.module.scss';

// MAIN COMPONENT
const CardForm = ({
  children,
  body = {},
  buttons: {
    show: showButtons = true,
    cancel: {
      icon: cancelIcon,
      show: showCancel = true,
      ...cancel
    } = {},
    submit: {
      icon: submitIcon,
      show: showSubmit = true,
      ...submit
    } = {},
  } = {},
  disabled,
  ...props
}) => (
  <Form {...props}>
    {formikBag => (<>
      <CardBody>
        <Form.Body disabled={disabled} {...body}>
          {isFunction(children) ? children(formikBag) : children}
        </Form.Body>
      </CardBody>
      {showButtons &&
        <CardFooter>
          <Form.Row>
            <Form.Col className={styles.col} xs={false} md={8} />
            <Form.Col xs={24} md={16}>
              <div className={styles.buttons}>
                <Form.Row>
                  {showCancel &&
                    <Form.Col
                      xs={showSubmit ? 12 : 24}
                      md={12}
                    >
                      <Form.Button.Cancel
                        icon={Object.assign({}, {
                          use: BUTTON.cancel.icon,
                          order: 1
                        }, cancelIcon)}
                        justify="between"
                        disabled={disabled}
                        {...cancel}
                      />
                    </Form.Col>
                  }
                  {showSubmit &&
                    <Form.Col
                      xs={showCancel ? 12 : 24}
                      md={12}
                    >
                    <Form.Button.Save
                        variant="primary"
                        label="Submit"
                        icon={Object.assign({}, {
                          use: 'arrow-right'
                        }, cancelIcon)}
                        disabled={disabled}
                        {...submit}
                      />
                    </Form.Col>
                  }
                </Form.Row>
              </div>
            </Form.Col>
          </Form.Row>
        </CardFooter>
      }
    </>)}
  </Form>
)

// EXPORT
export default CardForm;
