import React from 'react';

// DEPENENCIES
import clsx from 'clsx';

// BOOTSTRAP COMOPNENTS
import { Badge as BSBadge } from 'react-bootstrap';

// CORE COMPONENTS
import Sprite from 'core/tools/Sprite';
import Tooltip from 'core/tools/Tooltip';

// STYLES
import styles from './badge.module.scss';

// MAIN COMPONENT
const Badge = ({
  children,
  className,
  array,
  variant,
  label = children,
  tip,
  error,
  ...props
}) => error ? (
  <Sprite
    as={false}
    icon="warning"
    fill="danger"
  />
) : !label ? null : (
  <Tooltip
    wrapperClassName="badge-tooltip"
    variant={variant}
    tip={tip}
  >
    <BSBadge
      className={clsx(
        styles.badge,
        styles[variant],
        className
      )}
      {...props}
    >
      {label}
    </BSBadge>
  </Tooltip>
)

// EXPORT
export default Badge;
