import React, { forwardRef, useCallback, useMemo } from 'react';

// DEPENDENCIES
import { isFunction } from 'lodash';
import * as yup from 'yup';

// GLOBAL FUNCTIONS
import { blankToNull, bugLog, valueLog } from 'functions.js';

// LOCAL VARIABLES
const VALIDATION = {
  disclaimer:  (message = 'You must agree to continue.') => yup.mixed().oneOf(['true', '1'], message),
  email:       (message = 'Invalid email.') => yup.string().email(message),
  charkey:     (message = 'Invalid character key.') => yup.string().matches(/^[A-Z]{6}$/, message),
  passkey:     (message = 'Invalid password.') => yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, {
    message,
    excludeEmptyString: true
  }),
  alphanumeric: (message = 'Only alphanumerics are allowed.') => yup.string().matches(/^[A-Za-z0-9]+$/, message),
  postalCode:  (message = 'Invalid postal code.') => yup.string().matches(/^\d{5}(?:[-\s]\d{4})?$/, {
    message,
    excludeEmptyString: true
  }),
  required:    (message = 'Required field.') => yup.mixed().required(message),
  true:        (message = `User must select 'Yes' to continue.`) => yup.mixed().oneOf(['true', '1'], message),
  tel:         (message = 'Invalid phone number.') => yup.string().matches(/^([2-9]{1}[0-9]{2}-[2-9]{1}[0-9]{2}-[0-9]{4})|([2-9]{1}[0-9]{2}[2-9]{1}[0-9]{2}[0-9]{4})$/, {
  //tel:       (message = 'Invalid phone number.') => yup.string().matches(/^\+1 \([2-9]{1}[0-9]{2}\) [2-9]{1}[0-9]{2}-[0-9]{4}$/, {
      message,
    excludeEmptyString: true
  }),
  text:        () => yup.string().ensure(),
  year:        (message = 'Invalid year.') => yup.number().typeError(message).nullable().min(1900, message).transform(blankToNull)
  //year:        yup.number().typeError('Invalid year.').nullable().min(1900, 'Invalid year.').max(new Date().getFullYear(), 'Invalid year.').transform(blankToNull)
}

// MAIN COMPONENT
export const inputValidation = Component => forwardRef((props, ref) => {

  // PROPS
  let {
    name,
    type,
    schema,
    required,
    requiredMessage,
    errorMessage,
    validationSchema = VALIDATION[schema] || VALIDATION[type],
    disabled,
    plaintext,
    readOnly,
    debug
  } = props;

  // MEMOS
  validationSchema = useMemo(
    () => {
      const schema = isFunction(validationSchema) ? validationSchema(errorMessage) : validationSchema;
      return disabled || plaintext || readOnly ? undefined : required ? VALIDATION.required(requiredMessage).concat(schema || VALIDATION.text()) : schema;
    },
    [validationSchema, required, requiredMessage, errorMessage, disabled, plaintext, readOnly]
  )

  // CALLBACKS
  const handleValidate = useCallback(
    (value = '') => {
      value = value === null ? '' : value.toString();
      valueLog('validate', debug, name, value);
      if (!!validationSchema) {
        bugLog(validationSchema, debug);
        return validationSchema.validate(value)
          .then(response => {
            bugLog('valid', debug, name);
            return null;
          })
          .catch(({ errors = [] }) => {
            bugLog('error', debug, name, errors[0]);
            return errors[0]
          })
      } else {
        bugLog('no validation', debug, name);
        return null;
      }
    },
    [name, validationSchema, debug]
  )

  // RENDER
  return <Component {...props} validationSchema={validationSchema} onValidate={handleValidate} ref={ref} />;
})
