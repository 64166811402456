import React, { useMemo } from 'react';

// DEPENDENCIES
import { find } from 'lodash';

// LOCAL COMPONENTS
import Badge from '../prototype/Badge';

// MAIN COMPONENT
const CodeBadge = ({
  children,
  options = [],
  value = children,
  record,
  ...props
}) => {

  // MEMOS
  const { variant = 'secondary', label, code } = useMemo(
    () => find(options, option => option.key === value || option.code === value || option.label === value) || {},
    [options, value]
  )

  // RENDER
  return (
    <Badge
      variant={variant}
      label={code || label}
      tip={!!code ? label : undefined}
      {...props}
    />
  )
}

// EXPORT
export default CodeBadge;
