// DEPENDENCIES
import { useMemo } from "react";
import { useLocation } from 'react-router-dom';

// HOOK
const useRouteCheck = key => {
  const location = useLocation();

  return useMemo(
    () => location.pathname.split('/').includes(key),
    [location, key]
  );
}

// EXPORT
export default useRouteCheck;
