import React from 'react';

// GLOBAL FUNCTIONS
import { formatDateTime } from 'functions';

// TOOLS
import Badge from 'tools/badge/Badge';

// PROTOTYPE
import ListPrototype from '../prototype/ListPrototype';

// PARTS
import List from 'parts/interface/list/List';
const { Col } = List;

// COLUMNS
const COLUMNS = ({
  application_name,
  char_key,
  sms_code_verify,
  created_date,
  updated_date
}) => [
  <Col
    cols={{ lg: 7 }}
    label="Application Name"
    value={application_name}
  />,
  <Col
    cols={{ lg: 4 }}
    label="Unique Key"
    value={char_key}
  />,
  <Col
    cols={{ lg: 3 }}
    as={Badge.YesNo}
    label="SMS Code Verification"
    value={sms_code_verify}
  />,
  <Col
    cols={{ lg: 5 }}
    label="Created Date/Time"
    value={formatDateTime(created_date)}
  />,
  <Col
    cols={{ lg: 5 }}
    label="Updated Date/Time"
    value={formatDateTime(updated_date)}
  />
]

// MAIN COMPONENT
const Applications = () => <ListPrototype
  routeKey="application"
  columns={COLUMNS}
/>

export default Applications;
