import React from 'react';

// CORE COMPONENTS
import Form from 'core/form/Form';

// PROTOTYPE
import EditPrototype from "../prototype/EditPrototype";

// GLOBAL VARIABLES
import { makePath } from "functions";
import { PATHNAMES } from "pathnames";

const DEFAULT_DATA = {
    active_flag: false,
    delete_flag: false,
}

const STATUS_OPTIONS = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
];

// MAIN COMPONENT
const DealershipGroups = () => (
    <EditPrototype
        routeKey="dealershipGroups"
        idKey="dealer_group_id"
        form={{ initialValues: DEFAULT_DATA }}
        redirect={makePath(PATHNAMES.dealershipGroups)}
    >
        <Form.Control
            name="dealer_group_name"
            label="Dealership Group Name"
            required
        />
        <Form.Control
            name="address_line1"
            label="Address Line 1"
        />
        <Form.Control
            name="address_line2"
            label="Address Line 2"
        />
        <Form.Control
            name="city"
            label="City"
        />
        <Form.Select
            name="us_state_id"
            label="State"
            lookup="usaStates"
            optionKeys={{ label: 'state_name', value: 'us_state_id'}}
        />
        <Form.Control
            name="postal_code"
            label="Zip Code"
            schema="postalCode"
        />
        <Form.Checklist
            name="active_flag"
            label="Active?"
            type="radio"
            options={STATUS_OPTIONS}
            required
        />
        <Form.Checklist
            name="delete_flag"
            label="Delete?"
            type="radio"
            options={STATUS_OPTIONS}
            required
        />
    </EditPrototype>
);

export default DealershipGroups;
