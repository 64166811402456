import React, { useMemo } from 'react';

// DEPENENCIES
import { connect } from 'react-redux';

// LOCAL COMPONENTS
import SelectFilter from './SelectFilter';

// MAIN COMPONENT
const DealershipFilter = connect(
  ({ user: { stores } }) => ({ stores })
)(({
  // REDUX STATE
  stores,
  // REST
  name="dealershipStoreID",
  label="Dealership",
  placeholder="Select dealership",
  ...props
}) => {

  // MEMOS
  const options = useMemo(
    () => stores.map(({dealer_name: label, make_dealer_id: value})=>
        ({ label, value })),
    [stores]
  )

  // RENDER
  return (
    <SelectFilter
      name={name}
      label={label}
      placeholder={placeholder}
      options={[{label: 'All', value: ''}, ...options]}
      {...props}
    />
  )
})

// EXPORT
export default DealershipFilter;
