import React, { useContext, useMemo } from 'react';

// HELPERS
import { ListContext } from '../../../helpers/getListData.js';

// CORE COMPONENTS
import Form from 'core/form/Form';

// MAIN COMPONENT
const ResultCount = () => {

  // CONTEXT
  const {
    plural,
    params: {
      page,
      rowsOnPage
    },
    state,
    records: {
      total = 0,
      list = []
    }
  } = useContext(ListContext) || {};

  // MEMOS
  const prevEntries = useMemo(
    () => rowsOnPage ? (parseInt(page, 10) - 1) * parseInt(rowsOnPage, 10) : 0,
    [page, rowsOnPage]
  )

  // RENDER
  return (
    <Form.Col>
      <div>
        {state === 'fetching' ? (
          <em>Looking up {plural}...</em>
        ) : total === 0 ?  (
          <em>No {plural} found.</em>
        ) : (<>
          Showing <strong>{prevEntries + 1}</strong>&ndash;<strong>{prevEntries + list.length}</strong> of <strong>{total}</strong>
        </>)}
      </div>
    </Form.Col>
  )
}

// EXPORT
export default ResultCount;
