import React from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { trim } from 'lodash';

// BOOTSTRAP COMOPNENTS
import { Col, Row } from 'react-bootstrap';

// STYLES
import styles from './listCol.module.scss';

// MAIN COMPONENT
const ListCol = ({
  as: Component,
  children,
  className,
  cols,
  label,
  value = children || '',
  list,
  ...props
}) => (
  <Col
    className={clsx(
      styles.container,
      className
    )}
    xs={24}
    {...cols}
  >
    <Row className={styles.row}>
      {label &&
        <Col
          className={styles.label}
          xs={8}
        >
          {label}
        </Col>
      }
      <Col
        className={clsx(
          'list-col',
          styles.col
        )}
        xs={!!label ? 16 : 24}
        lg={24}
      >
        {value === null || value === undefined || trim(value.toString()) === '' ? (
          <span className={styles.empty}>&ndash;</span>
        ) : Component ? (
          <Component {...props}>{value}</Component>
        ) : list ? (
          <div className={styles.list}>
            {value.toString().split(',').map((s, i) => <div key={i}>{s}</div>)}
          </div>
        ) : value}
      </Col>
    </Row>
  </Col>
)

// EXPORT
export default ListCol;
