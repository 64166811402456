import React from 'react';

// DEPENDENCIES
import { compose } from 'redux';

// GLOBAL VARIABLES
import { NUMBER_FORMATS } from 'numberFormats.js';

// HELPERS
import { timeFormat } from '../helpers/timeFormat.js';
import { timeValidation } from '../helpers/timeValidation.js';

// LOCAL COMPONENTS
import Control from '../../../Control';

// STYLES
//import styles from './timePicker.module.scss';

// MAIN COMPONENT
const DateDropdown = compose(
  timeFormat,
  timeValidation
)(props => (
  <Control
    placeholder={NUMBER_FORMATS.time.placeholder}
    {...props}
  />
))

// EXPORT
export default DateDropdown;
