import React from 'react';

// DEPENDENCIES
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';
import { PATHNAMES } from 'pathnames.js';

// GLOBAL FUNCTIONS
import { makePath } from 'functions.js';

// CORE COMPONENTS
import Form from 'core/form/Form';

// PARTS
import Page from 'parts/authentication/Page';

// MAIN COMPONENT
const NewPassword = compose(
  withRouter
)(({
  history,
  match: {
    params: {
      token
    }
  }
}) => (
  <Page
    documentTitle="Password"
    title="Password reset"
    subtitle="Enter your new password below."
    formArgs={{
      endpoint: ENDPOINTS.session.newPassword,
      onSuccess: () => history.push({
        pathname: makePath(PATHNAMES.login),
        state: {
          alerts: [
            {
              variant: 'success',
              message: 'Password updated.'
            }
          ]
        }
      }),
      loadingMessage: 'Updating password',
      errorMessage: 'Unable to update password.'
    }}
  >
    <Form.Body.Vertical>
      <Form.Control
        hidden
        readOnly
        name="token"
        value={token}
      />
      <Form.Control
        confirmed
        name="newPassword"
        label="New Password"
        type="password"
        schema="passkey"
      />
    </Form.Body.Vertical>
    <Page.Footer>
      <Page.Button.Back
        to={makePath(PATHNAMES.login)}
      />
      <Page.Button.Submit
        label="Update Password"
      />
    </Page.Footer>
  </Page>
))

// EXPORT
export default NewPassword;
