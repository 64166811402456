import React, { createContext, useCallback, useEffect, useRef } from 'react';

// DEPENDENCIES
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import { compose } from 'redux';

// GLOBAL FUNCTIONS
import { toggleMenu } from 'actions.js';

// CONTEXT EXPORT
export const MenuContext = createContext(null);

// MAIN COMPONENT
export const menuContext = Component => compose(
  connect(
    ({ menu }) => ({ menu }),
    {toggleMenu}
  ),
  withRouter
)(({
  // REDUX STATE
  menu,
  // REDUX DISPATCH
  toggleMenu,
  // REACT ROUTER
  history,
  location: {
    pathname
  },
  match,
  staticContext,
  // REST
  ...props
}) => {

  // REFS
  const containerRef = useRef();

  // CALLBACKS
  const handleResize = useCallback(
    () => {
      toggleMenu(false);
    },
    [toggleMenu]
  )
  const handleClick = useCallback(
    ({ target }) => {
      if (!containerRef.current) return;
      if (!containerRef.current.contains(target)) toggleMenu(false);
    },
    [containerRef, toggleMenu]
  )

  // EFFECTS
  useEffect(
    () => {
      window.addEventListener("resize", handleResize);
      return window.removeEventListener("resize", handleResize);
    },
    [handleResize]
  )
  useEffect(
    () => {
      window.addEventListener('click', handleClick);
      return window.removeEventListener('click', handleClick);
    },
    [handleClick]
  )
  useEffect(
    () => {
      toggleMenu(false)
    },
    [toggleMenu, pathname]
  )

  // RENDER
  return(
    <MenuContext.Provider value={{
      containerRef,
      show: menu,
      toggle: toggleMenu
    }}>
      <Component {...props} />
    </MenuContext.Provider>
  )
})
