import React from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { connect } from 'react-redux';
import { compose } from 'redux';

// GLOBAL FUNCTIONS
import { sidebarActions } from 'actions.js';

// CORE COMPONENTS
import Button from 'core/tools/Button';

// STYLES
import styles from './sidebarHeader.module.scss';

// MAIN COMPONENT
const SidebarHeader = compose(
  connect(
    null,
    { ...sidebarActions }
  )
)(({
  title,
  onClose,
  // REDUX DISPATCH
  close
}) => (
  <div className={styles.container}>
    <h3 className={styles.title}>{title}</h3>
    <div className={styles.tool}>
      <Button
        className={clsx(
          styles.button,
          styles.close
        )}
        variant="custom"
        icon="times"
        onClick={() => close()}
        square
      />
    </div>
  </div>
))

// EXPORT
export default SidebarHeader;
