import React, { useMemo } from 'react';

// DEPENDENCIES
import { camelCase, isFunction, isObject, lowerCase, snakeCase, startCase } from 'lodash';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';
import { PATHNAMES } from 'pathnames.js';

// GLOBAL FUNCTIONS
import { formatDateTime, makePath } from 'functions';

// HELPERS
import useRouteCheck from 'parts/interface/helpers/routeCheck';

// CORE COMPONENTS
import Form from 'core/form/Form';

// PARTS
import Record from 'parts/interface/record/Record';

// MAIN COMPONENT
/**
 * @param {React.Children} children - contents of Form.Body
 * @param {String} routeKey - unique key used to determine subordinate props, endpoints, and pathnames
 * @param {String/Object} [endpoint] - basepath URI or collection of URI's for the API endpoint
 * @param {String} [idKey] - name of the record object's unique id param 
 * @param {String} [label] - user-friendly label (lowercase) for record type
 * @param {String} [plural] - user-friendly label (lowercase for multiple records
 * @param {String} [title] - user-friendly title (startcase) for display in the browser tab
 * @param {String} [redirect] - path to which the user is redirected, after leaving the Edit page
 * @param {Object} [form] - props passed to the Record.Form component
 * @param {Object} [card] - props passed to the Record.Card component
 * @param props {Object}
 * @returns {JSX.Element}
 * @constructor
 */
const EditPrototype = ({
  children,
  routeKey,
  endpoint      = ENDPOINTS.record[routeKey],
  idKey         = `${snakeCase(routeKey)}_id`,
  label         = lowerCase(routeKey),
  plural        = `${label}s`,
  title         = startCase(plural),
  redirect      = makePath(PATHNAMES[camelCase(plural)]),
  form = {},
  card = {},
  ...props
}) => {

  // HOOKS
  const isEdit = useRouteCheck('edit');
  const isView = useRouteCheck('view');

  // MEMOS
  const [
    recordEndpoint,
    formEndpoint
  ] = useMemo(
    () => isObject(endpoint) ? [
      endpoint.get,
      isEdit ? endpoint.put : endpoint.add
    ] : [
      endpoint,
      endpoint
    ],
    [endpoint, isEdit]
  )

  // RENDER
  return (
    <Record
      idKey={idKey}
      label={label}
      title={title}
      endpoint={recordEndpoint}
      {...props}
    >{({ record: { created_date, updated_date } }) => (
      <Record.Form
        endpoint={isView ? '-' : formEndpoint}
        redirect={redirect}
        {...form}
      >{formikBag => (
        <Record.Card.Edit>
          <Form.Body>
            {isFunction(children) ? children(formikBag) : children}
            {isEdit && <>
              {created_date && <>
                <Record.Card.Divider />
                <Form.Col>
                  <Record.Card.Subtitle>Log</Record.Card.Subtitle>
                </Form.Col>
                <Form.Control
                  label="Created Date/Time"
                  value={formatDateTime(created_date)}
                  plaintext
                />
              </>}
              {updated_date && <>
                <Form.Control
                  label="Updated Date/Time"
                  value={formatDateTime(updated_date)}
                  plaintext
                />
              </>}
            </>}
          </Form.Body>
        </Record.Card.Edit>
      )}</Record.Form>
    )}</Record>
  )
}

export default EditPrototype;

