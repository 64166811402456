import React from 'react';

// DEPENDENCIES
import { connect } from 'react-redux';
import { compose } from 'redux';

// GLOBAL VARIABLES
import { COPYRIGHT, SITE_TITLE, VERSION } from 'globals.js';

// GLOBAL FUNCTIONS
import { alertFunctions } from 'functions.js';

// HELPERS
import { documentTitle } from 'helpers/documentTitle';

// ASSETS
import Logo from 'img/logo.png';

// CORE COMPONENTS
import Form from 'core/form/Form';
import Alert from 'core/tools/alert/Alert';
import Button from 'core/tools/Button';

// STYLES
import styles from './page.module.scss';

// MAIN COMPONENT
const Page = compose(
  connect(
    ({
      configuration
    }) => ({
      ...configuration
    })
  ),
  documentTitle
)(({
  children,
  title,
  subtitle,
  formArgs,
  // REDUX STATE
  version
}) => (
  <div className={styles.container}>
    <div className={styles.content}>
      <div>
        <div className={styles.header}>
          <div className={styles.branding}>
            <img
              className={styles.logo}
              src={Logo}
              height="48"
              alt={SITE_TITLE}
            />
          </div>
          <h1 className={styles.title}>
            {title}
          </h1>
          {[].concat(Array.isArray(subtitle) ? subtitle : [subtitle]).map((text, i) => (
            <h3
              key={i}
              className={styles.subtitle}
            >{text}</h3>
          ))}
        </div>
        <div className={styles.body}>
          <Alert.Router className={styles.alerts} limit="1" />
          <Form
            messageFunctions={alertFunctions}
            {...formArgs}
          >
            {children}
          </Form>
        </div>
      </div>
      <div className={styles.info}>
        <div>
          <p className={styles.copyright}>
            v{VERSION}
          </p>
          <p>
            ©{new Date().getFullYear()}&nbsp;{COPYRIGHT}
          </p>
        </div>
      </div>
    </div>
  </div>
))

Page.Footer = ({
  children
}) => (
  <div className={styles.footer}>
    {children}
  </div>
)

Page.Button = Button;
Page.Button.Back = props => (
  <Button
    className={styles.back}
    variant="secondary"
    label="Back"
    icon={{
      use: 'arrow-left',
      order: 1
    }}
    outline
    {...props}
  />
)

export default Page;
