import React, { cloneElement, useCallback, useContext } from 'react';

// DEPENDENCIES
import { cloneDeep, isEqual, keys } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';

// GLOBAL VARIABLES
import { FETCH } from 'defaults';

// GLOBAL FUNCTIONS
import { filterActions } from 'actions.js';
import { doCallback, getPathType } from 'functions.js';

// CONTEXT
import { ListContext } from 'parts/interface/helpers/getListData.js';

// TOOLS
import Sidebar from 'tools/sidebar/Sidebar';

// MAIN COMPONENT
const FilterSidebar = compose(
  connect(
    ({ filters }) => ({ filters }),
    { ...filterActions }
  )
)(({
  form = {},
  // REDUX STATE
  filters,
  // REDUX DISPATCH
  addFilters,
  clearFilters,
  updateFilter,
  clearFilter,
  // REST
  ...props
}) => {

  // CONTEXT
  const { init, searchFilters = [] } = useContext(ListContext) || {};

  // CALLBACKS
  const handleSubmit = useCallback(
    (filters, args) => {
      const clonedFilters = cloneDeep(filters);
      keys(clonedFilters).forEach(key => {
        if (FETCH.blacklist.includes(key)) delete clonedFilters[key];
      })
      addFilters(getPathType(), clonedFilters);
      doCallback(args.onSuccess);
    },
    [addFilters]
  )
  const handleClear = useCallback(
    () => {
      if (!isEqual(filters, init)) clearFilters(getPathType(), init);
    },
    [init, filters, clearFilters]
  )

  // RENDER
  return !searchFilters.length ? null : (
    <Sidebar
      name="filters"
      title="Advanced Search"
      {...props}
    >
      <Sidebar.Form
        {...form}
        initialValues={filters[getPathType()]}
        onSubmit={handleSubmit}
        buttons={{
          cancel: {
            label: 'Clear',
            icon: {
              use: 'ban'
            },
            onClick: handleClear
          },
          submit: {
            label: 'Search'
          }
        }}
      >
        {searchFilters.map((filter, i) => cloneElement(filter, { key: i }))}
      </Sidebar.Form>
    </Sidebar>
  )
})

// EXPORT
export default FilterSidebar
