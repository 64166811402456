import React, {useCallback, useMemo} from 'react';

// DEPENDENCIES
import { useHistory, useLocation } from 'react-router-dom';
import { startCase, lowerCase, find } from 'lodash';
import queryString from 'query-string';

// GLOBAL VARIABLES
import { DAYS, DEALERSHIP_HOURS } from 'globals.js';
import { PATHNAMES } from 'pathnames.js';

// GLOBAL FUNCTIONS
import { apiFetch, makePath } from 'functions.js';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';

// CORE COMPONENTS
import Form from 'core/form/Form';

// PROTOTYPE
import EditPrototype from '../prototype/EditPrototype';
import { controlName, precedingZero } from 'functions';

// STYLES
import styles from './dealershipHours.module.scss';

// FUNCTIONS
const addPrecedingZeros = arr => arr.map(value => ({
  value,
  label: precedingZero(value)
}));
const initializeRecord = hours => ({hours});

// VARIABLES
const HOUR_OPTIONS = addPrecedingZeros([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
const MIN_OPTIONS = addPrecedingZeros([0, 15, 30, 45]);
const AMPM_OPTIONS = ['AM', 'PM']

// PARTS
const HourControl = ({ index, prefix }) => (
  <Form.Group>
    <Form.Select
      name={controlName(`${prefix}_hour`, 'hours', index)}
      options={HOUR_OPTIONS}
      formGroup={{
        className: styles.hourPart
      }}
    />
    <Form.Select
      name={controlName(`${prefix}_min`, 'hours', index)}
      options={MIN_OPTIONS}
      formGroup={{
        className: styles.hourPart
      }}
    />
    <Form.Select
      name={controlName(`${prefix}_ampm`, 'hours', index)}
      options={AMPM_OPTIONS}
      formGroup={{
        className: styles.hourPart
      }}
/>
  </Form.Group>
)
const DayControl = ({ index, label }) => (
  <Form.Group>
    <Form.Label label={label} inline={false} />
    <Form.Col>
      <Form.Row>
        <Form.Col>
          <HourControl index={index} prefix="open" />
        </Form.Col>
        <Form.Col>
          <HourControl index={index} prefix="close" />
        </Form.Col>
        <Form.YesNo
          name={controlName('closed_flag', 'hours', index)}
          type="checkbox"
          formGroup={{
            className: styles.closedFlag
          }}
        />
      </Form.Row>
    </Form.Col>
  </Form.Group>
)

// MAIN COMPONENT
const Applications = () => {

  // REACT ROUTER
  const { search } = useLocation();
  const history = useHistory();
  
  // MEMOS
  const { hours_category } = useMemo(
    () => queryString.parse(search),
    [search]
  )
  const title = useMemo(
    () => {
      const category = find(DEALERSHIP_HOURS, ({ code }) => code === hours_category);
      return category?.name;
    },
    [hours_category]
  )

  const handleOnSubmit = useCallback((values) => {
      const payload = [];

      for(const prop in values?.hours) {
          if(values?.hours.hasOwnProperty(prop)) {
              payload.push(values?.hours[prop]);
          }
      }

      apiFetch({
          endpoint: ENDPOINTS.record.dealershipHours,
          method: 'PUT',
          params: {
              hours: payload
          },
          loadingMessage: `Updating ${lowerCase(title)}`,
          errorMessage: `Unable to update ${lowerCase(title)}.`,
          successMessage: `${startCase(title)} updated.`,
          onSuccess: () => history.push(makePath(PATHNAMES.dealershipHours)),
          useID: false
      })

  }, [history, title])

  // RENDER
  return (
    <EditPrototype
      routeKey="dealershipHours"
      label={lowerCase(title)}
      redirect={makePath(PATHNAMES.dealershipHours)}
      title={title}
      params={{
        hours_category
      }}
      form={{
        initializeRecord,
        onSubmit: handleOnSubmit
      }}
      useID={false}
    >
      <Form.Col>
        <Form.Group>
          <Form.Col className={styles.labelSpacer} />
          <Form.Col>
            <Form.Row>
              <Form.Col><span className="caption">Open Time (HH:MM AM/PM)</span></Form.Col>
              <Form.Col><span className="caption">Close Time (HH:MM AM/PM)</span></Form.Col>
              <Form.Col className={styles.closedFlag}><span className="caption">Closed?</span></Form.Col>
            </Form.Row>
          </Form.Col>
        </Form.Group>
      </Form.Col>
      {DAYS.map((day, i) => (
        <Form.Col xs="24">
          <DayControl
            index={i}
            label={day}
          />
        </Form.Col>
      ))}
    </EditPrototype>
)
}

export default Applications;

