import React, { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';

// DEPENDENCIES
import { connect } from 'react-redux';
import { compose } from 'redux';

// GLOBAL HELPERS
import { getLookupData } from 'helpers/getLookupData';
import { googleMapsAPI } from './googleMapsAPI';

// LOCAL VARIABLES
const API_PARAMS = {
  fields: ['address_components'],
  types:  ['address'],
  componentRestrictions: {
    country: ['us']
  }
}

// MAIN COMPONENT
export const googleMapsAutocomplete = Component => compose(
  getLookupData({
    lookup: 'usaStates'
  }),
  connect(
    ({ lookups }) => ({ lookups })
  ),
  googleMapsAPI,
  forwardRef
)(({
  onSelect,
  isScriptLoadSucceed,
  // REDUX STATE
  lookups: {
    usaStates = []
  },
  // REST
  ...props
}, ref) => {

  // AUTOCOMPLETE ELEMENT
  const addressInputRef = ref || useRef();
  const autocomplete = useMemo(
    () => {
      if (!isScriptLoadSucceed || !addressInputRef.current) return;
      return new window.google.maps.places.Autocomplete(addressInputRef.current, API_PARAMS);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isScriptLoadSucceed, addressInputRef, addressInputRef.current]
  )

  // AUTCOMPLET HANDLER
  const handleAutocomplete = useCallback(
    () => {
      const { address_components = [] } = autocomplete.getPlace();
      const o = address_components.reduce((o, {long_name, types}) => ({ ...o, [types[0]]: long_name }), {});
      onSelect({
        address: [o.street_number, o.route].filter(el => el).join(' '),
        city:     o.locality,
        state:    (usaStates.find(({ state_name }) => state_name === o.administrative_area_level_1) || {}).us_state_id,
        zipcode:  [o.postal_code, o.postal_code_suffix].filter(el => el).join('-')
      })
    },
    [autocomplete, onSelect, usaStates]
  )
  useEffect(
    () => {
      if (!autocomplete) return;
      autocomplete.addListener("place_changed", handleAutocomplete);
    },
    [autocomplete, handleAutocomplete]
  )

  // RENDER
  return <Component {...props} ref={addressInputRef} />;
})
