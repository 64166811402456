import React from 'react';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';

// PARTS
import List from 'parts/interface/list/List';
const { Action, Button, Col } = List;

// ARGS
const ARGS = {
    label: 'leadFormCategory',
    idKey: 'lead_category_id',
    endpoint: ENDPOINTS.record.leadFormCategory.list
}

// HEADER
const BUTTONS = [ <Button.Add /> ];

// COLUMNS
const COLUMNS = ({ category_name, char_key, site_name }) => [
    <Col
        cols={{lg: 10}}
        label="Lead Category Name"
        value={category_name}
    />,
    <Col
        cols={{lg: 4}}
        label="Unique Key"
        value={char_key}
    />,
    <Col
        cols={{lg: 10}}
        label="Site Name"
        value={site_name}
    />
];

// ACTIONS
const ACTIONS = () => [ <Action.Edit /> ];


// MAIN COMPONENT
const LeadFormCategory = () => <List
    title="Lead Forms"
    args={ARGS}
    buttons={BUTTONS}
    columns={COLUMNS}
    actions={ACTIONS}
    usePagination={false}
    useInfiniteScroll={true}
/>;

export default LeadFormCategory;
