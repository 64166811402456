import React from 'react';

// DEPENDENCIES
import clsx from 'clsx';

// BOOTSTRAP COMPONENTS
import { Badge } from 'react-bootstrap';

// STYLES
import styles from './menuBadge.module.scss';

// MAIN COMPONENT
const MenuBadge = ({
  children,
  className,
  variant = 'secondary',
  label = children
}) => (
  <Badge
    className={clsx(
      styles.badge,
      className
    )}
    variant={variant}
    pill
  >
    {label}
  </Badge>
)

// EXPORT
export default MenuBadge;
