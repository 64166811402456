import React, { Children, cloneElement, useCallback, useEffect, useMemo, useState } from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { withRouter } from 'react-router';

// GLOBAL FUNCTIONS
import { getPathKey } from 'functions.js'

// BOOTSTRAP COMPONENTS
import { Collapse } from 'react-bootstrap';

// GLOBAL COMPONENTS
import Sprite from 'core/tools/Sprite';

// LOCAL COMPONENTS
import MenuItem from './MenuItem';
import SubmenuItem from './SubmenuItem';

// STYLES
import styles from './submenu.module.scss';

// MAIN COMPONENT
const Submenu = withRouter(({
  as: Parent = MenuItem,
  children,
  level = 0,
  disable,
  // ROUTER
  history,
  location,
  match,
  staticContext,
  // REST
  ...props
}) => {

  // STATE
  const [ submenuShow, setSubmenuShow ] = useState(false)

  // MEMOS
  const pathkeys = useMemo(
    () => children.map(({ props }) => props.pathkey),
    [children]
  )
  const isActive = useMemo(
    () => pathkeys.includes(getPathKey(location.pathname)),
    [pathkeys, location.pathname]
  )

  // CALLBACKS
  const toggleShow = useCallback(
    (toggle = !submenuShow) => {
      setSubmenuShow(toggle);
    },
    [submenuShow, setSubmenuShow]
  )
  const handleClick = useCallback(
    e => {
      e.preventDefault();
      toggleShow();
    },
    [toggleShow]
  )

  // EFFECTS
  useEffect(
    () => {
      if (disable) setSubmenuShow(false);
      else if (isActive) setSubmenuShow(true);
    },
    [disable, setSubmenuShow, isActive]
  )

  // RENDER
  return (
    <Parent
      {...props}
      className={clsx(
        'submenu-toggle-item',
        styles.toggle
      )}
      linkClassName={clsx(
        'submenu-toggle-link',
        styles.link,
        !!submenuShow && ['toggled', styles.toggled]
      )}
      after={
        <Sprite
          className={styles.icon}
          use={submenuShow ? 'chevron-up' : 'chevron-down' }
          size="md"
        />
      }
      onClick={handleClick}
    >
      <Collapse
        className={clsx(
          'submenu',
          `submenu-${level}`,
          styles[`level-${level}`],
          styles.submenu
        )}
        in={submenuShow}
      >
        <div>
          <div className={clsx(
            'submenu-list',
            styles.list
          )}>
            {Children.map(children, (child, i) => !child ? null : cloneElement(child, Object.assign({
              key: i,
              level: level + 1,
              disable: !submenuShow
            })))}
          </div>
        </div>
      </Collapse>
    </Parent>
  )
})

// CHILD COMPONENTS
Submenu.Item = SubmenuItem;

// EXPORT
export default Submenu;
