import React, { useCallback } from 'react';

// DEPENDENCIES
import { connect } from 'react-redux';

// GLOBAL FUNCTIONS
import { sidebarActions } from 'actions.js';

// LOCAL COMPONENTS
import Action from '../prototype/Action';

// MAIN COMPONENT
export default connect(
  null,
  { ...sidebarActions }
)(({
  sidebar,
  recordID,
  // REDUX DISPATCH PROPS
  toggle,
  close,
  // REST
  ...props
}) => {

  // CALLBACKS
  const handleClick = useCallback(
    () => toggle(sidebar, recordID),
    [sidebar, recordID, toggle]
  )

  // RENDER
  return (
    <Action
      onClick={handleClick}
      {...props}
    />
  )
})
