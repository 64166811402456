import React from 'react';

// PROTOTYPE
import Prototype from './prototype/Badge';

// TYPE COMPONENTS
import AgeBadge from './types/AgeBadge';
import BooleanBadge from './types/BooleanBadge';
import CodeBadge from './types/CodeBadge';
import NumberBadge from './types/NumberBadge';
import UserRoleBadge from './types/UserRoleBadge';

// STYLES
import styles from './badge.module.scss';

// MAIN COMPONENT
const Badge = props => <Prototype {...props} />;

// CHILD COMPONENTS

// BOOLEAN
Badge.Boolean = BooleanBadge;
Badge.Active  = props => <BooleanBadge
  options={[{label: 'Inactive'}, {label: 'Active'}]}
  {...props}
/>;
Badge.YesNo   = props => <BooleanBadge
  className={styles.yesNo}
  options={[{label: 'No'}, {label: 'Yes'}]}
  {...props}
/>;

// NUMBER
Badge.Number  = NumberBadge;
Badge.Age     = AgeBadge;

// CODE
Badge.Code    = CodeBadge;
// Badge.Example  = props => <CodeBadge options={OPTIONS} {...props} />;

// DEPRECATED
Badge.UserRole = UserRoleBadge;

// EXPORT
export default Badge;
