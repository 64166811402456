import React, { useCallback, useMemo } from "react";

// FUNCTIONS
import { formatDateTime, makePath } from "functions";

// CORE COMPONENTS
import Form from "core/form/Form";

// PROTOTYPE
import ListPrototype from "../prototype/ListPrototype";

// PARTS
import List from "parts/interface/list/List";

// GLOBAL VARIABLES
import { ENDPOINTS } from "endpoints.js";

const { Action, Col, Button } = List;
const { History } = List.Button;

// COLUMNS
const COLUMNS = ({
  name,
  email_address,
  created_date,
  created_by,
  email_format,
}) => [
  <Col
    cols={{
      lg: 4,
    }}
    label="Name"
    value={name}
  />,
  <Col
    cols={{
      lg: 4,
    }}
    label="Email Address"
    value={email_address}
  />,
  <Col
    cols={{
      lg: 4,
    }}
    label="Created Date/Time"
    value={formatDateTime(created_date)}
  />,
  <Col
    cols={{
      lg: 4,
    }}
    label="Created By"
    value={created_by}
  />,
  <Col
    cols={{
      lg: 2,
    }}
    label="Format"
    value={email_format}
  />,
];

// ACTIONS
const ACTIONS = () => [<Action.Edit />, <Action.Delete />];

// MAIN COMPONENT
const LeadRouting = ({ history, location }) => {

  // MEMOS
  const filterParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return { lead_category_id: params.get("leadCategoryId") };
  }, [location.search]);

  const updateFilter = useCallback(
    (value) => {
      const params = new URLSearchParams();
      params.append("leadCategoryId", value);
      history.push({ search: params.toString() });
    },
    [history]
  );

    const buttons = useMemo(() => [
        <Button.Add />,
        <History args={{
            method: "GET",
            endpoint: makePath(ENDPOINTS.record.leadRoutingHistory.list, filterParams?.lead_category_id),
            loadingMessage: 'Fetching history log...',
            errorMessage: 'Unable to fetch history log...',
        }}
        />
    ], [filterParams])

  return (
    <>
      <Form.Select
        name="selectedCategoryId"
        label="Category"
        lookup="leadCategories"
        optionKeys={{ label: "category_name", value: "lead_category_id" }}
        onSelect={updateFilter}
        useFormik={false}
        value={filterParams.lead_category_id || ""}
      />
      {filterParams.lead_category_id && (
        <ListPrototype
          routeKey="leadRouting"
          idKey="lead_routing_id"
          label="leadRouting"
          title="Lead Routing"
          showID={false}
          columns={COLUMNS}
          actions={ACTIONS}
          buttons={buttons}
          init={filterParams}
        />
      )}
    </>
  );
};

export default LeadRouting;
