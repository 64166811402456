import React from 'react';

// CORE COMPONENTS
import Form from 'core/form/Form';

// MAIN COMPONENT
const DateRangeFilter = ({
  name = '',
  label = 'Completed Date',
  ...props
}) => (
  <Form.Date
    name={name}
    label={label}
    range
    {...props}
  />
)

// EXPORT
export default DateRangeFilter;
