import React from 'react';

// CORE COMPONENTS
import Collapse from 'core/tools/collapse/Collapse';

// STYLES
import styles from './sessionCollapse.module.scss';

// MAIN COMPONENT
const SessionCollapse = ({
  component: Component,
  label,
  ...props
}) => (
  <Component.Wrapper>
    <div className={styles.container}>
      {label &&
        <span className={styles.label}>{label}:</span>
      }
      <Collapse
        toggle={{
          as: Component.Toggle,
          className: styles.toggle
        }}
      >
        <div className={styles.list}>
          <Component.List
            className={styles.item}
          />
        </div>
      </Collapse>
    </div>
  </Component.Wrapper>
)

export default SessionCollapse
