import React, { useCallback } from 'react';

import { Col as BCol, Row } from "react-bootstrap";

import Button from "core/tools/Button";

import { apiFetch, modalFunctions } from "functions";
import { MODAL_PRIORITY } from "globals";

const HistoryButton = ({ args }) => {
    const showModal = useCallback(() => {
        apiFetch({
            ...args,
            onSuccess: (result) => (
                modalFunctions.add({
                    type: 'confirmation',
                    modalStyles: 'h-auto',
                    body:
                        <>
                            <Row className="bg-light font-weight-bold p-3">
                                <BCol lg={6}>
                                    Action Date/Time
                                </BCol>
                                <BCol lg={6}>
                                    Action By
                                </BCol>
                                <BCol lg={12}>
                                    Log Message
                                </BCol>
                            </Row>
                            <Row className="p-3">
                                {
                                    result.map((item, index) =>
                                        <Row key={index + item?.logged_datetime}
                                             className="p-2 border-bottom border-dark">
                                            <BCol lg={6}>
                                                {item?.logged_datetime}
                                            </BCol>
                                            <BCol lg={6}>
                                                {item?.logged_by}
                                            </BCol>
                                            <BCol lg={12} style={{overflowWrap: 'break-word'}}>
                                                {item?.log_message && item?.log_message.split(',').map((part, i) => (
                                                    <React.Fragment key={i + item?.log_message}>
                                                        {part}
                                                        <br/>
                                                    </React.Fragment>
                                                ))}
                                            </BCol>
                                        </Row>
                                    )
                                }
                            </Row>
                        </>,
                    cancelButton: false,
                    continueButton: {
                        label: 'Close',
                        icon: {},
                    },
                    priority: MODAL_PRIORITY?.high
                })
            )
        });

    }, [args])

    return (
        <Button.Link
            label='History'
            className='w-100 mr-auto'
            onClick={showModal}
        />
    )
};


export default HistoryButton;