import React  from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import Record from 'parts/interface/record/Record';


// FUNCTIONS
import { formatDateTime } from "functions";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// MAIN COMPONENT
const LeadRoutingChangeLog = compose(
  connect(
    ( record ) => ( record )
  )
)(({
  record
}) => {

  const logData = record.lead_routing_log;

  return (

    <>

      <Record.Card.Subtitle>Change Log</Record.Card.Subtitle>

      <Container>
        <Row>
          <Col sm={4}>Last Change</Col>
          <Col sm={3}>User</Col>
          <Col auto>Description</Col>
        </Row>
        {logData && logData.map( (item)=>{
            return (
              <Row>
                <Col sm={4}>{formatDateTime(item.created_date)}</Col>
                <Col sm={3}>{item.log_action_by}</Col>
                <Col auto>{item.log_message}</Col>
              </Row>
            )
          })    
        }
        
      </Container>
      
    </>)
})

export default LeadRoutingChangeLog
