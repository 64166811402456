import React, { useCallback, useContext } from 'react';

// DEPENDENCIES
import clsx from 'clsx';

// GLOBAL VARIABLES
import { SITE_TITLE } from 'globals.js';

// CONTEXT
import { MenuContext } from '../../helpers/menuContext';

// REACT ROUTER COMPONENTS
import { Link } from "react-router-dom";

// ASSETS
import Logo from 'img/logo.png';

// BOOTSTRAP COMPONENTS
import { Navbar } from 'react-bootstrap';

// CORE COMPONENTS
import Button from 'core/tools/Button';

// STYLES
import styles from './menuBrand.module.scss';

// MAIN COMPONENT
const MenuBrand = () => {

  // CONTEXT
  const { toggle } = useContext(MenuContext);

  // CALLBACKS
  const handleClick = useCallback(
    () => {
      toggle(false)
    },
    [toggle]
  )

  // RENDER
  return (
    <div className={styles.container}>
      <div className={clsx(
        styles.tool,
        styles.placeholder,
      )} />
      <Navbar.Brand
        as={Link}
        to="/"
        onClick={handleClick}
        className={styles.brand}
      >
        <img
          className={styles.logo}
          src={Logo}
          height="48"
          alt={SITE_TITLE}
        />
      </Navbar.Brand>
      <div className={styles.tool}>
        <Button
          className={clsx(
            styles.button,
            styles.close
          )}
          variant="custom"
          icon="times"
          onClick={() => toggle(false)}
          square
        />
      </div>
    </div>
  )
}

// EXPORT
export default MenuBrand;
