import React from "react";

// GLOBAL VARIABLES
import { ENDPOINTS } from "endpoints.js";
import { formatDateTime } from "functions";
// PARTS
import List from "parts/interface/list/List";
import ListPrototype from "../prototype/ListPrototype";
import ListButton from "parts/interface/list/button/ListButton";

const { Action, Col, Tool } = List;
const { Filter } = List;
const { Download } = ListButton;

// ARGS
const ARGS = {
  label: "Lead Form",
  idKey: "form_contact_id",
  endpoint: ENDPOINTS.record.leadForms.list,
  init: {
    startRow: 1,
    numRow: 50
  },
  custom: true
};

// TOOLBAR
const TOOLS = [
  <Tool.ResultCount />,
  <Filter.Search name='searchText' />,
  <Tool.SortBy
      options={[
        { label: "Contact Name", value: "contact_name" },
        { label: "Created Date", value: "created_date" },
        { label: "Phone", value: "phone" },
        { label: "Email", value: "email_address" },
        { label: "Category Name", value: "category_name" },
        { label: "Form Type", value: "form_type" },
        { label: "Referer URL", value: "referer_url" }
      ]}
  />,
  <Download className='w-auto text-white' variant='primary' label='Export to Excel' solid />,
  <Tool.RecordsInfo />
];

const FILTERS = [
  <Filter.DateRange label='Time Frame' startName='startDate' endName='endDate' />,
  <Filter.Select
    name="lead_category_id"
    label="Category"
    lookup="leadCategory" // same key as endpoint
    optionKeys={{ label: "category_name", value: "lead_category_id" }} // as per API
  />,
  <Filter.Select
    name="lead_form_id"
    label="Form Type"
    lookup="leadFormType"
    optionKeys={{ label: "lead_name", value: "lead_form_id" }}
  />,
  <Filter.Select
    name="source"
    label="Source"
    lookup="leadSource" // same key as endpoint
  />,
  <Filter.Select
    name="referer_url"
    label="Referer"
    lookup="leadRefererUrl" // same key as endpoint
  />,
];

// COLUMNS
const COLUMNS = ({
  contact_name,
  created_date,
  phone,
  email_address,
  category_name,
  form_type,
  referer_url,
}) => [
  <Col cols={{ lg: 3 }} label="Date Time" value={formatDateTime(created_date)} />,
  <Col cols={{ lg: 3 }} label="Name" value={contact_name} />,
  <Col cols={{ lg: 3 }} label="Email Address" value={email_address} />,
  <Col cols={{ lg: 3 }} label="Phone" value={phone} />,
  <Col cols={{ lg: 4 }} label="Category" value={category_name} />,
  <Col cols={{ lg: 3 }} label="Type" value={form_type} />,
  <Col cols={{ lg: 4 }} label="Referrer" value={referer_url} />,
];

// ACTIONS
const ACTIONS = () => [<Action.Edit icon="search-plus" label="View" path="/lead-forms/view/" />];

// MAIN COMPONENT
const LeadForms = () => (
    <ListPrototype
      title="Lead Form"
      args={ARGS}
      tools={TOOLS}
      buttons={[]}
      columns={COLUMNS}
      actions={ACTIONS}
      usePagination={false}
      useInfiniteScroll={true}
      searchFilters={FILTERS}
    />
);

export default LeadForms;
