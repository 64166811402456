import React, { useMemo } from 'react';

// DEPENDENCIES
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { findKey } from 'lodash';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';
import { DELIVERY_METHOD } from 'globals.js';

// GLOBAL FUNCTIONS
import { sessionActions } from 'actions.js';
import { alertFunctions, apiFetch, makePath } from 'functions.js';

// CORE COMPONENTS
import Form from 'core/form/Form';

// PARTS
import Page from 'parts/authentication/Page';

// MAIN COMPONENT
const EnterCode = compose(
  withRouter,
  connect(
    ({ user }) => ({ user }),
    { ...sessionActions }
  )
)(({
  history,
  location,
  user,
  setVerification
}) => {

  // MEMOS
  const contactMethod = useMemo(
    () => findKey(DELIVERY_METHOD, el => el === queryString.parse(location.search).delivery_method),
    [location.search]
  )
  const contactInfo = useMemo(
    () => {
      if (!contactMethod) return '';
      const contactInfo = {
        text: `${user.cell_phone_number.substring(0, user.cell_phone_number.length - 7)}**-****`,
        email: `${user.email_address.split('@')[0].substring(0, 2)}****@${user.email_address.split('@')[1]}`
      }
      return contactInfo[contactMethod]
    },
    [contactMethod, user]
  )

  // RENDER
  return (
    <Page
      documentTitle="Login"
      title="One-time security code"
      subtitle={[
        `We have sent ${contactMethod === 'email' ? 'an' : 'a'} ${contactMethod} with one-time security code to ${contactInfo}. Once you receive the message, enter the security code and click “Submit”.`,
        `Please note that the ${contactMethod} can take a few minutes to be received.`
      ]}
      formArgs={{
        endpoint: ENDPOINTS.session.enterCode,
        onSuccess: setVerification,
        loadingMessage: 'Submitting one-time security code',
        errorMessage: 'Could not authenticate one-time security code.'
      }}
    >
      <Form.Body.Vertical>
        <Form.Control
          type="password"
          name="security_code"
          label="Security Code"
          append={{
            use: 'lock'
          }}
          required
        />
        <Form.Text>
          <Page.Button.Link
            label="Re-send one-time security code"
            icon={{
              use: 'sync',
              order: 1
            }}
            onClick={() => {
              apiFetch({
                method: 'POST',
                endpoint: ENDPOINTS.session.requestCode,
                params: {
                  delivery_method: queryString.parse(location.search).delivery_method
                },
                loadingMessage: 'Requesting one-time security code',
                successMessage: 'One-time security code re-sent.',
                errorMessage: 'Could not request one-time security code.',
                messageFunctions: alertFunctions
              })
            }}
          />
        </Form.Text>
      </Form.Body.Vertical>
      <Page.Footer>
        <Page.Button.Back
          to={makePath(ENDPOINTS.session.requestCode)}
        />
        <Page.Button.Submit
          label="Enter Security Code"
        />
      </Page.Footer>
    </Page>
  )
})

// EXPORT
export default EnterCode
