import React from 'react';

// CORE COMPONENTS
import Form from 'core/form/Form';

// TOOLS
import ApplicationSelect from 'tools/form/ApplicationSelect';
import CharKey from 'tools/form/CharKey';

// PROTOTYPE
import EditPrototype from "../prototype/EditPrototype";

const DEFAULT_DATA = {
  role_name: '',
  char_key: '',
  application_name: '',
  application_admin: false,
  archive_flag: false,
}

// MAIN COMPONENT
const ApplicationRoles = () => (
  <EditPrototype
    routeKey="applicationRole"
    idKey="site_role_id"
    form={{ initialValues: DEFAULT_DATA }}
  >
    <Form.Control
      name="role_name"
      label="Role Name"
      required
    />
    <CharKey />
    <ApplicationSelect />
    <Form.YesNo
      name="application_admin"
      label="Is Application Administrator?"
      required
    />
    <Form.YesNo
      name="archive_flag"
      label="Is Archive?"
      required
    />
  </EditPrototype>
);

export default ApplicationRoles;

