export const ENDPOINTS = {
  session: {
    getConfiguration: 'application-configuration',
    login:            'authenticate',
    logout:           'logout',
    extend:           'extend-session',
    requestCode:      'security-code-request',
    enterCode:        'security-code-authentication',
    changeDealership: 'change-dealership',
    resetPassword:    'reset-password',
    newPassword:      'reset-password/new-password',
    passwordToken:    'reset-password/token-confirm'
  },
  lookup: {
    application:          'applications-lookup/list',
    leadCategory:         'lead-category/list',
    leadFormType:         'lead-form-type/list',
    leadSource:           'source/list',
    leadRefererUrl:       'referer_url/list',
    applications:         'applications-lookup/list',
    dealerships:          'dealerships-lookup/list',
    dealershipSites:      'dealership-sites/list',
    deviceResolutions:    'device-resolutions/list',
    dxRoles:              'roles/list',
    makes:                'makes-lookup/list',
    orgTitles:            'org-title/list',
    salesDepartmentTypes: 'sales-dept-type/list',
    serviceProducts:      'service-products/list',
    staffRole:            'staff-roles',
    userGroup:            'user-group/list',
    userPermission:       'user-permission/list',
    userRole:             'user-role/list',
    dealershipGroups:     'dealership-groups-lookup/list',

    serviceTransportationOptions: 'service-transportation-options/lookup',
    // SAMPLE ONLY (END)
    listSites: 'dealership-sites/list',
    leadFormTypes: 'lead-form-type/list',
    leadCategories: 'lead-category/list',

    usaStates:            'states/list',
  },
  user: {
    account:  'update-user-profile',
    password: 'change-password',
    editPassword: 'user/change-password'
  },
  record: {
    application: 'application',
    applicationConfiguration: 'application-config',
    applicationRole: {
      list: 'application-role/list',
      get: 'application-role',
      add: 'application-role',
      put: 'application-role'
    },
    dealership: {
      list: 'dealerships/list',
      get: 'dealerships',
      add: 'dealerships',
      put: 'dealerships'
    },
    dealershipGroups: {
      list: 'dealership-groups/list',
      get: 'dealership-groups',
      put: 'dealership-groups',
      add: 'dealership-groups'
    },
    dealershipHours: 'dealership-hours',
    organizationTitle: {
      list: 'org-title/list',
      get: 'org-title',
      add: 'org-title',
      put: 'org-title'
    },
    leadForms: {
      list: "lead-form/list",
      get: "lead-form",
      put: "lead-form",
    },
    requestPage: 'request-pages',
    seoMetadata: 'seo-meta-data',
    generalService: 'general-service',
    scheduledMaintenance: 'scheduled-maintenance',
    // yearModelSpecific: 'service-item',
    serviceTransportation: {
      list: 'service-transportation-options/list',
      get: 'service-transportation-options',
      add: 'service-transportation-options',
      put: 'service-transportation-options'
    },
    websiteGeneralInfo: {
      list: 'website-general-info/list',
      get: 'website-general-info',
      add: 'website-general-info',
      put: 'website-general-info'
    },
    leadRouting: {
      list: 'lead-routing/list',
      get: 'lead-routing',
      add: 'lead-routing',
      put: 'lead-routing',
      delete: 'lead-routing'
    },
    leadRoutingHistory: {
      list: 'lead-routing/category-log-history'
    },
    serviceScheduler: {
      get: 'service-scheduler-config',
      put: 'service-scheduler-config'
    },
    user: {
      list: 'user/list',
      get: 'user',
      put: 'user',
      add: 'user'
    },
    leadFormCategory: {
      list: 'lead-form-category/list',
      get: 'lead-form-category',
      put: 'lead-form-category',
      add: 'lead-form-category'
    }
  }
}
