import React from 'react';

// PROTOTYPE
import ListPrototype from "../prototype/ListPrototype";

// TOOLS
import Badge from 'tools/badge/Badge';

// PARTS
import List from 'parts/interface/list/List';
const { Action, Button, Col, Tool } = List;

// HEADER
const BUTTONS = [ <Button.Add /> ]

// TOOLBAR
const TOOLS = [ <Tool.ResultCount /> ]

// COLUMNS
const COLUMNS = ({
  role_name,
  char_key,
  application_name,
  application_admin,
  archive_flag
}) => [
  <Col
    cols={{ lg: 7 }}
    label="Role Name"
    value={ role_name }
  />,
  <Col
    cols={{ lg: 4 }}
    label="Unique Key"
    value={ char_key }
  />,
  <Col
    cols={{ lg: 7 }}
    label="Application"
    value={ application_name }
  />,
  <Col
    cols={{ lg: 3 }}
    label="Application Administrator?"
    as={ Badge.YesNo }
    value={ application_admin }
  />,
  <Col
    cols={{ lg: 3 }}
    label="Archive?"
    as={ Badge.YesNo }
    value={ archive_flag }
  />
]

// ACTIONS
const ACTIONS = () => [ <Action.Edit /> ]

// MAIN COMPONENT
const ApplicationRoles = () => <ListPrototype
  routeKey="applicationRole"
  idKey="site_role_id"
  buttons={BUTTONS}
  tools={TOOLS}
  columns={COLUMNS}
  actions={ACTIONS}
/>

export default ApplicationRoles;
