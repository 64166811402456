import React from 'react';

// TOOLS
import Badge from 'tools/badge/Badge';

// PROTOTYPE
import ListPrototype from '../prototype/ListPrototype';

// PARTS
import List from 'parts/interface/list/List';
const { Col } = List;

// COLUMNS
const COLUMNS = ({
  page_title,
  page_file_name,
  request_action_key,
  home_page,
  mobile_page
}) => [
  <Col
    cols={{ lg: 6 }}
    label="Page Title"
    value={page_title}
  />,
  <Col
    cols={{ lg: 6 }}
    label="File Name"
    value={page_file_name}
  />,
  <Col
    cols={{ lg: 6 }}
    label="Request Action Key"
    value={request_action_key}
  />,
  <Col
    cols={{ lg: 3 }}
    as={Badge.YesNo}
    label="Home Page?"
    value={home_page}
  />,
  <Col
    cols={{ lg: 3 }}
    as={Badge.YesNo}
    label="Mobile Page?"
    value={mobile_page}
  />
]

// MAIN COMPONENT
const RequestPages = () => <ListPrototype
  routeKey="requestPage"
  columns={COLUMNS}
/>

export default RequestPages;
