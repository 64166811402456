import React, { forwardRef } from 'react';

// DEPENDENCIES
import clsx from 'clsx';

// CORE COMPONENTS
import Button from 'core/tools/Button';
import Dropdown from 'core/tools/dropdown/Dropdown';

// LOCAL COMPONENTS
import HeaderButton from '../parts/HeaderButton';

// STYLES
import styles from './headerDropdown.module.scss';

// CHILD COMPONENTS
const DropdownItem = ({
  className,
  ...props
}) => (
  <div className={clsx(
    styles.item,
    className
  )}>
    <Button
      className={styles.button}
      justify="start"
      variant="custom"
      {...props}
    />
  </div>
)
const HeaderToggle = forwardRef(({
  className,
  icon = {},
  ...props
}, ref) => (
  <HeaderButton
    className={clsx(
      styles.toggle,
      className
    )}
    icon={{
      ...icon,
      className: clsx(
        styles.icon,
        icon.className,
      ),
      size: 'sm'
    }}
    ref={ref}
    {...props}
  />
))

// MAIN COMPONENT
const HeaderDropdown = ({
  children,
  className,
  label,
  toggle,
  menu,
  ...props
}) => (
  <div className={styles.container}>
    {label &&
      <span className={styles.label}>{label}:</span>
    }
    <Dropdown
      className={clsx(
        styles.menu,
        className
      )}
      toggle={{
        as: HeaderToggle,
        ...toggle
      }}
      menu={{
        className: styles.menu,
        ...menu
      }}
      popover={{
        className: styles.popover
      }}
      margin={0}
      {...props}
    >
      <div className={styles.items}>
        {children}
      </div>
    </Dropdown>
  </div>
)

// CHILD COMPONENTS
HeaderDropdown.Toggle = HeaderToggle;
HeaderDropdown.Item = DropdownItem;

// EXPORT
export default HeaderDropdown
