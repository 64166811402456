import React, { useContext, useMemo } from 'react';

// DEPENDENCIES
import { startCase } from 'lodash';

// GLOBAL VARIABLES
import { PATHNAMES } from 'pathnames.js';

// GLOBAL FUNCTIONS
import { getPathStep } from 'functions.js';

// CONTEXT
import { RecordContext } from 'parts/interface/helpers/getRecordData';

// TOOLS
import Card from 'tools/card/Card';

// MAIN COMPONENT
const EditCard = ({
  children,
  ...props
}) => {

  // CONTEXT
  const { label } = useContext(RecordContext) || {};

  // VARIABLES
  const step = getPathStep();

  // MEMOS
  const title = useMemo(
    () => {
      switch(step) {
        case PATHNAMES.edit: return `Edit ${startCase(label)}`;
        case PATHNAMES.view: return `View ${startCase(label)}`;
        case PATHNAMES.add: return `Add ${startCase(label)}`;
        default: return;
      }
    },
    [step, label]
  )

  // RENDER
  return (
    <Card
      title={title}
      {...props}
    >
      <Card.Body>
        {children}
      </Card.Body>
    </Card>
  )
}

// EXPORT
export default EditCard;
