import React from 'react';

// DEPENDENCIES
import clsx from 'clsx';

// LOCAL COMPONENTS
import Delete from './types/Delete';
import Link from './types/Link';
import Sidebar from './types/Sidebar';
import Sort from './types/Sort';

// STYLES
import styles from './listAction.module.scss';

// MAIN COMPONENT
const ListAction = {
  Link,
  Sidebar,
  Delete,
  SortUp: props => <Sort direction="A" {...props} />,
  SortDown: props => <Sort direction="D" {...props} />,
  Comments: ({
    children = '',
    recordID
  }) => (
    <Sidebar
      className={clsx(
        styles.comments,
        !children && styles.empty
      )}
      sidebar="comments"
      label="Comments"
      recordID={recordID}
    >{children.toString()}</Sidebar>
  ),
  // STEP BUTTONS
  View: props => (
    <Link
      step="view"
      label="View"
      icon="search-plus"
      {...props}
    />
  ),
  Edit: props => (
    <Link
      step="edit"
      label="Edit"
      icon="pencil-alt"
      {...props}
    />
  )
}

// EXPORT
export default ListAction
