import React, { useContext } from 'react';

// DEPENDENCIES
import clsx from 'clsx';

// CORE COMPONENTS
import Button from 'core/tools/Button';

// CONTEXT
import { SessionContext } from '../../helpers/sessionContext';
import { CollapseContext } from 'core/tools/collapse/helpers/collapseContext';
import { DropdownContext } from 'core/tools/dropdown/helpers/dropdownContext';

// STYLES
import styles from './sessionToggle.module.scss';
import sprite from 'core/tools/sprite.module.scss';

// MAIN COMPONENT
const SessionToggle = ({
  as: Component = Button,
  className,
  icon = {},
  toggleRef,
  ...props
}) => {

  // CONTEXT
  const { fetching } = useContext(SessionContext);
  const { open } = useContext(CollapseContext) || {};
  const { show } = useContext(DropdownContext) || {};

  // RENDER
  return (
    <Component
      className={clsx(
        styles.toggle,
        (open || show) && 'active',
        className
      )}
      icon={{
        ...icon,
        as: fetching ? false : undefined,
        use: fetching ? 'loader' : open || show ? 'chevron-up' : 'chevron-down',
        className: clsx(
          icon.className,
          fetching ? sprite.spin : undefined
        )
      }}
      variant="custom"
      ref={toggleRef}
      {...props}
    />
  )
}

export default SessionToggle
