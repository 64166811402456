import React from 'react';

// CORE COMPONENTS
import Form from 'core/form/Form';

// PROTOTYPE
import EditPrototype from "../prototype/EditPrototype";

const DEFAULT_DATA = {
  name: "",
  dealer_group_id: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip_code: "",
  phone_number: "",
  fax_number: "",
  toll_free_number: "",
  status: true,
}

const STATUS_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

// MAIN COMPONENT
const Dealerships = () => (
  <EditPrototype
    routeKey="dealership"
    idKey="dealer_id"
    form={{ initialValues: DEFAULT_DATA }}
  >
    <Form.Control
      name="name"
      label="Dealership Name"
      required
    />
    <Form.Select
        name="dealer_group_id"
        label="Dealership Group"
        lookup='dealershipGroups'
        optionKeys={{ label: 'dealer_group_name', value: 'dealer_group_id'}}
        required
    />
    <Form.Control
      name="address1"
      label="Address Line 1"
      required
    />
    <Form.Control
      name="address2"
      label="Address Line 2"
    />
    <Form.Control
      name="city"
      label="City"
      required
    />
    <Form.Select
      name="state"
      label="State"
      lookup="usaStates"
      optionKeys={{ label: 'state_name', value: 'state_code'}}
      required
    />
    <Form.Control
      name="zip_code"
      label="Zip Code"
      schema="postalCode"
      required
    />
    <Form.Control
      name="phone_number"
      label="Phone Number"
      required
    />
    <Form.Control
      name="fax_number"
      label="Fax Number"
    />
    <Form.Control
      name="toll_free_number"
      label="Toll Free Number"
    />
    <Form.Checklist
      name="status"
      label="Is Active?"
      type="radio"
      options={STATUS_OPTIONS}
      required
    />
  </EditPrototype>
);

export default Dealerships
