import React, {createContext, useCallback, useEffect, useMemo, useState} from 'react';

// DEPENDENCIES
import { isEqual } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

// GLOBAL VARIABLES
import { ROWS_ON_PAGE } from 'globals.js'

// GLOBAL FUNCTIONS
import { listActions, filterActions } from 'actions.js';
import { apiFetch, getPathType } from 'functions.js';

// CONTEXT EXPORT
export const ListContext = createContext(null);

// MAIN COMPONENT
export const getListData = Component => {
  return compose(
    connect(
      ({ records, filters }) => ({ records, filters }),
      { ...listActions, ...filterActions }
    ),
    withRouter
  )(({
    routeKey,
    args: {
      label = 'record',
      plural = `${label}s`,
      idKey = 'id',
      endpoint,
      init,
      debug,
      custom = false,
    } = {},
    buttons = [],
    tools = [],
    columns = () => [],
    actions = () => [],
    sidebars = [],
    searchFilters = [],
    performFetch = true,
    // REDUX STATE
    records,
    filters,
    // REDUX DISPATCH
    addFilters,
    clearFilters,
    updateFilter,
    clearFilter,
    loadCustomData,
    appendCustomData,
    // REACT ROUTER
    history,
    match: {
      params: {
        page = 1
      }
    },
    staticContext,
    // REDUX DISPATCH
    load,
    append,
    flush,
    // REST
    ...props
  }) => {

    // PROPS
    const { usePagination, useInfiniteScroll = false } = props;

    // STATE
    const [ isFetching, setIsFetching ] = useState('');
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ hasMore , setHasMore ] = useState(true);

    // PARAMS
      const params = useMemo(() => {
          const paramsObject = Object.assign({}, init, filters?.global, filters[getPathType()]);
          if (usePagination) Object.assign(paramsObject, {
              page: parseInt(page, 10),
              rowsOnPage: ROWS_ON_PAGE.default
          });
          if (useInfiniteScroll) {
              Object.assign(paramsObject, {
                  startRow: currentPage === 0 ? 1 : currentPage * ROWS_ON_PAGE.default + 1,
                  numRow: ROWS_ON_PAGE.default
              });
          }
          return paramsObject;
      }, [init, filters, page, usePagination, useInfiniteScroll, currentPage])

      const loadRecords = useCallback((res) => {
          const loadFunction = custom ? loadCustomData : load;
          const appendFunction = custom ? appendCustomData : append;

          const loadData = (response, isAppend) => {
              if (isAppend) {
                  appendFunction(response);
              } else {
                  loadFunction(response);
              }
          };

          if (useInfiniteScroll) {
              if (
                  res.result?.length < params.numRow ||
                  isEqual(res.result, records.list.slice(ROWS_ON_PAGE.default))
              ) {
                  setHasMore(false);
              }

              if (currentPage === 0) {
                  loadData(res, false);
                  return;
              }

              if (isEqual(res.result, records.list) || !records.list.length) {
                  loadData(res, false);
                  return;
              }

              loadData(res, true);
              return;
          }

          loadData(res, false);
    }, [useInfiniteScroll, currentPage, custom, records, loadCustomData, load, appendCustomData, append, params]);

    // FETCH
    const fetchRecords = useCallback(
        () => {
        if (!endpoint) return;

        apiFetch({
          method: 'GET',
          endpoint,
          params,
          onFetch: () => setIsFetching('fetching'),
          onResponse: () => setIsFetching(''),
          onSuccess: loadRecords,
          loadingMessage: `Fetching ${plural}...`,
          errorMessage: `Unable to fetch ${plural}.`,
          custom,
          debug
        })
      },
      [plural, endpoint, params, setIsFetching, debug, loadRecords, custom]
    )
    const handleScroll = useCallback(() => {
        if (!hasMore) return;
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 10) {
            if (document.activeElement) {
                document.activeElement.blur();
            }
            setCurrentPage(prevPage => prevPage + 1);
        }
    }, [hasMore]);

    useEffect(() => {
        fetchRecords();
    }, [params])

      useEffect(() => {
          flush();
          setHasMore(true);
          setCurrentPage(0);
      }, [filters, flush]);

    useEffect(() => {
        if (isFetching === 'fetching') return;

        if (useInfiniteScroll) window.addEventListener('scroll', handleScroll);

        return () => {
            if (useInfiniteScroll) window.removeEventListener('scroll', handleScroll);
        }
      }, [performFetch, fetchRecords, useInfiniteScroll, isFetching, handleScroll])

    // RENDER
    return (
      <ListContext.Provider value={{
        routeKey,
        idKey,
        label,
        plural,
        endpoint,
        params,
        buttons,
        tools,
        columns,
        actions,
        sidebars,
        searchFilters,
        init,
        state: isFetching,
        records,
        fetchRecords
      }}>
        <Component {...props} />
      </ListContext.Provider>
    )
  })
}
