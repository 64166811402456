import React, { useContext, useMemo } from 'react';

// DEPENDENCIES
import { compose } from 'redux';
import { connect } from 'react-redux';

// CONTEXT
import { ListContext } from '../../../helpers/getListData';

// GLOBAL FUNCTIONS
import { getPathType } from 'functions.js';

// STYLES
import styles from './filters.module.scss';

// MAIN COMPONENT
export default compose(
  connect(
    ({ filters, lookups }) => ({ filters, lookups })
  )
)(({
  // REDUX STATE PROPS
  filters: {
    [getPathType()]: filters = {}
  },
  lookups
}) => {

  // CONTEXT
  const { searchFilters } = useContext(ListContext) || {};

  // MEMO
  const params = useMemo(
    () => searchFilters.reduce((params, {
      name,
      label,
      options,
      lookup,
      optionKeys
    }) => {
      const reduceOptions = (label, option) => option.value === value ? option.label : label;
      const reduceLookup = (label, lookup) => lookup[optionKeys.value] === value ? lookup[optionKeys.label] : label;
      let value = options ? options.reduce(reduceOptions, '') :
      lookups[lookup] && optionKeys ? lookups[lookup].reduce(reduceLookup, '') :
      filters[name];
      if (value) params.push({label, value});
      return params;
    }, []),
    [searchFilters, filters, lookups]
  )

  // RENDER
  return params.length < 1 ? null : (
    <div className={styles.container}>
      {params.map(({ label, value }, i) => (
        <div key={i}>
          <span className={styles.label}>{label}:</span>
          &nbsp;
          <span className={styles.value}>{value}</span>
        </div>
      ))}
    </div>
  )
})
