import React, { useCallback } from 'react';

// DEPENDENCIES
import { compose } from 'redux';
import { connect } from 'react-redux';

// GLOBAL FUNCTIONS
import { filterActions } from 'actions.js';
import { getPathType } from 'functions.js';

// CORE FUNCTIONS
import Form from 'core/form/Form';

// MAIN COMPONENT
const SelectFilter = compose(
  connect(
    null,
    { ...filterActions }
  )
)(({
  name,
  update,
  useSelect = false,
  // REDUX DISPATCH
  addFilters,
  clearFilters,
  updateFilter,
  clearFilter,
  // REST
  ...props
}) => {

  // CALLBACKS
  const handleValueChange = useCallback(
    ({ value, order = 'A' }) => {
      addFilters(getPathType(), {
        [name]: value,
        order
      })
    },
    [name, addFilters]
  )

  const handleOnSelect = useCallback(
      (value, target, order = 'A') => {
        if (!useSelect) return undefined;
        addFilters(getPathType(), {
          [name] : value,
          order
        })
      },
      [name, addFilters, useSelect]
  )

  // RENDER
  return (
    <Form.Select
      name={name}
      onValueChange={update && !useSelect ? handleValueChange : undefined}
      onSelect={handleOnSelect}
      {...props}
    />
  )
})

// EXPORT
export default SelectFilter
