import React, { useMemo } from 'react';

// DEPENDENCIES
import { useLocation } from 'react-router-dom';

// GLOBAL VARIABLES
import { PATHNAMES } from 'pathnames.js';

// GLOBAL FUNCTIONS
import { getPathType, makePath } from 'functions.js';

// LOCAL COMPONENTS
import Action from '../prototype/Action';

// MAIN COMPONENT
export default ({
  path,
  step,
  search,
  recordID,
  ...props
}) => {

  // HOOKS
  const location = useLocation();

  // MEMOS
  const pathMemo = useMemo(
    () => {
      if (path) return path;
      return makePath(PATHNAMES[getPathType()], PATHNAMES[step]);
    },
    [path, step]
  )

  // RENDER
  return (
    <Action
      to={`${makePath(pathMemo, recordID)}${search || location.search}`}
      {...props}
    />
  )
}
