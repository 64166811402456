import React, { forwardRef, useMemo } from 'react';

// DEPENDENCIES
import { connect } from 'react-redux';
import { compose } from 'redux';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';

// GLOBAL FUNCTIONS
import { sessionActions } from 'actions.js';

// HELPERS
import { sessionContext } from '../../helpers/sessionContext';

// LOCAL COMPONENTS
import Session from '../Session';

// MAIN COMPONENT
const Dealerships = {}

// CHILD COMPONENTS
Dealerships.Wrapper = compose(
  sessionContext({
    label: 'dealership',
    endpoint: ENDPOINTS.session.changeDealership,
    param: 'make_dealer_id',
    onSuccess: sessionActions.setDealership,
    isLogin: true
  })
)(({
  children
}) => children)

Dealerships.Toggle = compose(
  connect(
    ({ user, dealership }) => ({ user, dealership })
  )
)(({
  // REDUX STATE
  user: {
    stores
  },
  dealership,
  // REST
  ...props
}) => {

  // MEMOS
  const label = useMemo(
    () => {
      const store = stores.find(store => store.make_dealer_id === dealership) || {};
      return store.dealer_name;
    },
    [stores, dealership]
  )

  // RENDER
  return(
    <Session.Toggle
      label={label}
      {...props}
    />
  )
})

Dealerships.List = compose(
  connect(
    ({ user, dealership }) => ({ user, dealership })
  ),
  forwardRef
)(({
  as,
  // REDUX STATE
  user: {
    stores
  },
  dealership
}, ref) => stores.map((store, i) => (
  <Session.Item
    key={i}
    as={as}
    label={store.dealer_name}
    value={store.make_dealer_id}
    active={store.make_dealer_id === dealership}
    ref={ref}
  />
)))

Dealerships.label="Dealership";


// EXPORT
export default Dealerships
