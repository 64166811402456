// GLOBAL VARIABLES
import { DATE, DOLLARS } from 'defaults.js';

// GLOBAL FUNCTIONS
import { format } from 'functions.js';

// LOCAL FUNCTIONS
const duration = label => ({
  format: value => format.plural(value.substring(0, 2), label),
  removeFormatting: formattedValue => String(formattedValue).replace(new RegExp(` ${label}(s)?`), ''),
  placeholder: `XX ${label}s`,
  decimalScale: 0,
  allowNegative: false,
})

// EXPORTS
export const NUMBER_FORMATS = {
  age: {
    format: format.age
  },
  date: {
    format: DATE.yearLength === 2 ? '##/##/##' : '##/##/####',
    placeholder: DATE.yearLength === 2 ? 'mm/dd/yy' : 'mm/dd/yyyy',
    mask: DATE.yearLength === 2 ? ['m', 'm', 'd', 'd', 'y', 'y'] : ['m', 'm', 'd', 'd', 'y', 'y', 'y', 'y']
  },
  dateRange: {
    format: DATE.yearLength === 2 ? '##/##/##-##/##/##' : '##/##/####-##/##/####',
    placeholder: DATE.yearLength === 2 ? 'mm/dd/yy-mm/dd/yy' : 'mm/dd/yyyy-mm/dd/yyyy',
    mask: DATE.yearLength === 2 ? ['m', 'm', 'd', 'd', 'y', 'y', 'm', 'm', 'd', 'd', 'y', 'y'] : ['m', 'm', 'd', 'd', 'y', 'y', 'y', 'y', 'm', 'm', 'd', 'd', 'y', 'y', 'y', 'y']
  },
  days: duration('day'),
  dollars: {
    prefix: '$ ',
    thousandSeparator: true,
    decimalScale: DOLLARS.decimalScale,
    fixedDecimalScale: true,
    placeholder: '$ X,XXX'
  },
  hours: duration('hour'),
  miles: {
    thousandSeparator: true,
    decimalScale: 2,
    placeholder: 'X,XXX'
  },
  percent: {
    suffix: '%',
    decimalScale: 2,
    allowNegative: false,
    placeholder: 'XX%'
  },
  postalCode: {
    //format: '#####-####',
    //placeholder: 'XXXXX-XXXX',
    format: '#####',
    placeholder: 'XXXXX',
    mask: 'X'
  },
  tel: {
    format: '###-###-####',
    //format: '+1 (###) ###-####',
    placeholder: 'XXX-XXX-XXXX',
    //placeholder: '+1 (XXX) XXX-XXXX',
    mask: 'X',
    useFormattedValue: true
  },
  time: {
    format: '##:## ##',
    placeholder: 'hh:mm'
  },
  year: {
    format: '####',
    placeholder: 'XXXX',
    mask: 'X'
  }
}
