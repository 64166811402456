import React from 'react';

// DEPENDENCIES
import clsx from 'clsx';

// STYLES
import styles from './cardTitle.module.scss';

// MAIN COMPONENT
const CardTitle = ({
  children,
  className,
  ...props
}) => !children ? null : (
  <h3
    className={clsx(
      styles.title,
      props.onClick && styles.pointer,
      className
    )}
    {...props}
  >{children}</h3>
)

export default CardTitle;
