import React, { useCallback, useMemo } from 'react';

// DEPENDENCIES
import pick from 'lodash/pick';
import { connect } from 'react-redux';
import { compose } from 'redux';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';

// GLOBAL FUNCTIONS
import { sessionActions } from 'actions';
import { apiFetch, makePath } from 'functions';

// CORE COMPONENTS
import Form from 'core/form/Form';

// PARTS
import Record from 'parts/interface/record/Record';

// VARIABLES
const USER_FIELDS = [
  'first_name',
  'last_name',
  'email_address'
]

// MAIN COMPONENT
const Profile = compose(
  connect(
    ({ user }) => ({ user }),
    { ...sessionActions }
  )
)(({
  // REDUX STATE
  user: {
    site_user_id,
    username,
    ...user
  },
  // REDUX DISPATCH
  updateUserProfile
}) => {

  // MEMOS
  const initialValuesMemo = useMemo(
    () => pick(user, USER_FIELDS),
    [user]
  )

  // CALLBACKS
  const handleSuccess = useCallback(
    () => {
      apiFetch({
        endpoint: makePath(ENDPOINTS.record.user.get, site_user_id),
        onSuccess: data => {
          updateUserProfile(pick(data, USER_FIELDS))
        }
      })
    },
    [site_user_id, updateUserProfile]
  )

  // RENDER
  return (
    <Record
      documentTitle="Account"
      recordID={site_user_id}
      label="account"
    >
      <Record.Form
        method="PUT"
        endpoint={ENDPOINTS.user.account}
        initialValues={initialValuesMemo}
        onSuccess={handleSuccess}
        redirect={false}
        cancelButton={false}
      >
        <Record.Card
          title="My Account"
        >
          <Record.Card.Body>
            <Form.Body>
              <Form.Control
                label="Username"
                value={username}
                plaintext
              />
              <Form.Control
                name="first_name"
                label="First Name"
                required
              />
              <Form.Control
                name="last_name"
                label="Last Name"
                required
              />
              <Form.Control
                name="email_address"
                label="Email Address"
                type="email"
                required
              />
            </Form.Body>
          </Record.Card.Body>
        </Record.Card>
      </Record.Form>
    </Record>
  )
})

export default Profile
