import React, { useEffect, useMemo } from 'react';

// DEPENDENCIES
import { useFormikContext } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'redux';

// HOOKS
import useRouteCheck from 'parts/interface/helpers/routeCheck';

// CORE COMPONENTS
import Form from 'core/form/Form';

// MAIN COMPONENT
const CharKey = connect(
  compose(
    ({ record }) => ({ record })
  )
)(({
  name = 'char_key',
  label = 'Unique Key',
  // REDUX STATE
  record: {
    [name]: value
  },
  ...props
}) => {

  // FORMIK
  const { setFieldValue, values } = useFormikContext() || {};

  // ROUTING
  const isEdit = useRouteCheck('edit');

  // MEMOS
  const propsMemo = useMemo(
    () => isEdit ? {
      label,
      value
    } : {
      name,
      label,
      schema: 'charkey'
    },
    [name, label, value, isEdit]
  )

  // EFFECTS
  useEffect(
    () => {
      if (isEdit && values[name]) setFieldValue(name, undefined)
    },
    [name, isEdit, values, setFieldValue]
  )

  // RENDER
  return (
    <Form.Control
      plaintext={isEdit}
      required={!isEdit}
      {...propsMemo}
      {...props}
    />
  )
})

//EXPORT
export default CharKey;
