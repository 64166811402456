import React, { useMemo } from 'react';

// DEPENDENCIES
import get from 'lodash/get';

// CORE COMPONENTS
import Form from 'core/form/Form';
import { useFormikContext } from 'formik';

// VARIABLES
const OPTIONS_WITH_NA = [
  {}, {}, {
    label: 'N/A',
    value : ''
  }
]

// MAIN COMPONENT
const YesNoNA = ({
  options,
  ...props
}) => {

  // PROPS
  const { name } = props;

  // FORMIK
  const { values } = useFormikContext() || {};

  // MEMOS
  const value = useMemo(
    () => get(values, name),
    [name, values]
  )
  const optionsMemo = useMemo(
    () => [
      {
        ...OPTIONS_WITH_NA[0],
        ...(options?.[0] || {})
      },
      {
        ...OPTIONS_WITH_NA[1],
        ...(options?.[1] || {})
      },
      {
        ...OPTIONS_WITH_NA[2],
        ...(options?.[2] || {}),
        checked: value === '' || value === undefined
      },
    ],
    [options, value]
  )

  // RENDER
  return (
    <Form.YesNo
      options={optionsMemo}
      {...props}
    />
  )
}

//EXPORT
export default YesNoNA;
