import React from 'react';

// GLOBAL VARIABLES
import { PATHNAMES } from 'pathnames.js';

// GLOBAL FUNCTIONS
import { makePath } from 'functions.js';

// ROUTER COMPONENTS
import { Redirect, Route, Switch } from "react-router-dom";

// ROUTES
import Dashboard from './routes/Dashboard';
import Edit from './routes/edit/Edit';
import List from './routes/list/List';
import User from '../user/User'
import View from "./routes/view/View";

// MAIN COMPONENT
const DefaultRouter = () => (
  <Switch>
    <Route
      path={makePath(PATHNAMES.dashboard)}
      component={Dashboard}
      exact
    />
    {[
      'applications',
      'applicationConfiguration',
      'applicationRoles',
      'dealerships',
      'dealershipHours',
      'organizationTitles',
      'requestPages',
      'seoMetadata',
      'serviceTransportation',
      'websiteGeneralInfo',
      'leadRouting',
      'leadForms',
      'websiteGeneralInfo',
      'users',
      'leadFormCategory',
      'dealershipGroups'
    ].reduce((arr, type, i) => {

      const ListComponent = List[type];
      const EditComponent = Edit[type];
      const ViewComponent = View[type];

      const { canAdd = true, useID = true } = ListComponent;

      return ([
        ...arr,
        <Route
          key={2 * i + 0}
          path={[
            makePath(PATHNAMES[type], PATHNAMES.page, ':page', '(search)'),
            makePath(PATHNAMES[type], PATHNAMES.page, ':page'),
            makePath(PATHNAMES[type], '(search)'),
            makePath(PATHNAMES[type]),
          ]}
          component={ListComponent}
          exact
        />,
        <Route
          key={2 * i + 1}
          path={canAdd ? [
            makePath(PATHNAMES[type], PATHNAMES.add),
            makePath(PATHNAMES[type], PATHNAMES.edit, ':recordID')
          ] : useID ? makePath(PATHNAMES[type], PATHNAMES.edit, ':recordID') : makePath(PATHNAMES[type], PATHNAMES.edit)}
          component={EditComponent}
          exact
        />,
        <Route
          key={2 * i + 2}
          path={makePath(PATHNAMES[type], PATHNAMES.view, ':recordID')}
          component={ViewComponent}
          exact
        />
      ])
    }, [])}
    <Route
      path={makePath(PATHNAMES.user, PATHNAMES.profile)}
      component={User.Profile}
    />
    <Route
      path={makePath(PATHNAMES.user, PATHNAMES.changePassword)}
      component={User.ChangePassword}
    />
    
    <Redirect
      from={makePath(PATHNAMES.user)}
      to={makePath(PATHNAMES.user, PATHNAMES.profile)}
    />

    <Redirect
      to={makePath(PATHNAMES.dashboard)}
    />
    
  </Switch>
)

// EXPORT
export default DefaultRouter;
