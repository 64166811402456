import React, { forwardRef, useMemo } from 'react';

// GLOBAL FUNCTIONS
import { format, safeDate } from 'functions.js';

// MAIN COMPONENT
export const dateValue = Component => {
  return forwardRef(({
    value,
    ...props
  }, ref) => {

    // PROPS
    const {
      name,
      startName,
      endName,
      time,
      range ,
      multiple,
      startDate = Array.isArray(value) ? value[0] : undefined,
      endDate   = Array.isArray(value) ? value[1] : undefined,
      form: {
        values
      } = {}
    } = props;

    // MEMOS
    const {
      [name]:      dateValue      = range && Array.isArray(value) ? value.filter(el => el).join('-') : value,
      [startName]: startDateValue = startDate,
      [endName]:   endDateValue   = endDate
    } = useMemo(
      () => values || {},
      [values]
    )
    const dateValues = useMemo(
      () => {
        if (time) {
          return ({
            currentDate: dateValue ? format.date(safeDate(dateValue)) : undefined,
            currentTime: dateValue ? format.time(safeDate(dateValue)) : undefined
          })
        } else if (multiple) {
          return ({
            currentDate: dateValue
          })
        } else if (!range) {
          return ({
            currentDate: format.date(dateValue)
          })
        } else if (dateValue) {
          return({
            dateRange:   dateValue,
            startDate:   format.date(dateValue.split('-')[0]),
            endDate:     format.date(dateValue.split('-')[1])
          })
        } else if (startDateValue || endDateValue) {
          return ({
            dateRange:   [format.date(startDateValue), format.date(endDateValue)].join('-'),
            startDate:   format.date(startDateValue),
            endDate:     format.date(endDateValue)
          })
        } else return {};
      },
      [time, range, multiple, dateValue, startDateValue, endDateValue]
    )

    // RENDER
    return <Component
      {...props}
      {...dateValues}
      ref={ref}
    />
  })
}
