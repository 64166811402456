// DEPENDENCIES
import _ from 'lodash';
import queryString from 'query-string';

// REDUX
import { store } from 'store.js';
import { alertActions, modalActions, sessionActions } from 'actions.js';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';
import { FETCH, DATE, LOGOUT } from 'defaults.js';
import { DEBUG, ENVIRONMENTS, MODAL_PRIORITY, PATH, PERMISSIONS, SITE_TITLE, USER_ROLES } from 'globals.js';
import { PATHNAMES } from 'pathnames.js';


// Utility

export const bugLog = (feedback, debug = DEBUG, name) => {
  if (name) feedback = `[${name}] ${feedback}`;
  if (debug) console.log(feedback);
}

export const valueLog = (feedback, debug, name, value) => {
  const valueString = value === undefined ? 'UNDEFINED' : value === null ? 'NULL' : value === '' ? `''` : String(value);
  bugLog(`${feedback}: ${valueString}`, debug, name);
}

export const doCallback = (callback, ...rest) => {
  if (callback === undefined) return;
  if (_.isFunction(callback)) return callback(...rest);
}

export const isTruthy = (value = '') => {
  if (value === true || value.toString().toLowerCase() === 'true' || value.toString() === '1') return true;
}

export const isFalsy = (value = '') => {
  if (value === false || value.toString().toLowerCase() === 'false' || value.toString() === '0') return true;
}

export const toBoolean = value => {
  if (isTruthy(value)) return true;
  if (isFalsy(value)) return false;
  return;
}
export const blankToNull = (value, originalValue) => originalValue.trim() === "" ? null: value;

export const nanToNull = value => isNaN(value) ? undefined : value;

export const isToday = (date) => {
  const today = new Date();
  const day = new Date(date);
  if (isNaN(day)) return;
  return day.getDate() === today.getDate() &&
    day.getMonth() === today.getMonth() &&
    day.getFullYear() === today.getFullYear()
}

export const randomID = () => Math.random().toString(36).substring(2, 15).toUpperCase();

export const controlName = (name, ...parents) => [...parents, name].filter(p => p !== undefined && p !== null && p !== '').join('.').toLowerCase()

export const setDocumentTitle = documentTitle => document.title = documentTitle ? `${documentTitle} | ${SITE_TITLE}` : SITE_TITLE;

export const checkUser = (key, userProperty, global) => {
  if (!key) return true;

  let found = false;
  const checkHaystack = key => {
    const needle = global[key];
    const haystack = store.getState().user[userProperty];
    if (!needle) found = true;
    if (Array.isArray(haystack)) {
      if (haystack.includes(needle)) found = true;
    } else {
      if (needle === haystack) found = true;
    }
  }

  if (Array.isArray(key)) {
    key.forEach(e => checkHaystack(e))
  } else {
    checkHaystack(key)
  }
  return found;
}

export const hasUserRole = role => {
  return checkUser(role, 'role_key', _.reduce(USER_ROLES, (r, { key }, k) => { r[k] = key; return r }, {}));
}

export const isPermitted = permission => {
  return checkUser(permission, 'permissions', PERMISSIONS);
}


// API

export const getEnv = key => {
  let val = ENVIRONMENTS.dev[key];
  Object.keys(ENVIRONMENTS).forEach(env => {
    if (window.location.hostname === ENVIRONMENTS[env].hostname) val = ENVIRONMENTS[env][key];
  })
  return val;
}

export const getURL = () => `${getEnv('url')}`;

export const getKey = () => getEnv('key');

export const stringifyBody = (values, stringify, blacklist = []) => {
  const clonedValues = _.cloneDeep(values);
  if (Array.isArray(clonedValues)) {
    return FETCH.useCSV ? clonedValues.join(',') : clonedValues;
  } else if (typeof clonedValues === 'object') {
    _.keys(clonedValues).forEach(key => {
      if (blacklist.includes(key)) delete clonedValues[key];
      else clonedValues[key] = stringifyBody(clonedValues[key]);
    })
    return stringify ? JSON.stringify(clonedValues) : clonedValues;
  } else {
    return clonedValues;
  }
}

export const apiFetch = ({
  method = 'GET',
  path = PATH,
  endpoint,
  url = 'https:/' + makePath(getURL(), path, endpoint),
  token = store.getState().token,
  headers = {},
  body,
  params = {},
  queryParams,
  useQueryString,
  isFile,
  onFetch,
  onResponse,
  onSuccess,
  onError,
  loadingMessage,
  successMessage,
  errorMessage,
  useErrorMessage = true,
  messageFunctions = modalFunctions,
  debugOnly,
  debug = debugOnly,
  logOut401 = true,
  isLogin = false,
  custom = false,
}) => {

  //debug = true;
  let fetchLog = feedback => bugLog(feedback, debug);

  if (!endpoint) {
    doCallback(onError);
    return console.error('No endpoint specified!');
  }

  let config = {};
  config.method = method;
  config.headers = Object.assign({
    'Content-Type': 'application/json',
    'x-api-key': getKey()
  }, headers);
  config.credentials = 'include';
  if (token) config.headers['Authorization'] = `Bearer ${token}`;
  if (body) config.body = body;
  if (queryParams) url += '?' + queryString.stringify(queryParams);
  if (!_.isEmpty(params)) {
    if (method === 'GET' || useQueryString) url += '?' + queryString.stringify(params);
    else config.body = stringifyBody(params, true, FETCH.blacklist);
  }

  if (!_.isEmpty(params)) fetchLog(params);

  fetchLog(url);
  fetchLog(config);

  if (debugOnly) return;

  const clearLoadingMessage = loadingMessage ? doCallback(messageFunctions.loading, loadingMessage) : undefined;
  doCallback(onFetch);

  fetch(url, config)
    .then(async response => {
      fetchLog(response);
      doCallback(clearLoadingMessage);
      doCallback(onResponse, response);
      if (response.status === 401 && logOut401) {
        logOut({
          alerts: [
            {
              variant: 'danger',
              message: response.error || response.message || 'You are not authorized to access the requested resource.'
            }
          ]
        })
      }
      if (!response.ok) {
          const result = await response.json();
          let error = result.errors[0] || result.message || errorMessage;
          throw new Error(error);
      }
      else return isFile ? response.arrayBuffer() : response.json();
    })
    .then(data => {
      fetchLog(data);
      if (data.status === false) {
        if (useErrorMessage) doCallback(messageFunctions.error, data.ERROR || data.error || data.message || data.errors[0] || errorMessage);
        doCallback(onError, data);
      } else {
        doCallback(messageFunctions.success, successMessage);
        doCallback(onSuccess, (isLogin || custom) ? data : data?.result || data);
      }
    })
    .catch((response) => {
      console.error(response);
      if (useErrorMessage) {
        doCallback(messageFunctions.error, response?.message || errorMessage);
        return;
      }
      doCallback(clearLoadingMessage);
      doCallback(onError);
    })
}

export const fileFetch = args => apiFetch({ ...args, isFile: true });

export const fileBlob = (file, blobType = 'application/pdf') => URL.createObjectURL(new Blob([file], {type: blobType !== false ? blobType : ''}));

export const fileDownload = (url, download = true) => {
  if (!url) return;
  let a = document.createElement('a');
  a.href = url;
  a.download = download;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

// Session

export const timeExpired = timeStamp => timeStamp && new Date(timeStamp).getTime() < _.now();

export const loggedIn = (token, tokenExp) => token && !timeExpired(tokenExp);

export const logOut = (payload, useFetch = LOGOUT.useFetch) => {

  const LOGOUT_ALERT = {
    type: 'loading',
    message: 'Logging out',
    priority: MODAL_PRIORITY.override
  }

  const dispatchLogout = () => store.dispatch(sessionActions.logout(payload))

  if (useFetch) {

    const args = {
      method: 'GET',
      endpoint: ENDPOINTS.session.logout,
      onResponse: modalFunctions.add(LOGOUT_ALERT),
      onSuccess: dispatchLogout,
      errorMessage: 'Unable to sign out.'
    }

    apiFetch(args);
  } else {
    dispatchLogout();
  }
}


// Router

export const makePath = (...parts) => _.replace(`/${_.filter(parts, p => !!p).map(p => _.trim(p, '/')).join('/')}/`, '//', '/');

export const getFirstPage = (path = window.location.pathname) => path.split('page')[0];

export const getPathParts = (path = window.location.pathname) => path.replace('https://' + getURL(), '').split('/').filter(el => el);

export const getPathKey = (path, paths = PATHNAMES) => _.findKey(paths, el => makePath(el) === makePath(path))

export const getPathType = path => getPathKey(getPathParts(path)[0]);

export const getPathStep = path => getPathKey(getPathParts(path)[1]);

export const googleAddress = (address) => {
  if (!address)
    return undefined;
  else
    return `https://www.google.com/maps/?q=${address.replace(/ ,/g, '').replace(/ /g, '+')}`;
}


// Alerts / Modals

const addAlert = alert => {
  if (!alert || !alert.message) return;
  alert.ID = randomID();
  store.dispatch(alertActions.add(alert));
  return () => store.dispatch(alertActions.remove(alert.ID));
}

export const alertFunctions = {
  add:     addAlert,
  remove:  alertID => store.dispatch(alertActions.remove(alertID)),
  clear:   alerts  => store.dispatch(alertActions.clear(alerts)),
  message: message => addAlert({ message, variant: 'secondary' }),
  success: message => addAlert({ message, variant: 'success' }),
  warning: message => addAlert({ message, variant: 'warning' }),
  danger:  message => addAlert({ message, variant: 'danger' }),
  info:    message => addAlert({ message, variant: 'info' }),
  error:   message => addAlert({ message, variant: 'danger' }),
  loading: message => addAlert({ message, type: 'loading' })
}


const addModal = modal => {
  if (!modal) return;
  modal.ID = randomID();
  if (modal.priority === undefined) modal.priority = MODAL_PRIORITY.default;
  store.dispatch(modalActions.add(modal));
  return () => store.dispatch(modalActions.remove(modal.ID));
}

export const modalFunctions = {
  add:          addModal,
  remove:       modalID      => store.dispatch(modalActions.remove(modalID)),
  increment:    ()           => store.dispatch(modalActions.increment()),
  clear:        ()           => store.dispatch(modalActions.clear()),
  message:      message      => !message ? null : addModal({ message, type: 'alert', variant: 'secondary' }),
  success:      message      => !message ? null : addModal({ message, type: 'alert', variant: 'success' }),
  warning:      message      => !message ? null : addModal({ message, type: 'alert', variant: 'warning' }),
  danger:       message      => !message ? null : addModal({ message, type: 'alert', variant: 'danger' }),
  info:         message      => !message ? null : addModal({ message, type: 'alert', variant: 'info' }),
  error:        message      => !message ? null : addModal({ message, type: 'alert', variant: 'danger'}),
  loading:      message      => !message ? null : addModal({ message, type: 'loading', priority: MODAL_PRIORITY.loading }),
  confirmation: (props = {}) => addAlert({ type: 'confirmation', ...props })
}


// Numbers / Date / Time

export const precedingZero = (num) => {
  let numString = num.toString();
  if (numString.length === 1)
    numString = '0' + numString;
  return numString;
}

export const toNumber = (num) => {
  return isNaN(num) ? 0 : num;
}

export const formatPlural = (value = 0, unit, usePrecedingZero = DATE.usePrecedingZero, usePlural = true) => {
  const float = parseFloat(value);
  if (isNaN(float)) return '';
  if (float === 0 && !usePrecedingZero) return '';
  return `${float} ${unit}${parseFloat(float) !== 1 && usePlural ? 's' : ''}`;
};

export const formatAge = (value = 0) => {
  if (isNaN(value)) return value;
  const age = parseInt(value, 0);
  if (age === 0) return 'Now';
  const strings = [];
  const days = Math.floor(age/1440);
  strings.push(days > 0 ? `${days}d` : '');
  const hours = Math.floor(age/60) % 24;
  strings.push(days < 2 && hours > 0 ? `${hours}h` : '');
  const minutes = age % 60;
  strings.push(days < 1 && hours < 12 && minutes > 0 ? `${minutes}m` : '');
  return strings.join(' ');
}

export const formatDollar = amount => {
  if (amount === undefined || amount === null || amount === '') return;
  if (isNaN(amount)) return 'NaN';
  const dollarFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })
  return dollarFormat.format(parseFloat(amount)).replace(/^(\D+)/, '$1 ')
}

export const nameCase = (string = '') => string.split(' ').map(string => _.upperFirst(string)).join(' ');

export const compare = (value, operator, operand) => {
  switch (operator) {
    case '===':
      return value === operand;
    case '>':
      return value > operand;
    case '<':
      return value < operand;
    case '>=':
      return value >= operand;
    case '<=':
      return value <= operand;
    default:
      return undefined;
  }
}

export const checkDate = (value, operator, operand) => {
  value = getDay(value).getTime();
  operand = getDay(operand).getTime();

  if (typeof operator === 'undefined') // If no operator is defined, just check that value is a valid date.
    return !isNaN(value);

  if (isNaN(value) && isNaN(operand)) // Else, first check that dates are valid.
    return undefined;

  return compare(value, operator, operand); // Return comparison;
};

export const checkTime = value => {
  let date = getTimeDate(value);

  return checkDate(date);
}

export const getDay = (date) => {
  date = date instanceof Date ? date : new Date(date);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export const getTimeDate = (timeString) => {
  return timeString instanceof Date ? timeString : new Date(`January 1 1970 ${timeString}`);
}

export const getHours = (timeString, usePrecedingZero = false) => {
  const time = getTimeDate(timeString);

  const convertToTwelveHours = (hours) => {
    const h = hours % 12;
    return h === 0 ? 12 : h;
  }
  const hours = convertToTwelveHours(time.getHours());

  return usePrecedingZero ? precedingZero(hours) : hours;
}

export const getMinutes = (timeString, usePrecedingZero = true) => {
  const time = getTimeDate(timeString);

  return usePrecedingZero ? precedingZero(time.getMinutes()) : time.getMinutes();
}

export const getMeridian = (timeString) => {
  const time = getTimeDate(timeString);

  return time.getHours() > 11 ? 'PM' : 'AM';
}

export const getMidnight = (date) => {
  date = date instanceof Date ? date : new Date(date);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export const timeToObject = (timeString) => {
  if (!timeString) return;
  return {
    hours: getHours(timeString, false),
    minutes: getMinutes(timeString, false),
    meridian: getMeridian(timeString)
  }
}

export const timeToString = (timeObject, usePrecedingZero) => {
  if (!timeObject) return;
  const hours = usePrecedingZero ? precedingZero(timeObject.hours) : timeObject.hours;
  const minutes = precedingZero(timeObject.minutes);
  const meridian = timeObject.meridian;

  return `${hours}:${minutes} ${meridian}`;
}

export const safeDate = date => {
  if (!date) return;
  if (date instanceof Date) return date;
  const dateObject = new Date(date);
  return isNaN(dateObject.getTime()) ? '' : dateObject;
}

export const safeTime = (time, date = '1/1/1') => {
  if (!time) return;
  if (time instanceof Date) return time;
  return safeDate([date, time].join(' '));
}

export const compareDate = (value, operator, operand) => {
  value = getMidnight(value).getTime();
  operand = getMidnight(operand).getTime();

  if (typeof operator === 'undefined') // If no operator is defined, just check that value is a valid date.
    return !isNaN(value);

  if (isNaN(value) && isNaN(operand)) // Else, first check that dates are valid.
    throw Error;

  return compare(value, operator, operand); // Return comparison;
}

export const formatDate = (value, yearLength = DATE.yearLength, usePrecedingZero = DATE.usePrecedingZero, separator = '/') => {
  const date = safeDate(value);
  if (!date) return '';
  let month = date.getMonth() + 1;
  if (usePrecedingZero) month = precedingZero(month);
  let day = date.getDate();
  if (usePrecedingZero) day = precedingZero(day);
  let year = date.getFullYear().toString().substring((4 - yearLength), 4);
  return `${month}${separator}${day}${separator}${year}`;
}

export const formatTime = (value, usePrecedingZero) => {
  const time = safeTime(value);
  if (!time) return '';
  return timeToString(timeToObject(time), usePrecedingZero);
}

export const formatDateTime = (value, yearLength = 4, usePrecedingZero = false, separator = '/') => {
  if (!value) return value;
  return `${formatDate(value, yearLength, usePrecedingZero, separator)} ${formatTime(safeDate(value), usePrecedingZero)}`
}

export const format = {
  age:      formatAge,
  date:     formatDate,
  dateTime: formatDateTime,
  dollar:   formatDollar,
  plural:   formatPlural,
  time:     formatTime
}
