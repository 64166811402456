import React, { useCallback } from 'react';

// DEPENDENCIES
import { compose } from 'redux';
import { connect } from 'react-redux';

// GLOBAL FUNCTIONS
import { filterActions } from 'actions.js';
import { getPathType } from 'functions.js';

// CORE FUNCTIONS
import Form from 'core/form/Form';

// MAIN COMPONENT
const ChecklistFilter = compose(
    connect(
        null,
        { ...filterActions }
    )
)(({
       name,
       update,
       // REDUX DISPATCH
       addFilters,
       clearFilters,
       updateFilter,
       clearFilter,
       // REST
       ...props
   }) => {

    // CALLBACKS
    const handleValueChange = useCallback(
        ({ value, order = 'A' }) => {
            if (value) addFilters(getPathType(), {
                [name]: value,
                order
            })
        },
        [name, addFilters]
    )

    // RENDER
    return (
        <Form.Checklist
            type="radio"
            name={name}
            onValueChange={update ? handleValueChange : undefined}
            {...props}
        />
    )
})

// EXPORT
export default ChecklistFilter
