import React from 'react';

// CORE COMPONENTS
import Number from 'core/tools/Number';

// PROTOTYPE
import Badge from '../prototype/Badge';

// MAIN COMPONENT
const NumberBadge = ({
  children,
  schema,
  value = children = '',
  ...props
}) => (
  <Badge {...props}>
    {!value ? '' : !schema ? value : (
      <Number
        schema={schema}
        value={value}
      />
    )}
  </Badge>
)

// EXPORT
export default NumberBadge;
