import React from 'react';

// CORE COMPONENTS
import Form from 'core/form/Form';

// PARTS
import Record from 'parts/interface/record/Record';

// PROTOTYPE
import EditPrototype from '../prototype/EditPrototype';

// MAIN COMPONENT
const SEOMetadata = () => (
  <EditPrototype
    routeKey="seoMetadata"
    idKey="seo_metadata_header_id"
    label="SEO metadata"
    title="SEO Metadata"
  >
    <Form.Control
      name="page_title"
      label="Request Page"
      plaintext
    />
    <Record.Card.Divider />
    <Form.Col>
      <Record.Card.Subtitle>Page Metadata</Record.Card.Subtitle>
    </Form.Col>
    <Form.Control
      name="metadata_title"
      label="Metadata Title"
    />
    <Form.Control
      name="metadata_description"
      label="Metadata Description"
    />
    <Form.Textarea
      name="metadata_keywords"
      label="Metadata Keywords"
    />
    <Record.Card.Divider />
    <Form.Col>
      <Record.Card.Subtitle>Content Headers (H1 & H2)</Record.Card.Subtitle>
    </Form.Col>
    <Form.Control
      name="seo_main_header"
      label="Site Header"
    />
    <Form.Control
      name="seo_sec_header1"
      label="Site Sub-Header Line 1"
    />
    <Form.Control
      name="seo_sec_header2"
      label="Site Sub-Header Line 2"
    />
    <Record.Card.Divider />
    <Form.Col>
      <Record.Card.Subtitle>Page Footer</Record.Card.Subtitle>
    </Form.Col>
    <Form.Control
      name="seo_footer_title"
      label="Footer SEO Title"
    />
    <Form.Textarea
      name="seo_footer_content"
      label="Footer SEO Content"
    />
  </EditPrototype>
)

export default SEOMetadata;

