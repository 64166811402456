import React from 'react';

// TOOLS
import Dealerships from 'parts/interface/session/types/Dealerships';

// LOCAL COMPONENTS
import SessionDropdown from './SessionDropdown';

// MAIN COMPONENT
const DealershipDropdown = () => (
  <SessionDropdown
    component={Dealerships}
    label={Dealerships.label}
  />
)

export default DealershipDropdown
