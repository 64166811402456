import React, { useCallback, useContext } from 'react';

// DEPENDENCIES
import { capitalize, isObject } from 'lodash';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';

// GLOBAL FUNCTIONS
import { apiFetch, doCallback, makePath, modalFunctions } from 'functions.js';

// CONTEXT
import { ListContext } from '../../../helpers/getListData';

// LOCAL COMPONENTS
import Action from '../prototype/Action';

// MAIN COMPONENT
export default ({
  recordID
}) => {

  // CONTEXT
  const { routeKey, label, fetchRecords } = useContext(ListContext) || {};

  // CALLBACKS
  const deleteRecord = useCallback(
    () => {
      const endpoint = isObject(ENDPOINTS.record[routeKey]) ? ENDPOINTS.record[routeKey].delete : ENDPOINTS.record[routeKey];
      if (!endpoint) return;
      apiFetch({
        method: 'DELETE',
        endpoint: makePath(endpoint, recordID),
        loadingMessage: `Deleting ${label}...`,
        successMessage: `${capitalize(label)} deleted.`,
        errorMessage: `Unable to delete ${label}.`,
        onSuccess: () => {
          doCallback(fetchRecords);
        }
      })
    },
    [recordID, routeKey, label, fetchRecords]
  )
  const handleClick = useCallback(
    () => {
      modalFunctions.add({
        type:  'Confirmation',
        title: `Are you sure that you want to permanently delete the selected ${label}?`,
        body:  'This action cannot be undone.',
        cancelButton: {
          label:   'Never Mind'
        },
        continueButton: {
          variant: 'danger',
          label: 'Delete',
          icon: 'trash-alt',
          onClick: deleteRecord
        }
      })
    },
    [label, deleteRecord]
  )

  // RENDER
  return (
    <Action
      variant="danger"
      label="Delete"
      icon="trash-alt"
      onClick={handleClick}
    />
  )
}
