import React, { forwardRef, useContext, useMemo, useEffect } from 'react';

// DEPENDENCIES
import { compact, get, isArray } from 'lodash';
import { compose } from 'redux';

// GLOBAL FUNCTIONS
import { doCallback } from 'functions.js';

// CONTEXT
import { UploadContext } from './uploadContext';

export const uploadFormik = Component => compose(
  forwardRef
)((props, ref) => {

  // PROPS
  const {
    name,
    multiple,
    disabled,
    // FORMIK BAG
    form: {
      setFieldValue,
      initialValues,
    } = {}
  } = props;

  // CONTEXT
  const {
    uploads,
    setUploads
  } = useContext(UploadContext) || {};

  // INITIALIZE CONTEXT
  const initialValue = useMemo( // get initial value form Formik form
    () => get(initialValues, name),
    [name, initialValues]
  )
  useEffect( // add uploads from initial value
    () => {
      if (!initialValue) return;
      let uploads = isArray(initialValue) ? initialValue : [initialValue];
      setUploads(uploads)
    },
    [initialValue, setUploads]
  )

  // UPDATE FORMIK
  const fieldValue = useMemo( // check/update fieldValue when uploads change
    () => {
      const fieldValues = compact(uploads.map(upload => upload.success ? upload.fieldValue : undefined));
      return multiple ? fieldValues : fieldValues[0];
    },
    [uploads, multiple]
  )
  useEffect( // update Formik when fieldValue changes
    () => {
      if (name) doCallback(setFieldValue, name, fieldValue);
    },
    [name, fieldValue, setFieldValue]
  )

  // RESETTING
  useEffect( // clear uploads when disabling
    () => {
      if (disabled) setUploads([]);
    },
    [disabled, setUploads]
  )

  // RENDER
  return <Component {...props} ref={ref} />;
})
