// SESSION

export const configurationActions = {
  setConfiguration: data => ({
    type: 'SET_CONFIGURATION',
    configuration: data
  }),
  clearConfiguration: data => ({
    type: 'CLEAR_CONFIGURATION',
    configuration: data
  })
}

export const sessionActions = {
  login: ({ result, ...data } = {}) => ({
    type: 'LOGIN_USER',
    user: result,
    ...data
  }),
  logout: payload => ({
    type: 'LOGOUT_USER',
    payload
  }),
  extendSession: (data = {}) => ({
    type: 'EXTEND_SESSION',
    ...data
  }),
  setVerification: (data = {}) => ({
    type: 'SET_VERIFICATION',
    verified: true
  }),
  setDealership: (data = {}) => ({
    type: 'SET_DEALERSHIP',
    ...data
  }),
  setUserRole: (data = {}) => ({
    type: 'SET_USER_ROLE',
    ...data
  }),
  updateUserProfile: (data = {}) => ({
    type: 'UPDATE_USER_PRPOFILE',
    profile: data
  })
}


// SETTINGS

export const filterActions = {
  addFilters: (path, filters) => ({
    type: 'ADD_FILTERS',
    path,
    filters
  }),
  clearFilters: (path, init) => ({
    type: 'CLEAR_FILTERS',
    path,
    init
  }),
  addFilter: (path, name, value) => ({
    type: 'UPDATE_FILTER',
    path,
    name,
    value
  }),
  clearFilter: (path, name) => ({
    type: 'CLEAR_FILTER',
    path,
    name
  })
}


// INTERFACE

export const setScreenSize = isMobile => ({
  type: 'SET_SCREEN_SIZE',
  isMobile
})

export const referrerActions = {
  set: referrer => ({
    type: 'SET_REFERRER',
    referrer
  }),
  clear: () => ({
    type: 'CLEAR_REFERRER',
  })
  
}

export const alertActions = {
  add: alerts => ({
    type: 'ADD_ALERTS',
    alerts: Array.isArray(alerts) ? alerts : [ alerts ]
  }),
  remove: alertID => ({
    type: 'REMOVE_ALERT',
    alertID
  }),
  clear: alerts => ({
    type: 'CLEAR_ALERTS',
    alerts: alerts
  })
}

export const modalActions = {
  add: modal => ({
    type: 'ADD_MODAL',
    modal
  }),
  remove: modalID => ({
    type: 'REMOVE_MODAL',
    modalID
  }),
  increment: () => ({
    type: 'INCREMENT_MODAL'
  }),
  clear: () => ({
    type: 'CLEAR_MODALS'
  })
}

export const toggleMenu = toggle => ({
  type: 'TOGGLE_MENU',
  toggle
})

export const sidebarActions = {
  toggle: (sidebarName, recordID) => ({
    type: 'TOGGLE_SIDEBAR',
    sidebarName,
    recordID
  }),
  close: () => ({
    type: 'TOGGLE_SIDEBAR'
  })
}


// DATA

export const lookupActions = {
  init: key => ({
    type: 'INIT_LOOKUP',
    key
  }),
  load: (key, payload = []) => ({
    type: 'LOAD_LOOKUP',
    key,
    payload
  }),
  error: key => ({
    type: 'LOOKUP_ERROR',
    key
  }),
  flush: key => ({
    type: 'FLUSH_LOOKUP',
    key
  })
}

export const listActions = {
  load: (data = {}) => ({
    type: 'LOAD_RECORDS',
    ...(Array.isArray(data) ? {
      total: data.length,
      list: data
    }: {
      total: data.total_records,
      list: data.result
    })
  }),
  loadCustomData: (data = {}) => ({
    type: 'LOAD_CUSTOM_RECORDS',
    list: data.result,
    total: data.totalRecords,
    uniqueRecords: data.uniqueLeadCount,
  }),
  append: (data = {}) => ({
    type: 'APPEND_RECORDS',
    ...(Array.isArray(data) ? {
      total: data.length,
      list: data,
    }: {
      total: data.total_records,
      list: data.result,
    })
  }),
  appendCustomData: (data = {}) => ({
    type: 'APPEND_CUSTOM_RECORDS',
    total: data.result?.length,
    list: data.result,
  }),
  flush: () => ({
    type: 'FLUSH_RECORDS'
  })
}

export const recordActions = {
  load: data => ({
    type: 'LOAD_RECORD',
    data
  }),
  error: message => ({
    type: 'RECORD_ERROR',
    message
  }),
  append: (name, data) => ({
    type: 'APPEND_RECORD',
    name,
    data
  }),
  flush: () => ({
    type: 'FLUSH_RECORD'
  })
}

export const documentActions = {
  load: (name, url) => ({
    type: 'LOAD_FILE',
    name,
    url
  }),
  error: (name, message) => ({
    type: 'FILE_ERROR',
    name,
    message
  }),
  clear: name => ({
    type: 'CLEAR_FILE',
    name
  }),
  flush: () => ({
    type: 'FLUSH_FILES'
  })
}
// Testing again