import React from 'react';

// DEPENDENCIES
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';
import { PATHNAMES } from 'pathnames.js';

// GLOBAL FUNCTIONS
import { makePath } from 'functions.js';

// BOOTSTRAP COMPONENTS

// CORE COMPONENTS
import Form from 'core/form/Form';

// PARTS
import Page from 'parts/authentication/Page';

// MAIN COMPONENT
const ResetPassword = compose(
  withRouter
)(({
  history
}) => (
  <Page
    documentTitle="Password"
    title="Forgotten password"
    subtitle="Enter the username associated with your account. A password reset link will be sent to the email associated with your account."
    formArgs={{
      endpoint: ENDPOINTS.session.resetPassword,
      onSuccess: () => history.push({
        pathname: makePath(PATHNAMES.login),
        state: {
          alerts: [
            {
              variant: 'success',
              message: 'A password reset link has been sent to your email.'
            }
          ]
        }
      }),
      loadingMessage: 'Requesting password reset link',
      errorMessage: 'Unable to request password reset link.'
    }}
  >
    <Form.Body.Vertical>
      <Form.Control type="text" name="login" label="Username" required autoComplete={true} />
    </Form.Body.Vertical>
    <Page.Footer>
      <Page.Button.Back
        to={makePath(PATHNAMES.login)}
      />
      <Page.Button.Submit
        label="Request Password Reset"
      />
    </Page.Footer>
  </Page>
))

// EXPORT
export default ResetPassword;
