import React, { forwardRef, useCallback } from 'react';

// GLOBAL FUNCTIONS
import { doCallback, format } from 'functions.js';

// MAIN COMPONENT
export const timeFormat = Component => {
  return forwardRef((props, ref) => {

    // PROPS
    const {
      name,
      form: {
        setFieldValue
      } = {},
      onBlur,
      onTimeChange
    } = props;

    // CALLBACKS
    const handleBlur = useCallback(
      e => {
        const formattedTime = format.time(e.target.value);
        doCallback(onBlur, e);
        if (!formattedTime) return;
        doCallback(onTimeChange, formattedTime);
        setFieldValue(name, formattedTime);
      },
      [name, setFieldValue, onBlur, onTimeChange]
    )

    // RENDER
    return <Component
      {...props}
      onBlur={handleBlur}
      ref={ref}
    />
  })
}
