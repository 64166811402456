import React from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { connect } from 'react-redux';
import { compose } from 'redux';

// GLOBAL VARIABLES
import { SITE_TITLE } from 'globals.js';

// REACT ROUTER COMPONENTS
import { Link } from "react-router-dom";

// LOCAL COMPONENTS
import HeaderButton from './parts/HeaderButton';
import MenuToggle from './tools/MenuToggle';
import DealershipDropdown from './tools/DealershipDropdown';
import UserDropdown from './tools/UserDropdown';
// import UserRoleDropdown from './tools/UserRoleDropdown';

// ASSETS
import Logo from 'img/logo-inverse.png';

// STYLES
import styles from './header.module.scss';

// MAIN COMPONENT
const Header = compose(
  connect(
    ({ mobile }) => ({ mobile })
  )
)(({
  className,
  button,
  // REDUX STATE
  mobile
}) => (
  <header
    id="header"
    className={clsx(
      styles.header,
      className
    )}
  >
    {mobile ? (<>
      <div className={styles.tool}>
        <MenuToggle />
      </div>
      <Link
        to="/"
      >
        <img
          src={Logo}
          height="48"
          alt={SITE_TITLE}
        />
      </Link>
      <div className={styles.tool}>
        {!!button &&
          <HeaderButton
            size="lg"
            square
            {...button}
          />
        }
      </div>
    </>) : (<>
      <div className={styles.tools}>
        <MenuToggle />
        <DealershipDropdown />
        {/* <UserRoleDropdown /> */}
      </div>
      <UserDropdown />
    </>)}
  </header>
))

// EXPORT
export default Header
