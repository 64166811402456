import React, { useCallback, useMemo } from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { get } from 'lodash';

// GLOBAL VARIABLES
import { TOGGLE } from 'defaults.js';

// GLOBAL FUNCTIONS
import { doCallback } from 'functions.js';

// CORE COMPONENTS
import Button from 'core/tools/Button';

// STYLES
import styles from './toggleButton.module.scss';

// MAIN COMPONENT
const ToggleButton = ({
  className,
  variant = TOGGLE.active.variant,
  name,
  value,
  group,
  onClick,
  hasError,
  // FORMIK
  form: {
    setFieldValue,
    setFieldTouched,
    values
  } = {},
  // REST
  ...props
}) => {

  // CHECK STATE
  const handleClick = useCallback(
    () => {
      doCallback(onClick, value);
      doCallback(setFieldTouched, name, true);
      doCallback(setFieldValue, name, value);
    },
    [name, value, onClick, setFieldValue, setFieldTouched]
  )

  const active = useMemo(
    () => value === get(values, name),
    [name, value, values]
  )

  // RETURN
  return (
    <Button
      className={clsx(
        styles.button,
        active && styles.active,
        hasError && styles.error,
        className
      )}
      onClick={handleClick}
      {...(active ? TOGGLE.active : TOGGLE.inactive)}
      variant={group ? variant : active ? variant : TOGGLE.inactive.variant}
      {...props}
    />
  )
}

// EXPORT
export default ToggleButton;
