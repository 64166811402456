import React, { useContext } from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { connect } from 'react-redux';
import { compose } from 'redux';

// HELPERS/CONTEXT
import { menuContext, MenuContext } from '../../helpers/menuContext';

// BOOTSTRAP COMPONENTS
import { Nav, Navbar } from 'react-bootstrap';

// CORE COMPONENTS
import Scrollbox from 'core/tools/scrollbox/Scrollbox';

// PARTS
import MenuBadge from '../parts/MenuBadge';
import MenuBrand from '../parts/MenuBrand';
import MenuItem from '../parts/MenuItem';
import Submenu from '../parts/Submenu';

// TOOLS
import DealershipCollapse from '../tools/DealershipCollapse';
// import UserRoleCollapse from '../tools/UserRoleCollapse';

// STYLES
import styles from './menu.module.scss';

// MAIN COMPONENT
const Menu = compose(
  menuContext,
  connect(
    ({ mobile }) => ({ mobile })
  )
)(({
  children,
  // REDUX STATE
  mobile
}) => {

  // CONTEXT
  const { containerRef, show } = useContext(MenuContext);

  // RENDER
  return (
    <Navbar
      className={clsx(
        styles.navbar,
        show && styles.show
      )}
      expand={false}
      data-show={show}
      ref={containerRef}
    >
      <MenuBrand />
      <Scrollbox
        outerClassName={styles.outer}
        preventDefault
      >
        <div className={styles.inner}>
          {mobile && (<>
            <DealershipCollapse />
            {/* <UserRoleCollapse /> */}
          </>)}
          <Nav className={styles.nav}>
            {children}
          </Nav>
        </div>
      </Scrollbox>
    </Navbar>
  )
})

// CHILD COMPONENTS
Menu.Item    = MenuItem;
Menu.Badge   = MenuBadge;
Menu.Button  = props => <MenuItem {...props} button />;
Menu.Submenu = Submenu;

// EXPORT
export default Menu;
