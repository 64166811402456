import React from 'react';

// DEPENDENCIES
import queryString from 'query-string';

// REACT BOOTSTRAP
import { Col as BootstrapCol, Row as BootstrapRow } from 'react-bootstrap';

// PROTOTYPE
import ListPrototype from '../prototype/ListPrototype';

// PARTS
import List from 'parts/interface/list/List';
const { Action, Col } = List;
const HoursCol = ({ children }) => (
  <BootstrapRow>
    {children.map(({day_of_week, hours}) => (<>
      <BootstrapCol xs="8">{day_of_week}:</BootstrapCol>
      <BootstrapCol xs="16">{hours}</BootstrapCol>
    </>))}
  </BootstrapRow>
)

// COLUMNS
const COLUMNS = ({
  category_name,
  hours
}) => [
  <Col
    cols={{ lg: 12 }}
    label="Category Name"
    value={category_name}
  />,
  <Col
    cols={{ lg: 12 }}
    as={HoursCol}
    label="Hours"
    value={hours}
  />
]

// ACTIONS
const ACTIONS = ({ hours_category }) => [
  <Action.Edit
    search={'?' + queryString.stringify({hours_category})}
  />
]

// MAIN COMPONENT
const DealershipHours = () => <ListPrototype
  routeKey="dealershipHours"
  plural="dealership hours"
  title="Dealership Hours"
  showID={false}
  buttons={[]}
  columns={COLUMNS}
  actions={ACTIONS}
/>
DealershipHours.canAdd = false;
DealershipHours.useID = false;

export default DealershipHours;
