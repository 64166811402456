import React, { useEffect, useMemo } from 'react';

// DEPENDENCIES
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router-dom';
import { compose } from 'redux';

// GLOBAL VARIABLES
import { PATHNAMES } from 'pathnames.js';

// GLOBAL FUNCTIONS
import { referrerActions } from 'actions';
import { loggedIn, makePath } from 'functions.js';

// ROUTER COMPONENTS
import { Redirect } from "react-router-dom";

// MAIN COMPONENT
export const checkRouting = Component => {
  return compose(
    connect(
      ({
        configuration,
        user,
        dealership,
        token,
        tokenExp,
        alerts
      }) => ({
        configuration,
        user,
        dealership,
        token,
        tokenExp,
        alerts
      }),
      {...referrerActions}
    ),
    withRouter
  )(({
    // REDUX STORE
    configuration,
    user,
    dealership,
    token,
    tokenExp,
    alerts,
    // REDUX DISPATCH
    set: setRefferer,
    // REACT ROUTER
    location
  }) => {

    // MEMOS
    const whitelist = useMemo(
      () => {
        if (!configuration || !loggedIn(token, tokenExp)) return [PATHNAMES.login, PATHNAMES.resetPassword, makePath(PATHNAMES.resetPassword, ':token')];
        else if (configuration.two_step_verification && !user.verified) return [PATHNAMES.requestCode, PATHNAMES.enterCode];
        else if (!dealership) return [PATHNAMES.chooseDealership];
      },
      [configuration, user, dealership, token, tokenExp]
    )
    const redirect = useMemo(
      () => {
        if (!whitelist) return;
        const index = whitelist.findIndex(el => matchPath(location.pathname, { path: makePath(el), exact: true }) !== null);
        if (index < 0) return makePath(whitelist[0]);
      },
      [location.pathname, whitelist]
    )

    // EFFECTS
    useEffect(
      () => {
        if (!loggedIn(token, tokenExp) && !!redirect) setRefferer(location.pathname);
      },
      [token, tokenExp, redirect, setRefferer, location.pathname]
    )

    return !!redirect ? <Redirect to={{
      pathname: redirect,
      state: {alerts}
    }} /> : <Component />;
})
}
