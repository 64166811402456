import React, { forwardRef } from 'react';

// FUNCTIONS
import { controlName } from 'functions';

// MAIN COMPONENT
export const dateProps = Component => {
  return forwardRef(({
    name,
    startName = Array.isArray(name) ? name[0] : undefined,
    endName   = Array.isArray(name) ? name[1] : undefined,
    range     = startName && endName,
    ...props
  }, ref) => (
    <Component
      {...props}
      name={startName && endName ? controlName([startName, endName].join('_'), 'dummy') : name}
      startName={startName}
      endName={endName}
      range={range}
      ref={ref}
    />
  ));
}
