import React, { forwardRef, useEffect, useRef } from 'react';

// LODASH
import { cloneDeep, isEqual } from 'lodash';

// MAIN COMPONENT
export const formLogging = Component => forwardRef((props, ref) => {

  // PROPS
  const {
    debugOnly,
    debug = debugOnly,
    form: {
      errors,
      values
    } = {}
  } = props;

  const valuesRef = useRef();
  const errorsRef = useRef();

  // EFFECTS
  useEffect(
    () => {
      if (!debug) return;
      if (isEqual(values, valuesRef.current)) return;
      valuesRef.current = cloneDeep(values);
      console.log('VALUES:');
      console.log(values);
    },
    [debug, values, valuesRef]
  )
  useEffect(
    () => {
      if (!debug) return;
      if (isEqual(errors, errorsRef.current)) return;
      errorsRef.current = cloneDeep(errors);
      console.log('ERRORS:');
      console.log(errors);
    },
    [debug, errors, errorsRef]
  )

  // RENDER
  return <Component {...props} ref={ref} />;
})
