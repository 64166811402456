// LOCAL COMPONENTS
import AddButton from './types/AddButton';
import DownloadButton from './types/DownloadButton';
import FilterButton from './types/FilterButton';
import FilterHeaderButton from './types/FilterHeaderButton';
import HistoryButton from "./types/HistoryButton";

// MAIN COMPONENT
const ListButton = {
  Add:          AddButton,
  Download:     DownloadButton,
  Filter:       FilterButton,
  FilterMobile: FilterHeaderButton,
  History: HistoryButton
}

// EXPORT
export default ListButton
