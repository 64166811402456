// LOCAL COMPONENTS
import ActiveFilter from './types/ActiveFilter';
import DateRangeFilter from './types/DateRangeFilter';
import DealershipFilter from './types/DealershipFilter';
import SearchFilter from './types/SearchFilter';
import SelectFilter from './types/SelectFilter';
import ChecklistFilter from "./types/ChecklistFilter";

// MAIN OBJECT
const ListFilter = {
  // GENERAL
  Search:     SearchFilter,
  Select:     SelectFilter,
  DateRange:  DateRangeFilter,
  // SELECT FILTERS
  Active:     ActiveFilter,
  Dealership: DealershipFilter,
  Checklist: ChecklistFilter
}

// EXPORT
export default ListFilter
