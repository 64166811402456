import React from 'react';

// GLOBAL VARIABLES
import { PATHNAMES } from 'pathnames';

// GLOBAL FUNCTIONS
import { makePath } from 'functions';

// CORE COMPONENTS
import Form from 'core/form/Form';

// PARTS
import Record from 'parts/interface/record/Record';

// PROTOTYPE
import EditPrototype from '../prototype/EditPrototype';

// VARIABLES
const INITIAL_VALUES = {
  active_flag: true,
  applications: '',
  backup_phone: '',
  cl_header_image: '',
  craigslist_phone: '',
  dealer_address_line1: '',
  dealer_address_line2: '',
  dealer_city: '',
  dealer_id: '',
  dealer_name: '',
  dealer_state_id: '',
  dealer_zip_code: '',
  delete_flag: false,
  device_resolutions: '',
  google_map_api_key: '',
  joomla_menutype: '',
  main_phone: '',
  make_id: '',
  mobile_web_phone: '',
  parts_store_online: false,
  search_engine_phone: '',
  site_domain_name: '',
  site_name: '',
  site_official_domain_name: '',
  swf_download_path: '',
  swf_file_path: '',
  temp_site_domain_name: '',
  web_site_base_dir: ''
}

// MAIN COMPONENT
const WebsiteGeneraInfo = () => (
  <EditPrototype
    routeKey="websiteGeneralInfo"
    idKey="make_dealer_id"
    label="website"
    redirect={makePath(PATHNAMES.websiteGeneralInfo)}
    form={{
      initialValues: INITIAL_VALUES
    }}
  >
    <Form.Col>
      <Record.Card.Subtitle>General Info</Record.Card.Subtitle>
    </Form.Col>
    <Form.Control
      name="site_domain_name"
      label="Domain Name"
      required
    />
    <Form.Control
      name="site_official_domain_name"
      label="Display Domain Name As"
      required
    />
    <Form.Control
      name="site_name"
      label="Web Site Name"
      required
    />
    <Form.Control
      name="dealer_name"
      label="Organization Name"
      required
    />
    <Form.Select
      name="dealer_id"
      label="Dealership"
      lookup="dealerships"
      optionKeys={{label: 'dealer_name', value: 'dealer_id'}}
    />
    <Form.Select
      name="make_id"
      label="Vehicle Make"
      lookup="makes"
      optionKeys={{label: 'make_name', value: 'make_id'}}
    />
    <Record.Card.Divider />
    <Form.Col>
      <Record.Card.Subtitle>Dealership Address</Record.Card.Subtitle>
    </Form.Col>
    <Form.Control
        name="dealer_address_line1"
        label="Address Line 1"
        required
      />
      <Form.Control
        name="dealer_address_line2"
        label="Address Line 2"
      />
      <Form.Control
        name="dealer_city"
        label="City"
        required
      />
      <Form.Select
        name="dealer_state_id"
        label="State"
        lookup="usaStates"
        optionKeys={{ label: 'state_name', value: 'us_state_id'}}
        required
      />
      <Form.Control
        name="dealer_zip_code"
        label="Zip Code"
        schema="postalCode"
        required
      />
    <Record.Card.Divider />
    <Form.Col>
      <Record.Card.Subtitle>Phone Numbers</Record.Card.Subtitle>
    </Form.Col>
    <Form.Control
      name="main_phone"
      label="Main Phone"
      type="tel"
    />
    <Form.Control
      name="search_engine_phone"
      label="Search Engine Phone"
      type="tel"
    />
    <Form.Control
      name="craigslist_phone"
      label="Craigslist Phone"
      type="tel"
    />
      <Form.Control
      name="mobile_web_phone"
      label="Mobile Web Phone"
      type="tel"
    />
      <Form.Control
      name="backup_phone"
      label="Backup Phone"
      type="tel"
    />
    <Record.Card.Divider />
    <Form.Col>
      <Record.Card.Subtitle>SWF Path</Record.Card.Subtitle>
    </Form.Col>
    <Form.Control
      name="swf_file_path"
      label="SWF Base Directory Path"
      fullWidth
    />
    <Form.Control
      name="swf_download_path"
      label="SWF URL Path"
      fullWidth
    />
    <Record.Card.Divider />
    <Form.Col>
      <Record.Card.Subtitle>Dealer Track Configuration</Record.Card.Subtitle>
    </Form.Col>
    <Form.Control
      name="dealer_track_dealer_id"
      label="Dealer ID"
    />
    <Form.Control
      name="dealer_track_lender_id"
      label="Lender ID"
    />
    <Record.Card.Divider />
    <Form.Col>
      <Record.Card.Subtitle>Devices Supported</Record.Card.Subtitle>
    </Form.Col>
    <Form.Checklist
      name="device_resolutions"
      lookup="deviceResolutions"
      optionKeys={{label: 'device_description', value: 'device_resolution_id'}}
      list={{
        cols: {
          sm: 24,
          md: 12,
          lg: 6
        }
      }}
      fullWidth
      toggleAll
    />
    <Record.Card.Divider />
    <Form.Col>
      <Record.Card.Subtitle>Applications</Record.Card.Subtitle>
    </Form.Col>
    <Form.Checklist
      name="applications"
      lookup="applications"
      optionKeys={{label: 'application_name', value: 'application_id'}}
      list={{
        cols: {
          sm: 24,
          md: 12,
          lg: 6
        }
      }}
      fullWidth
      toggleAll
    />
    <Record.Card.Divider />
    <Form.Col>
      <Record.Card.Subtitle>Configuration</Record.Card.Subtitle>
    </Form.Col>
    <Form.Control
      name="google_map_api_key"
      label="Google Map API Key"
    />
    <Form.Control
      name="joomla_menutype"
      label="Joomla Menu"
    />
      <Form.Control
      name="cl_header_image"
      label="Craigslist Header Image Name"
    />
    <Form.Control
      name="web_site_base_dir"
      label="Web Site Base Directory Path"
      fullWdith
    />
    <Form.YesNo
      name="parts_store_online"
      label="Online Parts Store?"
      required
    />
    <Form.YesNo
      name="active_flag"
      label="Active?"
      required
    />
    <Form.YesNo
      name="delete_flag"
      label="Archive?"
      required
    />
   </EditPrototype>
)

export default WebsiteGeneraInfo;

