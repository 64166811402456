import React, { useEffect } from 'react';

// GLOBAL FUNCTIONS
import { setDocumentTitle } from 'functions.js';

// MAIN COMPONENT
export const documentTitle = Component => ({
  title,
  documentTitle = title,
  // REST
  ...props
}) => {

  // EFFECTS
  useEffect(
    () => {
      setDocumentTitle(documentTitle)
    },
    [documentTitle]
  )

  return  <Component title={title} {...props} />;
}
