import React from 'react';

// DEPENDENCIES
import clsx from 'clsx';

// BOOTSTRAP COMPONENTS
import { Card as BSCard } from 'react-bootstrap';

// LOCAL COMPONENTS
import CardBody from './parts/CardBody'
import CardFooter from './parts/CardFooter'
import CardForm from './parts/CardForm'
import CardHeader from './parts/CardHeader'
import CardTitle from './parts/CardTitle'

// STYLES
import styles from './card.module.scss';

// MAIN COMPONENT
const Card = ({
  children,
  className,
  title,
  header = {},
  body,
  footer
}) => (
  <BSCard
    className={clsx(
      styles.card,
      className
    )}
  >
    <CardHeader
      title={title}
      {...header}
    />
    <CardBody>
      {body}
    </CardBody>
    {children}
  </BSCard>
)

// CHILD COMPONENTS
Card.Header   = CardHeader;
Card.Body     = CardBody;
Card.Footer   = CardFooter;
Card.Title    = CardTitle;
Card.Subtitle = ({ children }) => <h4 className={styles.subtitle}>{children}</h4>;
Card.Divider  = () => <hr className={styles.divider} />;
Card.Form     = CardForm;

export default Card;
