import React, {useMemo} from "react";
import { useSelector } from "react-redux";

// GLOBAL VARIABLES
import { PATHNAMES } from "pathnames.js";
// GLOBAL FUNCTIONS
import { makePath,formatDateTime, formatDate, formatTime } from "functions";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

// PROTOTYPE
import EditPrototype from "../../edit/prototype/EditPrototype";

// PARTS
import Record from "parts/interface/record/Record";

const INITIAL_VALUES = {};

// MAIN COMPONENT
const LeadForms = ({
  form = {},
  card = {},
  // REACT ROUTER
  location,
  // REST
  ...props
}) => {

  const data = useSelector((state) => {
    const emailSentLog = state.record.emailSentLog;
    const leadData = state.record.leadData;
    const locationData = state.record.location;
    const tradeInVehicleInfo = state.record.tradeInVehicleInfo;
    const vehicleInfo = state.record.vehicleInfo;

    const data = {
     
      ...leadData,
      ...locationData,
      ...tradeInVehicleInfo,
      ...vehicleInfo,
    };

    data.emailSentLog = emailSentLog;

    return data;
  });

  const isServiceShedule = useMemo(() => {
   return ( data.form_type_key && data.form_type_key.toUpperCase() === "SCHSRV" );
  }, [data]);

  // RENDER
  return (
    <EditPrototype
      routeKey="leadForms"
      idKey="form_contact_id"
      label="Lead Forms"
      redirect={makePath(PATHNAMES.leadForms)}
      isEdit={false}
      isAdd={false}
      form={{
        initialValues: INITIAL_VALUES,
        saveButton: {
          className: 'd-none'
        }
      }}
    >
      <Container>
        <Row>
          <Col sm={3}>Date Time:</Col>
          <Col sm={4}>{formatDateTime(data.created_date)}</Col>
        </Row>
        <Row>
          <Col sm={3}>Category:</Col>
          <Col sm={4}>{data.category_name}</Col>
        </Row>
        <Row>
          <Col sm={3}>Form Type:</Col>
          <Col sm={4}>{data.form_type}</Col>
        </Row>

        <Record.Card.Divider />
        <Record.Card.Subtitle>Contact Information</Record.Card.Subtitle>
        <Row>
          <Col sm={3}>First Name:</Col>
          <Col sm={4}>{data.first_name}</Col>
        </Row>
        <Row>
          <Col sm={3}>Last Name:</Col>
          <Col sm={4}>{data.last_name}</Col>
        </Row>
        <Row>
          <Col sm={3}>Email Address:</Col>
          <Col>{data.email_address}</Col>
        </Row>
        <Row>
          <Col sm={3}>Phone:</Col>
          <Col sm={3}>{data.phone}</Col>
        </Row>
        <Record.Card.Divider />
        <Record.Card.Subtitle>Comments</Record.Card.Subtitle>
        <Row>
          <Col sm={12}>{data.comments}</Col>
        </Row>
        <Record.Card.Divider />
        <Record.Card.Subtitle>Vehicle Information</Record.Card.Subtitle>
        <Row>
          <Col sm={3}>Stock #:</Col>
          <Col sm={4}>{data.stock_num}</Col>
        </Row>
        <Row>
          <Col sm={3}>VIN #:</Col>
          <Col sm={4}>{data.vin_number}</Col>
        </Row>
        <Row>
          <Col sm={3}>New/Used::</Col>
          <Col sm={3}>{data.new_used?'New':'Used'}</Col>
        </Row>
        <Row>
          <Col sm={3}>Year:</Col>
          <Col sm={3}>{data.year}</Col>
        </Row>
        <Row>
          <Col sm={3}>Make:</Col>
          <Col sm={3}>{data.make}</Col>
        </Row>
        <Row>
          <Col sm={3}>Model:</Col>
          <Col>{data.model}</Col>
        </Row>
        {isServiceShedule && (
          <>
            <Row>
              <Col sm={3}>Mileage:</Col>
              <Col>{data.mileage}</Col>
            </Row>
            <Row>
              <Col sm={3}>Vehicle Serial #:</Col>
              <Col>{data.mileage}</Col>
            </Row>
            <Row>
              <Col sm={3}>License Plate #:</Col>
              <Col>{data.auto_plate_number}</Col>
            </Row>
          </>
        )}
       {!isServiceShedule && (
          <>
            <Row>
              <Col sm={3}>Trim:</Col>
              <Col sm={3}>{data.trim}</Col>
            </Row>
            <Row>
              <Col sm={3}>Interior Color:</Col>
              <Col sm={3}>{data.interior_color}</Col>
            </Row>
            <Row>
              <Col sm={3}>Exterior Color:</Col>
              <Col sm={3}>{data.exterior_color}</Col>
            </Row>
          </>
       )}
        
        {isServiceShedule && (
          <>
          <Record.Card.Divider />
          <Record.Card.Subtitle>Drop-Off Date/Time</Record.Card.Subtitle>
          <Row>
            <Col sm={3}>Date</Col>
            <Col sm={3}>{formatDate(data.drop_off_datetime)}</Col>
          </Row>
          <Row>
            <Col sm={3}>Time:</Col>
            <Col>{formatTime( new Date(data.drop_off_datetime)) }</Col>
          </Row>
          <Record.Card.Divider />
          <Record.Card.Subtitle>Other Service</Record.Card.Subtitle>
          <Row>
            <Col sm={12}>{data.other_service}</Col>
          </Row>
          </>
        )}
    
        <Record.Card.Divider />
        <Record.Card.Subtitle>Technical Detail/GeoLocation</Record.Card.Subtitle>
        <Row>
          <Col sm={3}>IP Address:</Col>
          <Col sm={3}>{data.ip_address}</Col>
        </Row>
        <Row>
          <Col sm={3}>Referrer:</Col>
          <Col>{data.referer_url}</Col>
        </Row>
        <Row>
          <Col sm={3}>Country:</Col>
          <Col>{data.country_name}</Col>
        </Row>
        <Row>
          <Col sm={3}>Location:</Col>
          <Col sm={3}>{data.city} {data.region}</Col>
        </Row>
        <Row>
          <Col sm={3}>ZIP Code:</Col>
          <Col sm={3}>{data.zip_code}</Col>
        </Row>
        <Row>
          <Col sm={3}>Latitude:</Col>
          <Col sm={3}>{data.latitude}</Col>
        </Row>
        <Row>
          <Col sm={3}>Longitude:</Col>
          <Col sm={3}>{data.longitude}</Col>
        </Row>

        <Record.Card.Divider />
        <Record.Card.Subtitle>Email log</Record.Card.Subtitle>

        <Container>
          <Row>
            <Col sm={6}><span className="font-weight-bold">Sent Email Addresses</span></Col>
            <Col sm={6}><span className="font-weight-bold">Sent Date/Time</span></Col>
            <Col auto><span className="font-weight-bold">Sent By</span></Col>
          </Row>
          {data.emailSentLog && data.emailSentLog.map( (item)=>{
              return (
                <Row>
                  <Col sm={6}>{item.sent_email_address}</Col>
                  <Col sm={6}>{formatDateTime(item.sent_email_datetime)}</Col>
                  <Col auto>{item.sent_by}</Col>
                </Row>
              )
            })    
          }
          
        </Container>

      </Container>
    </EditPrototype>
  );
};

export default LeadForms;
