import React from 'react';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';

// PARTS
import List from 'parts/interface/list/List';
const { Action, Button, Col, Tool } = List;

// ARGS
const ARGS = {
  label:    'organization-title',
  idKey:    'org_title_id',
  endpoint: ENDPOINTS.record.organizationTitle.list
}

// HEADER
const BUTTONS = [ <Button.Add /> ]

// TOOLBAR
const TOOLS = [ <Tool.ResultCount /> ]

// COLUMNS
const COLUMNS = ({
  org_title,
  char_key
}) => [
  <Col
    cols={{ lg: 6 }}
    label="Organization Title"
    value={ org_title }
  />,
  <Col
    cols={{ lg: 6 }}
    label="Unique Key"
    value={ char_key }
  />
]

// ACTIONS
const ACTIONS = () => [ <Action.Edit /> ]

// MAIN COMPONENT
const OrganizationTitles = () => <List
  title="Organization Titles"
  args={ARGS}
  buttons={BUTTONS}
  tools={TOOLS}
  columns={COLUMNS}
  actions={ACTIONS}
/>

export default OrganizationTitles;
