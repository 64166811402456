import React, { cloneElement } from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { connect } from 'react-redux';
import { compose } from 'redux';

// HELPERS/CONTEXT
import { documentTitle } from 'helpers/documentTitle';

// STYLES
import styles from './pageHeader.module.scss';

// MAIN COMPONENT
const PageHeader = compose(
  connect(
    ({ mobile }) => ({ mobile })
  ),
  documentTitle
)(({
  title,
  tools = [],
  // REDUX STATE
  mobile,
}) => !title && tools.length < 1 ? null : (
  <div className={clsx(
    'page-header',
    styles.header
  )}>
    <h2 className={styles.title}>{title}</h2>
    {!mobile && !!tools.length &&
      <div className={styles.tools}>
        {tools.map((tool, i) => cloneElement(tool, { key: i }))}
      </div>
    }
  </div>
))

// EXPORT
export default PageHeader;
