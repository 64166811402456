import React, { useContext } from 'react';

// HELPERS
import { ListContext } from '../../../helpers/getListData.js';

// CORE COMPONENTS
import Form from 'core/form/Form';

// MAIN COMPONENT
const RecordsInfo = () => {
    // CONTEXT
    const {
        records
    } = useContext(ListContext) || {};

    // RENDER
    return (
        <Form.Col className="ml-auto">
            <div>
               Number of records found: <span className="text-danger font-weight-bold">{records?.total || 0}</span> | Unique contact leads: <span className="text-danger font-weight-bold">{records?.uniqueRecords || 0}</span>
            </div>
        </Form.Col>
    )
}

// EXPORT
export default RecordsInfo;
