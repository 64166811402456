import React, { useMemo } from 'react';

// LOCAL COMPONENTS
import Badge from '../prototype/Badge';

// MAIN COMPONENT
const BooleanBadge = ({
  children,
  options = [],
  value = children,
  ...props
}) => {

  // MEMOS
  const optionsMemo = useMemo(
    () => [
      {
        variant: 'secondary',
        label:   'False',
        ...(options[0] || {})
      },
      {
        variant: 'success',
        label:   'True',
        ...(options[1] || {})
      }
    ],
    [options]
  )
  const { variant, label, code } = useMemo(
    () => {
      switch(String(value)) {
        case 'true':
          return optionsMemo[1];
        case 'false':
          return optionsMemo[0];
        default:
          return {};
      }
    },
    [optionsMemo, value]
  )

  // RENDER
  return (
    <Badge
      variant={variant}
      label={code || label}
      tip={!!code ? label : undefined}
      {...props}
    />
  )
}

// EXPORT
export default BooleanBadge;
