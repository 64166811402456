import React, { useCallback } from 'react';

// DEPENDENCIES
import { useFormikContext } from 'formik';
import * as yup from 'yup';

// GLOBAL FUNCTIONS
import { checkDate, controlName, doCallback } from 'functions.js';

// LOCAL COMPONENTS
import Control from '../Control';
import Col from '../../parts/Col';
import Row from '../../parts/Row';

// FUNCTIONS
const ccExpirationValidation = value => {
  const month = value.substring(0, 2);
  const year = value.substring(2);
  if (!checkDate(`${month}/1/${year}`, '>', new Date()))
    return false;
  return true;
}

// VARIABLES
const CC_PATTERNS = [
  '4[0-9]{12,18}',              // Visa (4) 13-19 digits
  '3[4,7][0-9]{13}',            // American Express (34, 37) 15 digits
  '62[0-9]{14,17}',             // China UnionPay (62) 16-19 didgits
  '5[1-5][0-9]{14}',            // MasterCard (51-55) 16 digits
  '222[1-9][0-9]{12}',          // MasterCard (222100-222999) 16 digits
  '22[3-9][0-9]{13}',           // MasterCard (223000-229999) 16 digits
  '2[3-6][0-9]{14}',            // MasterCard (230000-269999) 16 digits
  '27[0-1][0-9]{13}',           // MasterCard (270000-271999) 16 digits
  '2720[0-9]{6,12}',            // MasterCard (272000-272099) 16 digits
  '50[0-9]{10,17}',             // Maestro (500000-509999) 12-19 digits
  '5[6-8][0-9]{10,17}',         // Maestro (560000-589999) 12-19 digits
  '6[0-9]{11,18}',              // Maestro (600000-699999) 12-19 digits
  '30[0-5][0-9]{11}',           // Diners Club Carte Blanche (300-305) 14 digits
  '30[0-5,9][0-9]{11}',         // Diners Club International (300-305, 309) 14 digits
  '3[6,8-9][0-9]{12}',          // Diners Club International (36, 38-39) 14 digits
  '5[4-5][0-9]{14}',            // Diners Club International (54-55) 16 digits
  '65[0-9]{14}',                // Discover (65) 16 digits (65) 16 digits
  '64[4-9][0-9]{13}',           // Discover (644-649) 16 digits
  '6011[0-9]{12}',              // Discover (6011) 16 digits
  '62212[6-9][0-9]{10}',        // Discover (622126-622129) 16 digits
  '6221[3-9][0-9]{11}',         // Discover (622130-622199) 16 digits
  '622[2-8][0-9]{12}',          // Discover (622200-622899) 16 digits
  '6229[0-1][0-9]{11}',         // Discover (622900-622919) 16 digits
  '62292[0-5][0-9]{10}',        // Discover (622920-622925) 16 digits
  '352[8-9][0-9]{12}',          // JCB (3528-3529) 16 digits
  '35[3-8][0-9]{13}',           // JCB (3530-3589) 16 digits
  '1[0-9]{14}',                 // UATP (1) 15 digits
  '5019[0-9]{12}',              // Dankort (5019) 16 digits
  '636[0-9]{13,16}',            // InterPayment (636) 16-19 digits
]
const VALIDATION_SCHEMA = {
  number: yup.string().transform(value => value.replace(/\D/g,'')).matches(`^${CC_PATTERNS.join('$|^')}$`, 'Not a valid credit card number.'),
  expiration: yup.string().test('cc-expiration', 'Not a valid expiration.', ccExpirationValidation),
  ccv: yup.string().matches(/^[0-9]{3,4}$/, 'Not a valid CVV Code.')
}

// MAIN COMPONENT
const CreditCard = ({
  number: {
    name,
    onChange,
    ...number
  } = {},
  expiration = {},
  ccv = {},
  ...props
}) => {

  // FORMIK
  const { setFieldValue } = useFormikContext() || {};

  // CALLBACKS
  const handleChange = useCallback(
    e => {
      doCallback(onChange, e);
      const value = e.target.value.replace(/\D/g,'');
      setFieldValue(name, value);
    },
    [name, onChange, setFieldValue]
  )

  // RENDER
  return (
    <Row>
      <Col xs={24} md={16}>
        <Control
          {...number}
          {...props}
          name={controlName(name, 'dummy')}
          placeholder="#### #### #### ####"
          onChange={handleChange}
          validationSchema={VALIDATION_SCHEMA.number}
        />
      </Col>
      <Col xs={12} md={4}>
        <Control
          {...expiration}
          {...props}
          numberFormat={{
            format: '##/##',
            placeholder: '##/##',
            mask: '#'
          }}
          validationSchema={VALIDATION_SCHEMA.expiration}
        />
      </Col>
      <Col xs={12} md={4}>
        <Control
          {...ccv}
          {...props}
          numberFormat={{
            format: '####',
            placeholder: '####'
          }}
          validationSchema={VALIDATION_SCHEMA.ccv}
        />
      </Col>
    </Row>
  )
}

// EXPORT
export default CreditCard;
