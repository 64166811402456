import React from 'react';

// DEPENDENCIES
import { connect } from 'react-redux';
import { compose } from 'redux';

// GLOBAL FUNCTIONS
import { toggleMenu } from 'actions.js';

// LOCAL COMPONENTS
import HeaderButton from '../parts/HeaderButton';

// STYLES
import styles from './menuToggle.module.scss';

// MAIN COMPONENT
const MenuToggle = compose(
  connect(
    null,
    { toggleMenu }
  )
)(({
  // REDUX ACTION
  toggleMenu
}) => (
  <HeaderButton
    className={styles.toggle}
    icon="bars"
    onClick={() => toggleMenu()}
    size="lg"
    square
  />
))

// EXPORT
export default MenuToggle
