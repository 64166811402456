import FontAwesome from 'tools/FontAwesome';


// FUNCTIONS

export const FETCH = {
  blacklist: ['undefined', 'dummy', 'confirmation'],
  useCSV:    false
}

export const LOGOUT = {
  useFetch: true
}


// FORMS

export const APPEND = {
  clearButton: {
    use: 'times'
  },
  lockButton: {
    use: {
      locked: 'lock',
      unlocked: 'unlock-alt'
    }
  }
}

export const FORM_CONTROL = {
  clearDisabled: true
}

export const FORM_GROUP = {
  cols: {
    inline: {
      xs: 'auto'
    },
    columns: {
      xs: 24,
      md: 12,
      xl: 8
    },
    default: {
      xs: 24
    }
  }
}

export const LABEL = {
  cols: {
    above: {
      xs: 24
    },
    before: {
      xs: 24,
      md: 8
    },
    inline: {
      xs: 'auto'
    },
    columns: {
      xs: 24,
      xl: 8
    }
  }
}

export const PREPEND = {
}

export const RICH_TEXT = {
  height: 600,
  plugins: 'lists link code',
  toolbar: 'undo redo | bold italic underline | bullist numlist | link code',
}

export const UPLOAD = {
  accept:        'application/pdf',
  maxSize:       10485760,
  multiple:      false,
  useRecordID:   true,
  variant:       'primary',
  uploadLabel:   'Upload',
  uploadIcon:    'upload',
  deleteLabel:   'Remove',
  deleteIcon:    'trash-alt',
  buttonSize:    'md',
  fullWidth:     false
}


// NUMBERS

export const NUMBER = {
  usePlaceholder: false
}

export const DATE = {
  allowCustom:      true,
  useOptions:       true,
  usePrecedingZero: true,
  yearLength:       2,
  fromPrepend:      'From:',
  toPrepend:        'To:'
}

export const DOLLARS = {
  useCents:         false,
  centsPlaceholder: '$ X.XX',
  decimalScale:     2,
  allowNegative:    false
}


// COMPONENTS

export const ALERT = {
  variant: 'primary'
}

export const BUTTON = {
  variant:     'primary',
  spriteOrder: 2,
  spriteSize:  'md',
  round:       false,
  // TYPES
  cancel: {
    variant: 'secondary',
    label:   'Cancel',
    icon:    'times',
    outline: true
  },
  add: {
    label:   'Add',
    icon:    'plus-circle'
  },
  submit: {
    label:   'Submit',
    icon:    'arrow-right'
  },
  save: {
    variant: 'success',
    label:   'Save',
    icon:    'save'
  },
  delete: {
    variant: 'danger',
    label:   'Delete',
    icon:    'trash-alt'
  }
}

export const CALENDAR = {
  showOutsideMonth: false
}

export const CHECKLIST = {
  useCSV: true
}

export const DROPDOWN = {
  align:    'end',
  useArrow: false
}

export const LINK = {
  spriteOrder: 1
}

export const MODAL = {
  cancelButton: {
    icon: {
      use: 'times',
      order: 1
    },
    justify: 'between'
  },
  continueButton: {}
}

export const PLACEHOLDERS = {
  customSelect: 'Type or Select',
  input:        'Type Here',
  option:       'Option',
  select:       'Select',
  state:        'Select State',
  textarea:     'Type Here'
}

export const SPRITE = {
  as: FontAwesome,
  size: 'sm'
}

export const TOGGLE = {
  useGroup: true,
  active: {
    variant: 'primary'
  },
  inactive: {
    variant: 'secondary',
    outline: true
  }
}

export const YES_NO = {
  useNumeric: false,
  useToggle: false,
  group: false
}
