import React, { forwardRef } from 'react';

// LOCAL COMPONENTS
import HeaderDropdown from '../parts/HeaderDropdown';

// MAIN COMPONENT
const SessionDropdown = ({
  component: Component,
  ...props
}) => (
  <Component.Wrapper>
    <HeaderDropdown
      toggle={{
        as: forwardRef((props, ref) => (
          <Component.Toggle
            as={HeaderDropdown.Toggle}
            toggleRef={ref}
            {...props}
          />
        ))
      }}
      {...props}
    >
      <Component.List
        as={HeaderDropdown.Item}
      />
    </HeaderDropdown>
  </Component.Wrapper>
)

export default SessionDropdown
