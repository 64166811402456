import React, { forwardRef, useEffect } from 'react';

// GLOBAL FUNCTIONS
import { doCallback } from 'functions.js';
import { isEmpty } from 'lodash';

// MAIN COMPONENT
export const defaultOption = Component => {
  return forwardRef(({
    useDefault,
    ...props
  }, ref) => {

    // PROPS
    const {
      options,
      selection,
      onSelect
    } = props;

    // MEMOS
    useEffect(
      () => {
        if (useDefault && !selection) {
          const defaultOption = Array.isArray(options) && useDefault ? options[0] : undefined;
          if (isEmpty(defaultOption)) return;
          // This is a hacky method of waiting to call setFieldValue after Formik mounts
          setTimeout(() => doCallback(onSelect, defaultOption.value), 1)
        }
      },
      [options, selection, onSelect, useDefault]
    )

    // RENDER
    return <Component {...props} ref={ref} />
  })
}
