import React from 'react';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';
import { PATHNAMES } from 'pathnames.js';

// CORE COMPONENTS
import Form from 'core/form/Form';

// PARTS
import Record from 'parts/interface/record/Record';
import useRouteCheck from 'parts/interface/helpers/routeCheck';

const DEFAULT_DATA = {
  service_transportation_options_id: '',
  transportation_option: ''
}

// MAIN COMPONENT
const ServiceTransportation = ({
  form = {},
  card = {},
  // REACT ROUTER
  location,
  // REST
  ...props
}) => {
  const isEdit = useRouteCheck('edit');
  
  // RENDER
  return (
    <Record
      label="Service Transportation"
      idKey="service_transportation_options_id"
      documentTitle="Service Transportation"
      endpoint={ENDPOINTS.record.serviceTransportation.get}
      {...props}
    >
      <Record.Form
        initialValues={DEFAULT_DATA}
        endpoint={isEdit
          ? ENDPOINTS.record.serviceTransportation.put
          : ENDPOINTS.record.serviceTransportation.add
        }
        redirect={PATHNAMES.serviceTransportation}
        {...form}
      >
        <Record.Card.Edit>
          <Form.Body>
            <Form.Control
              name="transportation_option"
              label="Service Transportation"
              required
            />
          </Form.Body>
        </Record.Card.Edit>
      </Record.Form>
    </Record>
  )
}

export default ServiceTransportation;

