import React, { useMemo } from 'react';

// DEPENDENCIES
import { isObject, lowerCase, snakeCase, startCase } from 'lodash';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';

// GLOBAL FUNCTIONS
import { makePath } from 'functions';

// PARTS
import List from 'parts/interface/list/List';
const { Action, Button, Tool } = List;

// ACTIONS
const ACTIONS = () => [ <Action.Edit /> ]

// MAIN COMPONENT
/**
 * @param {String} routeKey - unique key used to determine subordinate props, endpoints, and pathnames
 * @param {String/Object} [endpoint] - basepath URI or collection of URI's for the API endpoint
 * @param {String} [idKey] - name of the record object's unique id param 
 * @param {String} [label] - user-friendly label (lowercase) for record type
 * @param {String} [plural] - user-friendly label (lowercase for multiple records
 * @param {String} [title] - user-friendly title (startcase) for display at the top of the page and browser tab
 * @param {Array} columns - collection of List.Col components supplied to the List.Headers and List.Row components
 * @param props {Object}
 * @returns {JSX.Element}
 * @constructor
 */
const ListPrototype = ({
  routeKey,
  idKey    = `${snakeCase(routeKey)}_id`,
  label    = lowerCase(routeKey),
  plural   = `${label}s`,
  title    = `${startCase(plural)}`,
  endpoint = ENDPOINTS.record[routeKey],
  init,
  tools = [],
  buttons = [<Button.Add />],
  columns,
  usePagination,
  ...props
}) => {

  // MEMOS
  const args = useMemo(
    () => ({
      label,
      plural,
      idKey,
      endpoint: isObject(endpoint) ? endpoint.list : makePath(endpoint, 'list'),
      init
    }),
    [label, plural, idKey, endpoint, init]
  )
  const toolsMemo = useMemo(
    () => usePagination ? [ <Tool.ResultCount />, ...tools ] : tools,
    [tools, usePagination]
  )

  // RENDER
  return (
    <List
      routeKey={routeKey}
      args={args}
      title={title}
      buttons={buttons}
      tools={toolsMemo}
      columns={columns}
      actions={ACTIONS}
      usePagination={usePagination}
      {...props}
    />
  )
}
export default ListPrototype;
