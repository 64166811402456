import React, { forwardRef, useMemo } from 'react';

// DEPENDENCIES
import * as yup from 'yup';

// GLOBAL FUNCTIONS
import { safeDate, safeTime, format } from 'functions.js';

// MAIN COMPONENT
export const timeValidation = Component => {
  return forwardRef((props, ref) => {

    // PROPS
    const {
      currentDate,
      minDate,
      maxDate,
      minTime,
      maxTime
    } = props;

    // MEMO
    const safeMinTime = useMemo(
      () => minTime ? safeTime(minTime, currentDate) : (minDate || '').split(' ').length > 1 ? safeDate(minDate) : undefined,
      [currentDate, minDate, minTime]
    )
    const safeMaxTime = useMemo(
      () => maxTime ? safeTime(maxTime, currentDate) : (maxDate || '').split(' ').length > 1 ? safeDate(maxDate) : undefined,
      [currentDate, maxDate, maxTime]
    )
    const validationSchema = useMemo(
      () => {
        let validationSchema = yup.date().nullable().transform((curr, orig) => orig === '' ? null : safeTime(orig, !currentDate ? undefined : currentDate)).typeError('Invalid time.');
        if (safeMinTime && currentDate) validationSchema = validationSchema.min(safeMinTime, `Must be no earlier than ${format.time(safeMinTime)}`);
        if (safeMaxTime && currentDate) validationSchema = validationSchema.max(safeMaxTime, `Must be no later than ${format.time(safeMaxTime)}`);
        return validationSchema;
      },
      [currentDate, safeMinTime, safeMaxTime]
    )

    // RENDER
    return <Component
      {...props}
      validationSchema={validationSchema}
      ref={ref}
    />
  })
}
