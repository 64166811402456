import React from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { isString } from 'lodash';

// GLOBAL VARIABLES
import { MODAL } from 'defaults.js';

// CORE COMPONENTS
import Button from 'core/tools/Button';

// LOCAL COMPONENTS
import DialogModal from './DialogModal';

// STYLES
import styles from './confirmationModal.module.scss';

// MAIN COMPONENT
const ConfirmationModal = ({
  className,
  cancelButton = {},
  continueButton = {},
  ...props
}) => (
  <DialogModal
    className={clsx(
      styles.modal,
      className
    )}
    {...props}
    buttons={[
      cancelButton === false ? null : (
        <Button.Cancel
          {...MODAL.cancelButton}
          {...cancelButton}
          icon={Object.assign({}, MODAL.cancelButton.icon, isString(cancelButton.icon) ? { use: cancelButton.icon } : cancelButton.icon)}
        />
      ),
      continueButton === false ? null : (
        <Button
          label='Continue'
          {...MODAL.continueButton}
          {...continueButton}
          col={cancelButton !== false ? undefined : {
            xs: 24,
            className: styles.col
          }}
          icon={Object.assign({}, MODAL.continueButton.icon, isString(continueButton.icon) ? { use: continueButton.icon } : continueButton.icon)}
        />
      )
    ].filter(button => !!button)}
  />
)

// EXPORT
export default ConfirmationModal;
