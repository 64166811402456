import React, { useContext } from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { compose } from 'redux';

// HELPERS
import { mountLog } from 'helpers/mountLog';
import { uploadContext } from './helpers/uploadContext';
import { uploadFormik } from './helpers/uploadFormik';
import { formikField } from '../../helpers/formik/formikField';
import { inputState } from '../../helpers/state/inputState';
import { inputValidation } from '../../helpers/state/inputValidation';
import { formGroup } from '../../helpers/layout/formGroup';
import { inputFeedback } from '../../helpers/layout/inputFeedback';
import { inputGroup } from '../../helpers/layout/inputGroup';
import { inputLabel } from '../../helpers/layout/inputLabel';
import { inputText } from '../../helpers/layout/inputText';
import { useHidden } from '../../helpers/layout/useHidden';

// CONTEXT
import { UploadContext } from './helpers/uploadContext.js';

// LOCAL COMPONENTS
import DeleteButton from './parts/DeleteButton';
import UploadButton from './parts/UploadButton';
import UploadItem, { Info, Label, Tools } from './parts/UploadItem';

// STYLES
import styles from './upload.module.scss';

// MAIN COMPONENT
const Upload = compose(
  mountLog,
  inputValidation,
  formikField,
  inputState,
  useHidden,
  formGroup,
  inputLabel,
  inputFeedback,
  inputText,
  inputGroup,
  uploadContext,
  uploadFormik
)(({
  className,
  uploadButton = {},
  uploadItem = {},
  ...props
}) => {

  // CONTEXT
  const { uploads = [] } = useContext(UploadContext) || {};

  // RENDER
  return (
    <div className={clsx(
      styles.container,
      className
    )}>
      {!!uploads.length &&
        <div className={styles.uploads}>
          {uploads.map((upload, i) => (
            <UploadItem
              key={i}
              upload={upload}
              {...props}
              {...uploadItem}
            />
          ))}
        </div>
        }
      <UploadButton
        button={uploadButton}
        {...props}
      />
    </div>
  )
})

// CHILD COMPONENTS
Upload.Item = UploadItem;
Upload.Info = Info;
Upload.Label = Label;
Upload.Tools = Tools;
Upload.Delete = DeleteButton;

// EXPORT
export default Upload