import React, { useCallback, useEffect, useState } from 'react';

// DEPENDENCIES
import { Alert } from "react-bootstrap";
import { connect } from "react-redux";

// PROTOTYPE
import EditPrototype from "../prototype/EditPrototype";

// PARTS
import Record from "parts/interface/record/Record";
import Sidebar from "tools/sidebar/Sidebar";

// CORE
import Form from "core/form/Form";
import Button from "core/tools/Button";

/// FUNCTIONS
import {apiFetch, makePath} from "functions";

//HELPERS
import useRouteCheck from "parts/interface/helpers/routeCheck";

// GLOBAL VARIABLES
import { ENDPOINTS } from "endpoints";

// ACTIONS
import { sidebarActions } from "actions.js";

// DEFAULT DATA
const DEFAULT_DATA = {
  first_name: "",
  last_name: "",
  cell_phone_number: "",
  email_address: "",
  emp_num: "",
  username: "",
  password: "",
  password_confirm: "",
  active_flag: true,
  org_title_id: "",
  sales_dept_type_id: "",
  site_role_id: "",
  make_dealer_id: ""
}

const submitForm = (data, args) => {
  const params = { ...data };
  const appRoles = [];
  
  for (const key in data) {
    if (key.includes("site_role_id") && !!data[key]) {
      appRoles.push(data[key]);
      delete params[key];
    }
  }
  
  params.site_role_id = appRoles.join(',');
  
  apiFetch({ ...args, params });
};

// MAIN COMPONENT
const Users =
    connect(
        null,
    {...sidebarActions}
)(({
        match: {
          params: {
            recordID
          }
        },
        // REDUX DISPATCH
        toggle
}) => {
  const [applicationRoles, setApplicationRoles] = useState([]);
  const isEdit = useRouteCheck('edit');
  
  // Extracts the dealership specific roles
  const getDxRoles = useCallback(() => {
    apiFetch({
      method: 'GET',
      endpoint: ENDPOINTS.lookup.dxRoles,
      onSuccess: data => setApplicationRoles(
        data.map(({application_name: label, site_roles}, index) => ({
          label,
          name: `site_role_id-${index}`,
          options: site_roles.map(({role_name: label, site_role_id: value})=>({label, value}))
        }))
      )
    })
  }, []);
  
  // Initialize form data
  const initRecord = useCallback(({site_role_id, password, ...data}) => {
    const newData = { ...data };
    const roleIDs = site_role_id.split(',').map(e => +e) || [];
    
    applicationRoles.forEach(({ name, options }) => {
      for (let i = 0; i < options.length; i += 1) {
        const roleID = roleIDs.find(id => id === options[i].value);
        if (!!roleID) {
          newData[name] = roleID;
          break;
        }
      }
    });
    
    return newData;
  }, [applicationRoles]);
  
  useEffect(() => {
    getDxRoles();
    
    return () => setApplicationRoles([]);
  }, [getDxRoles])
  
  return (
      <>
        <EditPrototype
            routeKey="user"
            idKey="site_user_id"
            form={{
              initialValues: DEFAULT_DATA,
              initializeRecord: initRecord,
              showAddButton: false,
              onSubmit: submitForm
            }}
        >
          <Form.Col>
            <Record.Card.Subtitle>Personal Information</Record.Card.Subtitle>
          </Form.Col>
          <Form.Control
              name="first_name"
              label="First Name"
              required
          />
          <Form.Control
              name="last_name"
              label="Last Name"
              required
          />
          <Form.Control
              name="cell_phone_number"
              label="Cell Phone"
              schema="tel"
          />
          <Form.Control
              name="email_address"
              label="Email"
              type="email"
              required
              confirmed={!isEdit}
              confirmationProps={{name: 'conf_email_address'}}
          />
          <Record.Card.Divider/>

          <Form.Col>
            <Record.Card.Subtitle>Employee Information</Record.Card.Subtitle>
          </Form.Col>
          <Form.Control
              name="emp_num"
              label="Employee Number"
              schema="alphanumeric"
              required
          />
          <Record.Card.Divider/>

          <Form.Col>
            <Record.Card.Subtitle>Login Information</Record.Card.Subtitle>
          </Form.Col>
          <Form.Control
              name="username"
              label="Username"
              readOnly={isEdit}
              required={!isEdit}
          />
          {!isEdit ? <Form.Control
                  name="password"
                  label="Password"
                  type="password"
                  required={!isEdit}
                  confirmed
                  confirmationProps={{name: 'password_confirm'}}
              />
              :
              <>
                    <Form.Label className="align-items-center">Password</Form.Label>
                    <Form.Col>
                      <Form.Text>
                        <Button.Link label="Change Password" onClick={() => toggle('password')} className="w-full" />
                      </Form.Text>
                    </Form.Col>
              </>
          }

          <Record.Card.Divider/>

          <Form.Col>
            <Record.Card.Subtitle>Site Access Configuration</Record.Card.Subtitle>
          </Form.Col>
          <Form.YesNo
              name="active_flag"
              label="Active"
          />
          {isEdit && <Form.Control
              name="created_date"
              label="Account Created"
              readOnly
          />}
          {isEdit && <Form.Control
              name="last_login_date"
              label="Last Login Date"
              readOnly
          />}
          <Record.Card.Divider/>

          <Form.Col>
            <Record.Card.Subtitle>Daily Sales Log (DSL) Title and Department</Record.Card.Subtitle>
            <Alert variant="info">Note: If this user’s name should be listed in the Daily Sales Log, please select the
              proper title and sales department type below. In order to remove this person’s name from the Daily Sales
              Log listing, simply leave these fields blank or revert back to "None".</Alert>
          </Form.Col>
          <Form.Select
              useNone
              name="org_title_id"
              label="Job Title"
              lookup="orgTitles"
              optionKeys={{label: 'org_title', value: 'org_title_id'}}
          />
          <Form.Select
              useNone
              name="sales_dept_type_id"
              label="Sales Department Type"
              lookup="salesDepartmentTypes"
              optionKeys={{label: 'dept_type_desc', value: 'sales_dept_type_id'}}
          />
          <Record.Card.Divider/>

          <Form.Col>
            <Record.Card.Subtitle>Application Role Configuration</Record.Card.Subtitle>
          </Form.Col>
          {applicationRoles.map(({label, options, name}) =>
              <Form.Select
                  useNone
                  name={name}
                  key={name}
                  label={label}
                  options={options}
              />
          )}
          <Record.Card.Divider/>

          <Form.Col>
            <Record.Card.Subtitle>Dealerships</Record.Card.Subtitle>
          </Form.Col>
          <Form.Checklist
              name="make_dealer_id"
              lookup="dealershipSites"
              optionKeys={{label: 'dealer_name', value: 'make_dealer_id'}}
              list={{cols: {sm: 24, md: 12, lg: 6}}}
              fullWidth
          />
        </EditPrototype>
        {isEdit &&
            <Sidebar
                name="password"
                title="Change Password"
            >
              <Sidebar.Form
                  endpoint={makePath(ENDPOINTS.user.editPassword, recordID)}
                  method='PUT'
                  loadingMessage="Submitting new password..."
                  successMessage="Password has been changed."
              >
                <Form.Control
                    name="new_password"
                    label="Password"
                    type="password"
                    confirmed
                    required
                    text="Leave blank if no changes are made to the current password"
                />
              </Sidebar.Form>
            </Sidebar>
        }
      </>
  )
})

export default Users;
