import React, { forwardRef } from 'react';

// DEPENDENCIES
import { compose } from 'redux';

// HELPERS
import { googleMapsAutocomplete } from '../helpers/state/googleMapsAutocomplete';

// LOCAL COMPONENTS
import Control from '../Control';

const Address = compose(
  googleMapsAutocomplete,
  forwardRef
)((props, ref) => (
  <Control
    {...props}
    ref={ref}
  />
))

export default Address;
