import React, { cloneElement, useContext } from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { compose } from 'redux';

// CONTEXT
import { ListContext } from 'parts/interface/helpers/getListData.js';

// HELPERS
import { checkPermission } from 'parts/interface/helpers/checkPermission';

// BOOTSTRAP COMOPNENTS
import { Col, Row } from 'react-bootstrap';

// STYLES
import styles from './listRow.module.scss';

// MAIN COMPONENT
const ListRow = compose(
  checkPermission
)(({
  record,
  recordID,
  showID = true,
  ...props
}) => {

  // CONTEXT
  const { columns, actions } = useContext(ListContext) || props;

  // RENDER
  return !columns || !record ? null : (
    <div className={styles.row}>
      <div className={styles.inner}>
        <Row className={styles.innerRow}>
          {recordID !== undefined && !!showID &&
            <Col className={clsx(
              styles.col,
              styles.number
            )}>
              {recordID}
            </Col>
          }
          <Col className={styles.cols}>
            <Row className={styles.innerRow}>
              {columns(record).map((column, i) => (
                cloneElement(column, {
                  key: i
                })
              ))}
            </Row>
          </Col>
          {actions && actions({}).length > 0 &&
            <Col className={clsx(
              styles.col,
              styles.actions,
              styles[`actions-${actions({}).length}`]
            )}>
              {actions(record).map((action, i) => action ? (
                cloneElement(action, {
                  key: i,
                  record,
                  recordID
                })
              ) : null)}
            </Col>
          }
        </Row>
      </div>
    </div>
  )
})

// EXPORT
export default ListRow;
