import React, { forwardRef, useEffect, useMemo } from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { compose } from 'redux';

// HELPERS
import { getLookupData } from 'helpers/getLookupData';

// CORE COMPONENTS
import Sprite from 'core/tools/Sprite';

// STYLES
import styles from './lookupOptions.module.scss';

// MAIN COMPONENT
export const lookupOptions = Component => compose(
  getLookupData(),
  forwardRef
)(({
  optionKeys,
  lookupData,
  // REST
  ...props
}, ref) => {

  // PROPS
  const {
    children,
    name,
    lookup,
    globalLookup,
    options,
    debug
  } = props;

  // MEMOS
  const optionMemo = useMemo(
    () => {
      if (!!options) return options;
      if (lookupData instanceof Error) return lookupData;
      if (Array.isArray(lookupData)) {
        if (isEmpty(lookupData)) return new Error('No options returned.');
        if (!optionKeys) return lookupData;
        else return lookupData.map(({
          [optionKeys.label]: label,
          [optionKeys.value]: value
        }) => ({
          label,
          value
        }))
      }
      if (!children && !options && !lookup && !globalLookup) return new Error('No options/lookup provided.');
    },
    [children, options, lookup, globalLookup, lookupData, optionKeys]
  )

  // EFFECTS
  useEffect(
    () => {
      if (debug) console.log(optionMemo);
    },
    [name, optionMemo, debug]
  )

  // RENDER
  return optionMemo instanceof Error ? (
    <div className={clsx(
      styles.warning,
      styles[props.size]
    )}>
      <Sprite className={styles.sprite} as={false} use="warning" fill="danger" />
      <span className="text-danger">{optionMemo.message || 'Unable to load.'}</span>
    </div>
  ) : lookup && !optionMemo ? (
    <div className={clsx(
      styles.warning,
      styles[props.size]
    )}>
      <Sprite.Loader className={styles.sprite} />
      <span className="loading text-muted font-italic">Loading</span>
    </div>
  ) : <Component
    {...props}
    options={optionMemo}
    ref={ref}
  />
})
