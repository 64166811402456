import React from 'react';

// DEPENDENCIES
import { connect } from 'react-redux';
import { compose } from 'redux';

// LOCAL COMPONENTS
import Menu from './prototype/Menu';
import UserMenu from './UserMenu';

// MAIN COMPONENT
const DefaultMenu = compose(
  connect(
    ({ mobile }) => ({ mobile })
  )
)(({
  mobile
}) => (
  <Menu>
    <Menu.Item
      label="Dashboard"
      icon="tachometer-alt"
      pathkey="dashboard"
    />
    <Menu.Item
      label="Manage Users"
      icon="user"
      pathkey="users"
    />
    <Menu.Submenu
      label="Website Management"
      icon="globe"
    >
      <Menu.Submenu.Item
        label="SEO Metadata"
        pathkey="seoMetadata"
      />
      <Menu.Submenu.Item
        label="Request Pages"
        pathkey="requestPages"
      />
      <Menu.Submenu.Item
        label="Dealership Hours"
        pathkey="dealershipHours"
      />
    </Menu.Submenu>
    <Menu.Submenu
      label="Leads / Forms"
      icon="clipboard-list"
    >
      <Menu.Submenu.Item
        label="Lead Forms"
        pathkey="leadForms"
      />
      <Menu.Submenu.Item
        label="Lead Routing"
        pathkey="leadRouting"
      />
    </Menu.Submenu>
    <Menu.Submenu
      label="Admin Configuration"
      icon="cog"
    >
      <Menu.Submenu.Item
        label="Lead Form Category"
        pathkey="leadFormCategory"
      />
      <Menu.Submenu.Item
        label="Dealership Groups"
        pathkey="dealershipGroups"
       />
      <Menu.Submenu.Item
        label="Dealerships"
        pathkey="dealerships"
      />
      <Menu.Submenu.Item
        label="Website General Info"
        pathkey="websiteGeneralInfo"
      />
      <Menu.Submenu.Item
        label="Applications"
        pathkey="applications"
      />
      <Menu.Submenu.Item
        label="Application Roles"
        pathkey="applicationRoles"
      />
      <Menu.Submenu.Item
        label="Application Configuration"
        pathkey="applicationConfiguration"
      />
      <Menu.Submenu.Item
        label="Organization Titles"
        pathkey="organizationTitles"
      />
      <Menu.Submenu.Item
        label="Service Transportation"
        pathkey="serviceTransportation"
      />
    </Menu.Submenu>
    {mobile &&
      <UserMenu />
    }
  </Menu>
))

// EXPORT
export default DefaultMenu;
