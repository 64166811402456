import React from 'react';

// DEPENDENCIES
import { connect } from 'react-redux';
import { compose } from 'redux';

// GLOBAL FUNCTIONS
import { loggedIn } from 'functions.js';

// HELPERS
import { checkScreenSize } from 'helpers/checkScreenSize';
import { getConfiguration } from 'helpers/getConfiguration';
import { scrollToTop } from 'helpers/scrollToTop';

// ROUTERS
import Interface from 'routers/interface/Interface';
import Authentication from 'routers/authentication/Authentication';

// MAIN COMPONENT
const App = compose(
  scrollToTop,
  checkScreenSize,
  getConfiguration,
  connect(
    ({
      configuration,
      token,
      tokenExp,
      user,
      dealership
    }) => ({
      configuration,
      token,
      tokenExp,
      user,
      dealership
    })
  )
)(({
  // REDUX STATE
  configuration,
  token,
  tokenExp,
  user,
  dealership
}) =>(
        configuration &&
        (!configuration.two_step_verification || user.verified) &&
        dealership &&
        loggedIn(token, tokenExp)
    ) ? <Interface /> : <Authentication />
)

export default App
