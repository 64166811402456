import React from 'react';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';

// FUNCTIONS
import { formatDate } from "functions";

// TOOLS
import Badge from 'tools/badge/Badge';

// PARTS
import List from 'parts/interface/list/List';
const { Action, Col, Tool, Button } = List;

// ARGS
const ARGS = {
    label:    'dealership group',
    idKey:    'dealer_group_id',
    endpoint: ENDPOINTS.record.dealershipGroups.list
}


const BUTTONS = [ <Button.Add /> ]

// TOOLBAR
const TOOLS = [ <Tool.ResultCount /> ]

// COLUMNS
const COLUMNS = ({
 dealer_group_name,
 city,
 state,
 zip_code,
 active_status,
 created_date
}) => [
    <Col
        cols={{ lg: 7 }}
        label="Dealership Group Name"
        value={dealer_group_name}
    />,
    <Col
        cols={{ lg: 3 }}
        label="City"
        value={city}
    />,
    <Col
        cols={{ lg: 2 }}
        label="State"
        value={ state }
    />,
    <Col
        cols={{ lg: 3 }}
        label="Zip Code"
        value={ zip_code }
    />,
    <Col
        cols={{ lg: 2 }}
        label="Active Status"
        as={ Badge.Active }
        value={active_status === 'Yes'}
    />,
    <Col
        cols={{ lg: 5 }}
        label="Created Date"
        value={formatDate(created_date)}
    />
]

// ACTIONS
const ACTIONS = () => [
    <Action.Edit />
]

// MAIN COMPONENT
const DealershipGroups = () => <List
    title="Dealership Groups"
    args={ARGS}
    tools={TOOLS}
    buttons={BUTTONS}
    columns={COLUMNS}
    actions={ACTIONS}
/>

export default DealershipGroups;
