// import React, {useContext, useMemo} from 'react';
import React, {useContext } from 'react';

// DEPENDENCIES
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { compose } from 'redux';

// DEPENDENCIES
import { startCase } from 'lodash';

// GLOBAL VARIABLES
// import { USER_ROLES } from 'globals.js';
import { PATHNAMES } from 'pathnames.js';

// GLOBAL FUNCTIONS
import { getPathType, makePath } from 'functions.js';

// CONTEXT
import { ListContext } from '../../../helpers/getListData';

// CORE COMPONENTS
import Button from 'core/tools/Button';

// MAIN COMPONENT
const AddButton = compose(
  connect(
    ({ user }) => ({ user })
  )
)(({
  className,
  // REDUX STATE
  user: {
    role_key
  }
}) => {

  // CONTEXT
  const { label } = useContext(ListContext) || {};
  const { search } = useLocation();
  
  // Commented until a proper roles list will not be defined
  // const notAdmin = useMemo(
  //   () => role_key !== USER_ROLES.applicationAdmin.key,
  //   [role_key]
  // )
  //
  // RENDER
  // return !notAdmin &&  <Button.Add  

  // RENDER
  return <Button.Add
    className={className}
    variant="success"
    label={`Add ${startCase(label)}`}
    to={`${makePath(PATHNAMES[getPathType()], PATHNAMES.add)}${search}`}
  />
})

// EXPORT
export default AddButton;
