import React from 'react';

// GLOBAL FUNCTIONS
import { hasUserRole } from 'functions.js';

// MAIN COMPONENT
export const checkRole = Component => ({
  userRole,
  ...props
}) => !hasUserRole(userRole) ? null : <Component {...props} />
