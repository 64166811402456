import React, { forwardRef, useCallback, useMemo, useState } from 'react';

// GLOBAL FUNCTIONS
import { doCallback } from 'functions.js';

// MAIN COMPONENT
export const optionsFilter = Component => {
  return forwardRef(({
    options,
    dropdown: {
      onShow,
      ...dropdown
    } = {},
    toggle: {
      onChange,
      ...toggle
    } = {},
    onFocus,
    onBlur,
    onSelect,
    allowCustom,
    filterOptions = allowCustom,
    showCustom = filterOptions,
    ...props
  }, ref) => {

    // FILTER STATE
    const [ filtering, setFiltering ] = useState(false);
    const [ filter, setFilter ] = useState();

    // OPTIONS MEMO
    options = useMemo(
      () => Array.isArray(options) && filter ? options.filter(o => o.label.toLowerCase().search(filter.toString().toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&')) > -1) : options,
      [options, filter]
    )

    // FILTER CALLBACK
    const handleChange = useCallback(
      e => {
        doCallback(onChange, e);
        if (filterOptions) setFilter(e.target.value);
      },
      [onChange, filterOptions, setFilter]
    )
    const handleSelect = useCallback(
      value => {
        doCallback(onSelect, value);
        setFilter(undefined);
      },
      [onSelect, setFilter]
    )
    const handleFocus = useCallback(
      e => {
        doCallback(onFocus, e);
        setFiltering(true);
      },
      [onFocus, setFiltering]
    )
    const handleBlur = useCallback(
      e => {
        doCallback(onBlur, e);
        setFiltering(false);
      },
      [onBlur, setFiltering]
    )
    const handleShow = useCallback(
      show => {
        doCallback(onShow, show);
        if (filterOptions && !allowCustom && !filtering && !show) setFilter(undefined);
      },
      [onShow, filterOptions, allowCustom, setFilter, filtering]
    )

    // RENDER
    return <Component
      {...props}
      options={options}
      onSelect={handleSelect}
      onChange={handleChange}
      dropdown={{
        ...dropdown,
        onShow: handleShow
      }}
      toggle={{
        ...toggle,
        onChange: handleChange,
        onFocus: handleFocus,
        onBlur: handleBlur,
      }}
      filter={filter}
      filterOptions={filterOptions}
      allowCustom={allowCustom}
      showCustom={showCustom}
      ref={ref}
    />
  })
}
