export const PATHNAMES = {
  // AUTHENTICTATION
  login:              'login',
  requestCode:        'request-code',
  enterCode:          'enter-code',
  chooseDealership:   'choose-dealership',
  resetPassword:      'reset-password',
  // PAGES
  page:               'page',
  dashboard:          'dashboard',
  search:             'search',
  // RECORD STEPS
  add:                'add',
  view:               'view',
  edit:               'edit',
  // USER
  user:               'user',
  profile:            'profile',
  changePassword:     'change-password',


  // USERS
  users:                    'users',
  // WEBSITE MANAGEMENT
  seoMetadata:              'seo-metadata',
  requestPages:             'request-pages',
  dealershipHours:          'dealership-hours',
  serviceItems:             'service-items',
  generalService:           'general-service',
  scheduledMaintenance:     'scheduled-maintenance',
  // LEADS/FORMS
  leadForms:                'lead-forms',
  leadRouting:              'lead-routing',
  // ADMIN CONFIGURATION
  leadFormCategory:         'lead-form-category',
  dealershipGroups:         'dealership-groups',
  dealerships:              'dealerships',
  websiteGeneralInfo:       'website-general-info',
  applications:             'applications',
  applicationRoles:         'application-roles',
  applicationConfiguration: 'application-configuration',
  organizationTitles:       'organization-titles',
  serviceTransportation:    'service-transportation'
}
