export const SITE_TITLE = 'GMS Admin Console Tool';
export const VERSION = process.env.REACT_APP_VERSION;
export const COPYRIGHT = 'Geneva Media Services LLC';

export const DEV_ENVIRONMENT = true;

export const PATH = 'gms-act';

export const ENVIRONMENTS = {
  dev: {
    hostname: process.env.REACT_APP_DEV_SITE_DOMAIN,
    key: process.env.REACT_APP_DEV_API_KEY,
    url: process.env.REACT_APP_DEV_HOST_URL
  },
  test: {
    hostname: process.env.REACT_APP_TEST_SITE_DOMAIN,
    key: process.env.REACT_APP_TEST_API_KEY,
    url: process.env.REACT_APP_TEST_HOST_URL
  },
  stage: {
    hostname: process.env.REACT_APP_STAGE_SITE_DOMAIN,
    key: process.env.REACT_APP_STAGE_API_KEY,
    url: process.env.REACT_APP_STAGE_HOST_URL
  },
  prod: {
    hostname: process.env.REACT_APP_PROD_SITE_DOMAIN,
    key: process.env.REACT_APP_PROD_API_KEY,
    url: process.env.REACT_APP_PROD_HOST_URL
  }
}

export const DEBUG = false;

export const TINY_MCE_API = process.env.REACT_APP_TINY_MCE_API;


// UI

export const BREAKPOINTS = {
  xs: 0,
  sm: 470,
  md: 768,
  lg: 1024,
  xl: 1440
}

export const TIMES = {
  configurationTimerLength: 12,  // in hours
  modalDuration:            2,   // in seconds
  reauthWarningTime:        2,   // in minutes
  sessionTimerLength:       5,   // in seconds
  transitionDuration:       150, // in milliseconds
  tooltipDelay:             500  // in milliseconds
}

export const ROWS_ON_PAGE = {
  default: 25,
  options: [ 12, 25, 50, 100 ]
}

export const MODAL_PRIORITY = {
  low:     -1,
  default:  0,
  high:     2,
  override: 3,
}


// ROLES/PERMISSIONS

export const USER_ROLES = {
  applicationUser: {
    key:          'APPUSR',
    label:        'Application User',
    abbreviation: 'User',
    variant:      'primary'
  },
  dealershipStoreAdmin: {
    key:          'DADMIN',
    label:        'Dealership Store Admin',
    abbreviation: 'Store Admin',
    variant:      'success'
  },
  applicationAdmin: {
    key:          'APPADM',
    label:        'Application Admin',
    abbreviation: 'App Admin',
    variant:      'warning'
  },
  applicationMaster: {
    key:          'APMSTR',
    label:        'Application Master',
    abbreviation: 'Master',
    variant:      'danger'
  }
}

export const PERMISSIONS = {
  foobar: 'FOOBAR' // EXAMPLE ONLY
}


// MISC

export const DELIVERY_METHOD = {
  email: 'E',
  text:  'T',
  both:  'B'
}

export const DEALERSHIP_HOURS = {
  parts: {
    name: 'Parts Hours',
    code: 'PRT'
  },
  sales: {
    name: 'Sales Hours',
    code: 'SLS'
  },
  service: {
    name: 'Service Hours',
    code: 'SVC'
  }
}

export const SERVICE_ITEMS = {
  generalService: {
    title: 'General Service Items',
    code: 'G'
  },
  scheduledMaintenance: {
    title: 'Scheduled Maintenance',
    code: 'S'
  },
  /*
  yearModelSpecific: {
    title: 'Year/Model-Specific Maintenance',
    code: 'Y'
  }
  */
}

// NUMERICAL

export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const TODAY = new Date();

export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export const THIS_MONTH = new Date(TODAY.getFullYear(), TODAY.getMonth(), 1);

export const DATE_RANGES = {
  custom: {
    label: 'Custom'
  },
  today: {
    label: 'Today',
    startDate: TODAY,
    endDate:   TODAY
  },
  thisWeek: {
    label: 'This Week',
    startDate: new Date(TODAY).setDate(TODAY.getDate() - TODAY.getDay()),
    endDate:   TODAY
  },
  lastWeek: {
    label: 'Last Week',
    startDate: new Date(TODAY).setDate(TODAY.getDate() - TODAY.getDay() - 7),
    endDate:   new Date(TODAY).setDate(TODAY.getDate() - TODAY.getDay() - 1)
  },
  thisMonth: {
    label: 'This Month',
    startDate: new Date(TODAY).setDate(1),
    endDate:   TODAY
  },
  lastMonth: {
    label: 'Last Month',
    startDate: new Date(new Date(TODAY).setMonth(TODAY.getMonth() - 1)).setDate(1),
    endDate:   new Date(TODAY).setDate(0)
  },
  thisQuarter: {
    label: 'This Quarter',
    startDate: new Date(new Date(TODAY).setMonth(Math.floor(TODAY.getMonth()/3) * 3)).setDate(1),
    endDate:   TODAY
  },
  lastQuarter: {
    label: 'Last Quarter',
    startDate: new Date(new Date(TODAY).setMonth(Math.floor(TODAY.getMonth()/3) * 3 - 3)).setDate(1),
    endDate:   new Date(new Date(TODAY).setMonth(Math.floor(TODAY.getMonth()/3) * 3)).setDate(0)
  },
  thisYear: {
    label: 'This Year',
    startDate: new Date(TODAY.getFullYear(), 0, 1),
    endDate:   TODAY
  },
  lastYear: {
    label: 'Last Year',
    startDate: new Date(TODAY.getFullYear() - 1, 0, 1),
    endDate: new Date(TODAY.getFullYear() - 1, 11, 31)
  }
}
