import React, { useContext, useMemo } from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// GLOBAL FUNCTIONS
import { getFirstPage } from 'functions.js';

// HELPERS/CONTEXT
import { ListContext } from '../../helpers/getListData.js';

// CORE COMPONENTS
import Button from 'core/tools/Button';

// STYLES
import styles from './listPagination.module.scss';

// MAIN COMPONENT
const ListPagination = compose(
  connect(
    ({ records }) => ({ records })
  )
)(({
  // REDUX STATE PROPS
  records: {
    total = 0
  }
}) => {

  // CONTEXT
  const { params: { page, rowsOnPage } } = useContext(ListContext) || {};

  // MEMOS
  const pageNum = useMemo(
    () => parseInt(page, 10),
    [page]
  )
  const firstPage = useMemo(
    () => 1,
    [],
  )
  const lastPage = useMemo(
    () => Math.ceil(total/rowsOnPage),
    [total, rowsOnPage]
  )
  const pages = useMemo(
    () => {
      const pages = [];
      if (pageNum - 3 > firstPage && pageNum === lastPage) pages.push(pageNum - 3);
      if (pageNum - 2 > firstPage && pageNum > lastPage - 2) pages.push(pageNum - 2);
      if (pageNum - 1 > firstPage) pages.push(pageNum - 1);
      if (pageNum !== firstPage && pageNum !== lastPage) pages.push(pageNum);
      if (pageNum + 1 < lastPage) pages.push(pageNum + 1);
      if (pageNum + 2 < lastPage && pageNum < firstPage + 2) pages.push(pageNum + 2);
      if (pageNum + 3 < lastPage && pageNum === firstPage) pages.push(pageNum + 3);
      return pages;
    },
    [pageNum, firstPage, lastPage]
  )

  // RENDER
  return total <= rowsOnPage ? null :(
    <nav className={styles.nav}>
      <ul className={clsx(
        'pagination',
        styles.list
      )}>
        <Item
          className={styles.back}
          page={pageNum - 1}
          icon="chevron-left"
          disabled={pageNum === 1}
        />
        <Item
          page={firstPage}
          label={firstPage}
          state={pageNum === firstPage ? 'active' : null}
        />
        {pages[0] > firstPage + 1 &&
          <Item
            label="&hellip;"
            state="disabled"
          />
        }
        {pages.map((n, i) =>
          <Item
            key={i}
            page={n}
            label={n}
            state={n === pageNum ? 'active' : null}
          />
        )}
        {pages[pages.length - 1] < lastPage - 1 &&
          <Item
            className={styles.ellipsis}
            label="&hellip;"
            state="disabled"
          />
        }
        <Item
          page={lastPage}
          label={lastPage}
          state={pageNum === lastPage ? 'active' : null}
        />
        <Item
          className={styles.next}
          page={pageNum + 1}
          icon="chevron-right"
          disabled={pageNum === lastPage}
        />
      </ul>
    </nav>
  )
})

// CHILD COMPONENT
const Item = withRouter(({
  className,
  linkClassName,
  page,
  label,
  icon,
  state,
  disabled = state === 'disabled' || state === 'active',
  // REACT ROUTER PROPS
  location: {
    search
  }
}) => {

  // MEMO
  const to = useMemo(
    () => page > 1 ? `${getFirstPage()}page/${page}/${search}` : `${getFirstPage()}${search}`,
    [page, search]
  )

  // RENDER
  return (
    <li className={clsx(
      'page-item',
      styles.item,
      styles[state],
      className,
      state
    )}>
      <Button
        className={clsx(
//          styles.link,
          disabled && styles.disabled,
          linkClassName
        )} //"page-link d-none d-sm-block"
        to={to}
        label={label}
        icon={icon}
        size="sm"
        square
        outline
        disabled={disabled}
      />
    </li>
  )
})

// EXPORT
export default ListPagination;
