import React from 'react';

// CORE COMPONENTS
import Form from 'core/form/Form';

// STYLES
import styles from './recordToggle.module.scss';

// MAIN COMPONENT
const RecordToggle = {
  Active: props => (
    <Form.Row>
      <Form.YesNo
        className={styles.toggle}
        name="active_flag"
        options={[
          {
            label: 'Active',
            value: true
          },
          {
            label: 'Inactive',
            value: false
          }
        ]}
        inline
        group
        {...props}
      />
    </Form.Row>
  ),
  Global: props => (
    <Form.Row>
      <Form.YesNo
        className={styles.toggle}
        name="global_flag"
        options={[
          {
            label: 'Local',
            value: 'false'
          },
          {
            label: 'Global',
            value: 'true'
          }
        ]}
        inline
        group
        {...props}
      />
    </Form.Row>
  )
}

// EXPORT
export default RecordToggle
