import React, { useCallback, useMemo, useEffect } from 'react';

// DEPENDENCIES
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';

// GLOBAL FUNCTIONS
import { lookupActions } from 'actions.js';
import { apiFetch } from 'functions.js';

// MAIN COMPONENT
export const getLookupData = (args = {}) => Component => compose(
  connect(
    ({ lookups }) => ({ lookups }),
    { ...lookupActions }
  )
)(({
  lookup = args.lookup,
  globalLookup = args.globalLookup,
  lookupParams = args.lookupParams,
  lookupDeps = args.lookupDeps,
  evergreen = args.evergreen || false,
  // REDUX STATE
  lookups,
  // REDUX DISPATCH
  init,
  load,
  error,
  flush,
  // REST
  ...props
}) => {

  // PROPS
  const {
    debug = args.debug,
  } = props;

  // CALLBACKS
  const fetchLookup = useCallback(
    (lookup, params) => {
      if (evergreen && !_.isEmpty(lookups[lookup]))
        return;

      apiFetch({
        endpoint: ENDPOINTS.lookup[lookup],
        params,
        useQueryString: true,
        onFetch: () => init(lookup),
        onSuccess: data => load(lookup, data.result || data),
        onError: () => error(lookup),
        debug
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lookups.length, init, load, error, evergreen, debug]
  )

  // MEMOS
  const lookupData = useMemo(
    () => {
      return Array.isArray(lookup)
        ? lookup.reduce((o, key) => ({ ...o, [key]: lookups[key] }), {})
        : lookups[lookup]
    },
    [lookup, lookups]
  )

  // EFFECTS
  useEffect(
    () => {
      if (!lookup || globalLookup) return;
      if (Array.isArray(lookup)) lookup.forEach(key => fetchLookup(key));
      else fetchLookup(lookup, lookupParams);
      //return () => flush(lookup);
    },
    //[lookup, globalLookup, fetchLookup, flush]
    [lookup, lookupParams, lookupDeps, globalLookup, fetchLookup]
  )

  return (
    <Component
      lookup={lookup}
      lookupData={lookupData}
      globalLookup={globalLookup}
      {...props}
    />
  )
})
