import React, { forwardRef, useCallback, useContext, useMemo, useRef } from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import isObject from 'lodash';
import { compose } from 'redux';

// GLOBAL VARIABLES
import { PLACEHOLDERS } from 'defaults.js';

// GLOBAL FUNCTIONS
import { doCallback } from 'functions.js';

// HELPERS
import { numberFormat } from '../../../helpers/state/numberFormat';

// CONTEXT
import { DropdownContext } from 'core/tools/dropdown/helpers/dropdownContext';

// LOCAL COMPONENTS
import Input from '../../../parts/Input';

// STYLES
import styles from './input.module.scss';

// MAIN COMPONENT
const DropdownInput = compose(
  numberFormat,
  forwardRef
)(({
  className,
  name,
  value = '',
  label = PLACEHOLDERS.option,
  icon,
  append,
  onClick,
  onFocus,
  onBlur,
  onChange,
  onSelect,
  selection = {},
  filter,
  useDropdown,
  useDummy,
  filterOptions = false,
  allowCustom = false,
  showCustom = false,
  disabled = !useDropdown && !filterOptions && !allowCustom,
  placeholder = allowCustom ? PLACEHOLDERS.customSelect : `${PLACEHOLDERS.select} ${label}`,
  form: {
    setFieldValue
  } = {},
  ...props
}, ref) => {

  // CONTEXT
  const { show, setShow } = useContext(DropdownContext) || {};

  // REFS
  const input = useRef();

  // MEMOS
  const displayValue = useMemo(
    () => filter !== undefined ? filter : allowCustom ? selection.label || value : selection.label,
    [value, selection, filter, allowCustom]
  )

  // CALLBACKS
  const handleClick = useCallback(
    e => {
      doCallback(onClick, e);
      doCallback(onBlur, e);
      doCallback(setShow, !show);
    },
    [onClick, onBlur, show, setShow]
  )
  const handleFocus = useCallback(
    e => {
      doCallback(onFocus, e);
      if (!filterOptions && !allowCustom && input.current) {
        doCallback(setShow, !show);
        if (input.current instanceof HTMLElement) input.current.blur();
        return false;
      } else {
        doCallback(setShow, showCustom || !show);
      }
    },
    [onFocus, filterOptions, allowCustom, showCustom, show, setShow]
  )
  const handleBlur = useCallback(
    e => {
      doCallback(onBlur, e);
      if (allowCustom && e.target.value !== value) {
        doCallback(onSelect, e.target.value);
      }
    },
    [value, onBlur, onSelect, allowCustom]
  )
  const handleChange = useCallback(
    e => {
      if (filterOptions || allowCustom) doCallback(onChange, e);
      if (allowCustom) doCallback(setFieldValue, name, e.target.value);
      if (show) doCallback(setShow, showCustom);
    },
    [name, onChange, filterOptions, allowCustom, show, setShow, showCustom, setFieldValue]
  )

  // RENDER
  return (
    <div ref={ref}>
      <Input
        className={clsx(
          styles.input,
          !allowCustom && styles.toggle,
          className
        )}
        id={name && useDummy ? `dummy.${name}` : name}
        value={displayValue}
        placeholder={placeholder}
        append={disabled || !useDropdown ? undefined : Object.assign({
          onClick: handleClick
        }, isObject(icon) ? icon : {
          use: icon
        }, append)}
        inputGroup={{
          className: clsx(
            show && 'form-control-focus'
          )
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        autoComplete="off"
        useFeedback={false}
        disabled={disabled}
        ref={input}
        {...props}
      />
    </div>
)
})

// EXPORT
export default DropdownInput;
