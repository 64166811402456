import React, { useCallback, useContext } from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { compose } from 'redux';
import { isFunction } from 'lodash';

// GLOBAL VARIABLES
import { UPLOAD } from 'defaults.js';

// GLOBAL FUNCTIONS
import { doCallback, modalFunctions, randomID } from 'functions.js';

// CONTEXT/HELPERS
import { UploadContext } from '../helpers/uploadContext';
import { uploadFile } from '../helpers/uploadFile';

// CORE COMPONENTS
import Button from 'core/tools/Button';

// STYLES
import styles from './uploadButton.module.scss';

// MAIN COMPONENT
const UploadButton = compose(
  uploadFile
)(({
  name,
  button: {
    className,
    containerClassName,
    ...button
  } = {},
  uploadFile,
  enableUpload,
  accept = UPLOAD.accept,
  maxSize = UPLOAD.maxSize,
  multiple = UPLOAD.multiple,
  disabled,
  plaintext,
  readonly,
  uploadArgs={},
  // FORMIK BAG
  form: {
    setFieldTouched
  } = {}
}) => {

  // CONTEXT
  const { processing, uploads, addUpload } = useContext(UploadContext) || {};

  // DROPZONE
  const handleDrop = useCallback(
    (acceptedFiles, failedItems) => {
      doCallback(setFieldTouched, name, true);

      acceptedFiles.forEach(file => uploadFile(file));

      failedItems.forEach(({ errors, file }) => {
        modalFunctions.error(`Failed to upload ${file.name}. ${errors.map(e => e.message).join(' ')}`)
        const { onError } = isFunction(uploadArgs) ? uploadArgs(file) : uploadArgs;
        doCallback(onError, { file, errors});
        addUpload({
          uploadID: randomID(),
          uploading: false,
          error: true,
          meta: {
            fileName: file.name
          }
        });
      })
    },
    [name, setFieldTouched, uploadFile, addUpload, uploadArgs]
  )
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple,
    accept,
    maxSize,
    disabled
  })
  const handleClick = useCallback(
    e => {
      e.stopPropagation();
      open(e);
    },
    [open]
  )

  // RENDER
  return (uploads.length > 0 && !multiple) || readonly || plaintext ? null : (
    <div
      className={clsx(
        styles.container,
        button.fullWidth && styles.fullWidth,
        isDragActive && 'dragging',
        disabled && 'disabled',
        containerClassName
      )}
      {...(enableUpload ? getRootProps() : {})}
    >
      {enableUpload &&
        <input {...getInputProps()} />
      }
      <Button
        className={clsx(
          styles.button,
          className
        )}
        variant={UPLOAD.variant}
        label={UPLOAD.uploadLabel}
        icon={UPLOAD.uploadIcon}
        onClick={handleClick}
        fullWidth={UPLOAD.fullWidth}
        disabled={!enableUpload || !!processing}
        size={UPLOAD.buttonSize}
        {...button}
      />
    </div>
  )
})

export default UploadButton;
