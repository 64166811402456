import React, { useEffect, useMemo } from 'react';

// DEPENDENCIES
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

// GLOBAL VARIABLES
import { USER_ROLES } from 'globals.js';

// GLOBAL FUNCTIONS
import { referrerActions } from 'actions';

// HELPERS / CONTEXT
import { sessionTimer } from 'helpers/sessionTimer';

// CORE COMPONENTS
import Modal from 'core/tools/modal/Modal';

// PARTS
import Page from 'parts/interface/page/Page'

// MENUS
import DefaultMenu from 'parts/interface/menus/DefaultMenu';

// ROUTES
import DefaultRouter from './sections/record/DefaultRouter';

// MAIN COMPONENT
const Interface = compose(
  sessionTimer,
  connect(
    ({ user, referrer }) => ({ user, referrer }),
    {...referrerActions}
  ),
  withRouter
)(({
  // REDUX STATE
  user,
  referrer,
  // REDUX DISPATCH
  clear: clearReferrer,
}) => {

  // MEMOS
  const [Menu, Router] = useMemo(
    () => {
      switch(user.role_key) {
        case USER_ROLES.foobar?.key:     return [DefaultMenu, DefaultRouter];
        default:                        return [DefaultMenu, DefaultRouter];
      }
    },
    [user.role_key]
  )

  // EFFECTS
  useEffect(
    () => {
      if (!referrer) return;
      clearReferrer();
    },
    [referrer, clearReferrer]
  )

  // RENDER
  return referrer ? null : (<>
    <Page
      menu={Menu}
    >
      <Router />
    </Page>
    <Modal.Router />
  </>)
})


// EXPORT
export default Interface;
