import React, { createContext, useCallback, useEffect, useMemo } from 'react';

// DEPENDENCIES
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

// GLOBAL FUNCTIONS
import { recordActions } from 'actions.js';
import { apiFetch, makePath } from 'functions.js';

// CONTEXT EXPORT
export const RecordContext = createContext(null);

// MAIN COMPONENT
export const getRecordData = Component => compose(
  connect(
    ({ record }) => ({ record }),
    { ...recordActions }
  ),
  withRouter
)(({
  label = 'entry',
  idKey = 'id',
  endpoint,
  recordID,
  useID = true,
  params,
  debug,
  // REDUX STATE
  record,
  // REDUX DISPATCH
  load,
  error,
  append,
  flush,
  // REACT ROUTER
  history,
  location,
  match,
  staticContext,
  // REST
  ...props
}) => {

  // MEMOS
  recordID = useMemo(
    () => recordID || match.params.recordID,
    [recordID, match.params.recordID]
  )

  // FETCH REQUEST
  const fetchRecord = useCallback(
    () => {
      if (!endpoint) return;
      if (!recordID && useID) return;
      apiFetch({
        endpoint: makePath(endpoint, recordID),
        params,
        loadingMessage: `Loading ${label}`,
        errorMessage: `Unable to load ${label}.`,
        onSuccess: load,
        debug
      })
    },
    [label, endpoint, recordID, useID, params, load, debug]
  )

  // MOUNT LISTENER
  useEffect(
    () => {
      fetchRecord();
    },
    [fetchRecord]
  )

  // UNMOUNT LISTENER
  useEffect(
    () => flush,
    [flush]
  )

  // RENDER
  return (
    <RecordContext.Provider value={{
      label,
      idKey,
      record,
      recordID,
      fetchRecord
    }}>
      <Component {...props} />
    </RecordContext.Provider>
  )
})
