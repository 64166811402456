import React, { forwardRef, useCallback, useContext, useMemo } from 'react';

// DEPENDENCIES
import { isFunction } from 'lodash';
import { compose } from 'redux';

// GLOBAL VARIABLES
import { UPLOAD } from 'defaults.js';

// GLOBAL FUNCTIONS
import { apiFetch, doCallback, makePath, randomID } from 'functions.js';

// CONTEXT
import { UploadContext } from './uploadContext';
import { RecordContext } from 'parts/interface/helpers/getRecordData';

// MAIN COMPONENT
export const uploadFile = Component => compose(
  forwardRef
)(({
    uploadArgs = {},
    endpoints = UPLOAD.endpoints || {},
    endpoint = endpoints.upload,
    useRecordID = UPLOAD.useRecordID,
    processUploadMeta,
    processFieldValue,
    ...props
}, ref) => {

  // PROPS
  const { disabled } = props;

  // CONTEXT
  const { recordID = props.recordID } = useContext(RecordContext) || {};
  const { addUpload, setSuccess, setError } = useContext(UploadContext) || {};

  // CALLBACKS
  const uploadFile = useCallback(
    file => {
      const uploadID = randomID();
      const { onFetch, onSuccess, onUpload, onError, ...args } = isFunction(uploadArgs) ? uploadArgs(uploadID, file) : uploadArgs;

      apiFetch({
        method: 'POST',
        endpoint: useRecordID ? makePath(endpoint, recordID) : endpoint,
        headers: {
          'Content-Type': file.type,
          'Content-Length': file.size
        },
        body: file,
        useQueryString: true,
        errorMessage: 'Unable to upload file.',
        onFetch: () => {
          doCallback(onFetch)
          addUpload({
            uploadID,
            meta: {
              fileName: file.name
            }
          });
        },
        onSuccess: data => {
          doCallback(onSuccess, data);
          const meta = doCallback(processUploadMeta, data, file);
          const fieldValue = doCallback(processFieldValue, data, file);
          doCallback(onUpload, uploadID, file, meta, fieldValue);
          setSuccess(uploadID, file, meta, fieldValue);
        },
        onError: error => {
          doCallback(onError, error);
          setError(uploadID);
        },
        ...args,
      })
    },
    [endpoint, recordID, useRecordID, uploadArgs, addUpload, setSuccess, setError, processUploadMeta, processFieldValue ]
  )

  // MEMOS
  const enableUpload = useMemo(
    () => !disabled && !!endpoint && (!!recordID || !useRecordID),
    [disabled, endpoint, recordID, useRecordID]
  )

  // RENDER
  return (
    <Component
      {...props}
      uploadFile={uploadFile}
      enableUpload={enableUpload}
      ref={ref}
    />
  )
})
