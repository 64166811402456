import React from 'react';

// LOCAL COMPONENTS
import YesNoNA from './YesNoNA';

// VARIABLES
const NEW_USED_OPTIONS = [
  {
    label: 'New'
  }, {
    label: 'Used'
  }
]

// MAIN COMPONENT
const NewUsed = props => (
  <YesNoNA
    name="new_used"
    options={NEW_USED_OPTIONS}
    {...props}
  />
)

//EXPORT
export default NewUsed;
