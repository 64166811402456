import React from 'react';

// TOOLS
import Dealerships from 'parts/interface/session/types/Dealerships';

// LOCAL COMPONENTS
import SessionCollapse from './SessionCollapse';

// MAIN COMPONENT
const DealershipCollapse = () => (
  <SessionCollapse
    component={Dealerships}
    label={Dealerships.label}
  />
)

// EXPORT
export default DealershipCollapse
