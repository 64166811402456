import React from 'react';

// TOOLS
import CharKey from 'tools/form/CharKey';

// PROTOTYPE
import EditPrototype from "../prototype/EditPrototype";

// PARTS
import Record from "parts/interface/record/Record";

// CORE
import Form from "core/form/Form";

// CONSTANTS
import { PATHNAMES } from "pathnames";

// FUNCTIONS
import { makePath } from 'functions';

// DEFAULT DATA
const DEFAULT_DATA = {
    category_name: '',
    char_key: '',
    make_dealer_id: ''
}

// MAIN COMPONENT
const LeadFormCategory = () => (
    <EditPrototype
        routeKey="leadFormCategory"
        idKey="lead_category_id"
        form={{ initialValues: DEFAULT_DATA }}
        redirect={makePath(PATHNAMES.leadFormCategory)}
    >
        <Form.Control
            name="category_name"
            label="Lead Category Name"
            required
        />
        <CharKey />
        <Form.Col>
            <Record.Card.Subtitle>Dealerships</Record.Card.Subtitle>
        </Form.Col>
        <Form.Checklist
            name="make_dealer_id"
            lookup="dealershipSites"
            optionKeys={{label: 'dealer_name', value: 'make_dealer_id'}}
            list={{cols: { sm: 24, md: 12, lg: 6 }}}
            fullWidth
        />
    </EditPrototype>
)

export default LeadFormCategory;
