// SESSION

export const configuration = (state = null, { type, configuration }) => {
  switch(type) {
    case 'SET_CONFIGURATION':
      return { ...configuration };
    case 'CLEAR_CONFIGURATION':
      return null;
    default:
      return state;
  }
}

export const token = (state = null, { type, token }) => {
  switch(type) {
    case 'LOGIN_USER':
      return token;
    case 'SET_DEALERSHIP':
      return token;
    case 'SET_USER_ROLE':
      return token;
    default:
      return state;
  }
}

export const tokenExp = (state = null, { type, token_exp }) => {
  switch(type) {
    case 'LOGIN_USER':
      return token_exp;
    case 'EXTEND_SESSION':
      return token_exp;
    case 'SET_DEALERSHIP':
      return token_exp;
    case 'SET_USER_ROLE':
      return token_exp;
    default:
      return state;
  }
}

export const user = (state = {}, { type, ...action }) => {
  switch(type) {
    case 'LOGIN_USER':
      return { ...action.user };
    case 'SET_VERIFICATION':
      const { verified } = action;
      return {
        ...state,
        verified
      };
    case 'SET_USER_ROLE':
      const { role_key, user_account_role_id } = action;
      return {
        ...state,
        role_key,
        user_account_role_id
      }
    case 'UPDATE_USER_PRPOFILE' :
      const { profile = {} } = action;
      return {
        ...state,
        ...profile
      };
    default:
      return state;
  }
}

export const dealership = (state = null, { type, ...action }) => {
  switch(type) {
    case 'LOGIN_USER':
      const { user } = action;
      // if (user.stores.length > 1) return null;
      return user.make_dealer_id
    case 'SET_DEALERSHIP':
      return action.make_dealer_id;
    default:
      return state;
  }
}


// INTERFACE

export const mobile = (state = false, { type, ...action }) => {
  switch(type) {
    case 'SET_SCREEN_SIZE':
      return action.isMobile;
    default:
      return state;
  }
}

export const referrer = (state = null, { type, ...action }) => {
  switch(type) {
    case 'SET_REFERRER':
      return action.referrer;
    case 'CLEAR_REFERRER':
      return null;
    default:
      return state;
  }
}

export const alerts = (state = [], { type, ...action }) => {
  let newState = [ ...state ];

  switch(type) {
    case 'ADD_ALERTS':
      newState.unshift(...action.alerts);
      return newState;
    case 'REMOVE_ALERT':
      const position = newState.findIndex(alert => alert.ID === action.alertID);
      if (position >= 0) newState.splice(position, 1);
      return newState;
    case 'CLEAR_ALERTS':
      newState = Array.isArray(action.alerts) ? [ ...action.alerts ] : [];
      return newState;
    case 'LOGIN_USER':
      return [];
    default:
      return state;
  }
}

export const modals = (state = [], { type, ...action }) => {
  const newState = [ ...state ];

  switch(type) {
    case 'ADD_MODAL':
      const insertionPoint = newState.findIndex(
        modal => modal.priority < action.modal.priority
      );
      insertionPoint === -1
        ? newState.push(action.modal)
        : newState.splice(insertionPoint, 0, action.modal);
      return newState;
    case 'REMOVE_MODAL':
      const modalIndex = newState.findIndex(
        modal => modal.ID === action.modalID
      );
      modalIndex === 0
        ? newState[modalIndex].close = true
        : newState.splice(modalIndex, 1);
      return newState;
    case 'INCREMENT_MODAL':
      newState.shift();
      return newState;
    case 'CLEAR_MODALS':
      return [];
    default:
      return state;
  }
}

export const menu = function(state = false, { type, toggle = !state }) {
  switch(type) {
    case 'TOGGLE_MENU':
      return toggle;
    default:
      return state;
  }
}

export const sidebar = function(state = {}, { type, sidebarName, recordID = true }) {
  switch(type) {
    case 'TOGGLE_SIDEBAR':
      return sidebarName ? {
        [sidebarName]: recordID
      } : {}
    default:
      return state;
  }
}


// DATA/SETTINGS

export const filters = (state = {}, { type, path, ...action }) => {
  const filters = state[path] || {};
  switch(type) {
    case 'ADD_FILTERS':
      return {
        ...state,
        [path]: {
          ...filters,
          ...action.filters
        }
      };
    case 'CLEAR_FILTERS':
      return {
        ...state,
        [path]: action.init || {}
      };
    case 'UPDATE_FILTER':
      return {
        ...state,
        [path]: {
          ...filters,
          [action.name]: action.value
        }
      };
    case 'CLEAR_FILTER':
      const { [action.name]: _clearedFilter , ...rest } = filters;
      return {
        ...state,
        [path]: rest
      };
    default:
      return state;
  }
}

export const lookups = (state = {}, { type, ...action }) => {
  const { key, payload = [] } = action;
  switch(type) {
    case 'INIT_LOOKUP':
      return {
        ...state,
        [key]: undefined
      }
    case 'LOAD_LOOKUP':
      return {
        ...state,
        [key]: Array.isArray(payload) ? [ ...payload ] : { ...payload }
      }
    case 'LOOKUP_ERROR':
      return {
        ...state,
        [key]: new Error()
      }
    case 'FLUSH_LOOKUP':
      const newState = {...state};
      delete newState[key];
      return newState;
    default:
      return state;
  }
}

const RECORDS_DEFAULT = {
  total: 0,
  list: []
}

export const records = function(state = RECORDS_DEFAULT, { type, ...action }) {
  switch(type) {
    case 'LOAD_RECORDS':
      return {
        ...state,
        total: action.total,
        list: action.list
      };
    case 'APPEND_RECORDS':
      return {
        ...state,
        total: state?.total + action?.total,
        list: [...state?.list, ...action?.list]
      };
    case 'APPEND_CUSTOM_RECORDS':
      return {
        ...state,
        total: state?.total + action?.total,
        list: [...state?.list, ...action?.list]
      }
    case 'LOAD_CUSTOM_RECORDS':
      return {
        ...state,
        ...action,
      }
    case 'FLUSH_RECORDS':
      return RECORDS_DEFAULT;
    default:
      return state;
  }
}

export const record = function(state = {}, { type, name, data, message }) {
  switch(type) {
    case 'LOAD_RECORD':
      return {...data};
    case 'RECORD_ERROR':
      return new Error(message);
    case 'FLUSH_RECORD':
      return {};
    default:
      return state;
  }
}

export const addendum = function(state = {}, { type, name, data }) {
  switch(type) {
    case 'APPEND_RECORD':
      const newState = {...state};
      if (name !== undefined && data !== undefined) newState[name] = data;
      else if (newState[name]) delete newState[name];
      return newState;
    case 'FLUSH_RECORD':
      return {};
    default:
      return state;
  }
}

export const documents = function(state = {}, { type, name, url, message }) {
  const newState = {...state};
  switch(type) {
    case 'LOAD_FILE':
      newState[name] = url;
      return newState;
    case 'FILE_ERROR':
      newState[name] = new Error(message);
      return newState;
    case 'CLEAR_FILE':
      delete newState[name];
      return newState;
    case 'FLUSH_FILES':
      return {};
    default:
      return state;
  }
}
