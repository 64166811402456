import React, { useMemo } from 'react';

// DEPENENCIES
import { find } from 'lodash';

// GLOBAL VARIABLES
import { USER_ROLES } from 'globals.js';

// LOCAL COMPONENTS
import Badge from '../prototype/Badge';

// MAIN COMPONENT
const UserRoleBadge = ({
  children
}) => {

  // MEMOS
  const {
    variant,
    abbreviation
  } = useMemo(
    () => find(USER_ROLES, ({ label }) => label === children) || {},
    [children]
  )

  // RENDER
  return (
    <Badge
      variant={variant}
      label={abbreviation}
    />
  )
}

// EXPORT
export default UserRoleBadge;
