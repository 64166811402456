import React from 'react';

// BOOTSTRAP COMPONENTS
import { Card } from 'react-bootstrap';

// STYLES
import styles from './cardFooter.module.scss';

// MAIN COMPONENT
const CardFooter = ({
  children
}) => !children ? null : (
  <Card.Footer className={styles.footer}>
    {children}
  </Card.Footer>
)

export default CardFooter;
