import React from 'react';

// DEPENDENCIES
import { compose } from 'redux';
import { connect } from 'react-redux';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';

// GLOBAL FUNCTIONS
import { sessionActions } from 'actions.js';
import { logOut } from 'functions.js';

// CORE COMPONENTS
import Form from 'core/form/Form';

// PARTS
import Page from 'parts/authentication/Page';

// MAIN COMPONENT
const ChooseDealership = compose(
  connect(
    ({ user }) => ({ user }),
    { ...sessionActions }
  )
)(({
  user: {
    stores = []
  },
  setStore
}) => (
  <Page
    documentTitle="Login"
    title="Choose a dealership"
    subtitle="Please choose a dealership you would like to access."
    formArgs={{
      endpoint: ENDPOINTS.session.changeDealership,
      onSuccess: setStore,
      loadingMessage: 'Submitting dealership choice',
      errorMessage: 'Unable to submit dealership choice.'
    }}
  >
    <Form.Body.Vertical>
      <Form.Select
        name="dealer_store_id"
        label="Dealership"
        options={stores.map(({
          make_dealer_id,
          dealer_name
        }) => ({
          label: dealer_name,
          value: make_dealer_id
        }))}
        required
        fullWidth
      />
    </Form.Body.Vertical>
    <Page.Footer>
      <Page.Button.Back
        onClick={() => logOut()}
      />
      <Page.Button.Submit
        label="Choose Dealership"
      />
    </Page.Footer>
  </Page>
))

// EXPORT
export default ChooseDealership
