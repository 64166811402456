import React from 'react';

// CORE COMPONENTS
import Form from 'core/form/Form';

// MAIN COMPONENT
const ApplicationSelect = () => (
  <Form.Select
    name="application_id"
    label="Application Name"
    lookup="application"
    optionKeys={{
      label: 'application_name',
      value: 'application_id'
    }}
    required
  />
)

//EXPORT
export default ApplicationSelect;
