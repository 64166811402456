// LOCAL COMPONENTS
import SessionItem from './parts/SessionItem';
import SessionToggle from './parts/SessionToggle';

// MAIN COMPONENT
const Session = {
  Item: SessionItem,
  Toggle: SessionToggle
}

// EXPORT
export default Session;
