import React from 'react';

// DEPENDENCIES
import { isObject, isString, startCase } from 'lodash';
import queryString from 'query-string';

// GLOBAL VARIABLES
import { PATHNAMES } from 'pathnames.js';

// GLOBAL FUNCTIONS
import { makePath } from 'functions.js';

// TOOLS
import Card from 'tools/card/Card';

// BOOTSTRAP
import { ListGroup } from 'react-bootstrap';

// CORE COMPONENTS
import Button from 'core/tools/Button'
import Sprite from 'core/tools/Sprite';

// STYLES
import styles from './dashboardCard.module.scss';

// MAIN COMPONENT
const DashboardCard = ({
  children,
  title,
  icon
}) => (
  <Card>
    <Card.Header>
      <Card.Title>
        <Sprite
          className={styles.icon}
          use={icon}
        />
        {title}
      </Card.Title>
    </Card.Header>
    <Card.Body>
      <ListGroup variant="flush">
        {children.map((child, i) => {
          const label = isString(child) ? startCase(child) : child.label || startCase(child.routeKey);
          let to = makePath(PATHNAMES[isString(child) ? child : child.routeKey]);
          if (isObject(child) && child.search) to += '?' + queryString.stringify(child.search);
          return (
            <ListGroup.Item
              key={i}
              className={styles.item}
              as={Button.Link}
              to={to}
            >
              <Sprite
                className={styles.icon}
                use="circle"
                size="xs"
              />
              {label}
            </ListGroup.Item>
          )
        })}
      </ListGroup>
    </Card.Body>
  </Card>
)

// EXPORT
export default DashboardCard
