import React from 'react';

// HELPERS
import { documentTitle } from 'helpers/documentTitle';

// BOOTSTRAP
import { Col, Row } from 'react-bootstrap';

// LOCAL COMPONENTS
import DashboardCard from './parts/DashboardCard';

// STYLES
import styles from './dashboard.module.scss';

// MAIN COMPONENTS
const Dashboard = {
  Container:  documentTitle(({ children }) => <>{children}</>),
  Row:        props => <Row className={styles.row} {...props} />,
  Col:        props => <Col className={styles.col} xs={24} md={12} {...props} />,
  Card:       DashboardCard
}

// EXPORT
export default Dashboard;
