import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { get } from 'lodash';

// GLOBAL VARIABLES
import { YES_NO } from 'defaults.js';

// GLOBAL FUNCTIONS
import { doCallback } from 'functions.js';

// LOCAL COMPONENTS
import Checklist from './checklist/Checklist';
import Toggle from './toggle/Toggle';

// STYLES
import styles from './yesNo.module.scss';

// MAIN COMPONENT
const YesNo = ({
  className,
  numeric = YES_NO.useNumeric,
  toggle  = YES_NO.useToggle,
  group = YES_NO.group,
  type,
  text,
  options: [
    yes,
    no,
    ...other
  ] = [],
  onValueChange,
  requiredTrue,
  disclaimer,
  required = requiredTrue || disclaimer,
  schema = requiredTrue ? 'true' : disclaimer ? 'disclaimer' : undefined,
  ...props
}) => {

  // CONTEXT
  const { values, setFieldValue } = useFormikContext() || {};

  // PROPS
  const { name } = props;

  // STATE
  const [ active, setActive ] = useState(false);

  // REFS
  const valueRef = useRef();

  // MEMOS
  const options = useMemo(
    () => ([
      {
        label: 'Yes',
        value: numeric ? 1 : true,
        ...(yes || {})
      },
      {
        label: 'No',
        value: numeric ? 0 : false,
        ...(no || {})
      },
      ...other
    ]),
    [numeric, yes, no, other]
  )
  const value = useMemo(
    () => get(values, name),
    [values, name]
  )

  // CALLBACKS
  const handleValueChange = useCallback(
    response => {
      const { value } = response;
      doCallback(onValueChange, response);
      setActive(value === options[0].value);
    },
    [options, onValueChange, setActive]
  )
  const handleCheck = useCallback(
    e => {
      const { checked } = e.currentTarget;
      const value = checked ? options[0].value : options[1].value;
      handleValueChange({name, value});
      setFieldValue(name, value);
    },
    [name, options, setFieldValue, handleValueChange]
  )

  // EFFECTS
  useEffect(
    () => {
      if (value === valueRef.current) return;
      valueRef.current = value; 
      handleValueChange({name, value});
    },
    [name, value, valueRef, handleValueChange]
  )

  // RENDER
  return type === 'checkbox' ? (
    <Checklist.Checkbox
      className={clsx(
        styles.checkbox,
        props.disabled && styles.disabled
      )}
      type="checkbox"
      checkbox={{
        value: options[0],
        label: text,
        checked: active,
        onChange: handleCheck
      }}
      schema={schema}
      required={required}
      {...props}
    />
  ) : toggle ? (
    <Toggle
      className={clsx(
        styles.toggle,
        props.inline && styles.inline,
        group && styles.group,
        active && styles.active,
        className
      )}
      options={options}
      onValueChange={handleValueChange}
      group={group}
      schema={schema}
      required={required}
      {...props}
    />
  ) : (
    <Checklist
      className={clsx(
        styles.checklist,
        active && styles.active,
        className
      )}
      type="radio"
      options={options}
      onValueChange={handleValueChange}
      list={{
        direction: 'horizontal',
        justify: 'start'
      }}
      schema={schema}
      required={required}
      {...props}
    />
  )
}

// EXPORT
export default YesNo;
