import React, { forwardRef, useMemo } from 'react';

// DEPENDENCIES
import queryString from 'query-string';
import scriptLoader from 'react-async-script-loader';
import { connect } from 'react-redux';
import { compose } from 'redux';

// MAIN COMPONENT
export const googleMapsAPI = Component => compose(
  connect(
    ({ configuration }) => ({ configuration })
  ),
  forwardRef
)(({
  // REDUX STATE
  configuration: {
    googleAPIKey
  },
  // REST
  ...props
}, ref) => {

  // GOOGLE API
  const apiURL = useMemo(
    () => {
      if (!googleAPIKey) return;
      const params = {
        key:       googleAPIKey,
        libraries: 'places',
//      callback:  'initAutocomplete' // This param was specified by Google documentation but returns an error and does not appear to be necessary.
      };
      return `https://maps.googleapis.com/maps/api/js?${queryString.stringify(params)}`;
    },
    [googleAPIKey]
  )
  const WrappedComponent = useMemo(
    () => !apiURL ? Component : scriptLoader(apiURL)(Component),
    [apiURL]
  )

  // RENDER
  return <WrappedComponent {...props} ref={ref} />
})
