import React, { useCallback } from 'react';

// DEPENDENCIES
import { compose } from 'redux';
import { connect } from 'react-redux';
import {debounce, isEmpty, isEqual} from 'lodash';

// GLOBAL FUNCTIONS
import { filterActions } from 'actions.js';
import { getPathType } from 'functions.js';

// CORE FUNCTIONS
import Form from 'core/form/Form';

// MAIN COMPONENT
const DebouncedSearch = compose(
    connect(
        ({ filters }) => ({ filters}),
        { ...filterActions }
    )
)(({
       name = 'searchKeyword',
       label = 'Keyword Search',
       placeholder = 'Enter Keyword',
       htmlSize,
       update,
       filters,
       // REDUX DISPATCH
       addFilters,
       clearFilters,
       updateFilter,
       clearFilter,
       // REST
       ...props
   }) => {

    // CALLBACKS
    const debouncedHandleInput = useCallback(debounce((e) => {
        const { value, name } = e.target;
        let currentValue = '';

        if (filters[getPathType()]?.hasOwnProperty(name)) {
            currentValue = filters[getPathType()][name];
        }

        if ((value.length >= 2 || isEmpty(value)) && !isEqual(currentValue, value)) {
            addFilters(getPathType(), {
                [name]: value
            });
        }
    }, 500), [addFilters, filters])

    const handleClear = useCallback(
        () => {
            clearFilter(getPathType(), name)
        }, [name, clearFilter])

    // RENDER
    return (
        <Form.Control
            name={name}
            label={props.size === 'sm' ? undefined : label}
            placeholder={props.size === 'sm' ? 'Quick Search' : placeholder}
            prepend={props.size !== 'sm' ? undefined : {
                use: 'search'
            }}
            onChange={debouncedHandleInput}
            onClear={handleClear}
            clearButton
            {...props}
        />
    )
})

// EXPORT
export default DebouncedSearch;
