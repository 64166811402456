import React, { useCallback, useContext } from 'react';

// DEPENDENCIES
import clsx from 'clsx';

// GLOBAL FUNCTIONS
import { doCallback } from 'functions.js';

// CONTEXT
import { SessionContext } from '../../helpers/sessionContext';
import { CollapseContext } from 'core/tools/collapse/helpers/collapseContext';
import { DropdownContext } from 'core/tools/dropdown/helpers/dropdownContext';

// CORE COMPONENTS
import Button from 'core/tools/Button';

// STYLES
import styles from './sessionItem.module.scss';

// MAIN COMPONENT
const SessionItem = ({
  as: MenuItem = Button,
  label,
  value,
  active
}) => {

  // CONTEXT
  const { onSelect } = useContext(SessionContext);
  const { setOpen } = useContext(CollapseContext) || {};
  const { setShow } = useContext(DropdownContext) || {};

  // CALLBACKS
  const handleClick = useCallback(
    () => {
      onSelect(value);
      doCallback(setOpen, false);
      doCallback(setShow, false);
    },
    [value, onSelect, setOpen, setShow]
  )

  // RENDER
  return (
    <MenuItem
      className={clsx(
        styles.item,
        active && ['active', styles.active]
      )}
      variant="custom"
      label={label}
      icon={active ? 'check' : undefined}
      onClick={!active ? handleClick : undefined}
      justify="start"
      round={false}
      fullWidth
    />
  )
}

export default SessionItem
