import React from 'react';

// CORE COMPONENTS
import Form from 'core/form/Form';

// PARTS
import Record from 'parts/interface/record/Record';

// TOOLS
import NewUsed from 'tools/form/NewUsed';
import YesNoNA from 'tools/form/YesNoNA';

// PROTOTYPE
import EditPrototype from '../prototype/EditPrototype';

// MAIN COMPONENT
const RequestPages = () => (
  <EditPrototype
    routeKey="requestPage"

  >
    <Form.Col>
      <Record.Card.Subtitle>Request Property</Record.Card.Subtitle>
    </Form.Col>
    <Form.Control
      name="page_title"
      label="Page Title"
    />
    <Form.Control
      name="page_file_name"
      label="File Name"
      required
    />
    <Form.Control
      name="request_action_key"
      label="Request Action Key"
    />
    <Form.YesNo
      name="home_page"
      label="Home Page?"
      required
    />
    <Form.YesNo
      name="mobile_page"
      label="Mobile Page?"
      required
    />
    <Record.Card.Divider />
    <Form.Col>
      <Record.Card.Subtitle>Request Condition</Record.Card.Subtitle>
    </Form.Col>
    <NewUsed
      label="Apply to New or Used Vehicle?"
    />
    <YesNoNA
      name="special_flag"
      label="Apply for Marked as Special?"
    />
    <YesNoNA
      name="model_summary_flag"
      label="Show Models?"
    />
    <Form.YesNo
      name="archive_flag"
      label="Archive?"
      required
    />
  </EditPrototype>
)

export default RequestPages;

