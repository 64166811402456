import React from 'react';

// BOOTSTRAP COMPONENTS
import { Card } from 'react-bootstrap';

// STYLES
import styles from './cardBody.module.scss';

// MAIN COMPONENT
const CardBody = ({
  children
}) => !children ? null : (
  <Card.Body className={styles.body}>
    {children}
  </Card.Body>
)

export default CardBody
