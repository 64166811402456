import React, { createContext, forwardRef, useCallback, useState } from 'react';

// DEPENDENCIES
import { capitalize } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

// GLOBAL FUNCTIONS
import { apiFetch, makePath } from 'functions.js';

// CONTEXT EXPORT
export const SessionContext = createContext(null);

// MAIN COMPONENT
export const sessionContext = ({
  label,
  endpoint,
  param,
  onSuccess,
  isLogin = false,
  debug
}) => Component => {
  return compose(
    connect(
      null,
      { handleSuccess: onSuccess }
    ),
    withRouter,
    forwardRef
  )(({
    // REDUX DISPATCH
    handleSuccess,
    // REACT ROUTER
    history,
    location,
    match,
    staticContext,
    // REST
    ...props
  }, ref) => {

    // FETCH STATE
    const [ fetching, setFetching ] = useState(false);

    // SELECTION HANDLER
    const onSelect = useCallback(
      id => {

        apiFetch({
          method: 'POST',
          endpoint,
          params: {
            [param]: id
          },
          onFetch: () => {
            setFetching(true)
          },
          onResponse: () => {
            setFetching(false)
          },
          onSuccess: response => {
            history.push(makePath('/'))
            handleSuccess(response)
          },
          loadingMessage: `Switching ${label.toLowerCase()}`,
          successMessage: `${capitalize(label)} switched.`,
          errorMessage: `Unable to switch ${label.toLowerCase()}.`,
          debug,
          isLogin
        })
      },
      [history, handleSuccess, setFetching]
    )

    // RENDER
    return (
      <SessionContext.Provider value={{
        onSelect,
        fetching
      }}>
        <Component {...props} ref={ref} />
      </SessionContext.Provider>
    )
  })
}
