import React from 'react';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';

// FUNCTIONS
import { formatDate } from "functions";

// TOOLS
import Badge from 'tools/badge/Badge';

// PARTS
import List from 'parts/interface/list/List';
const { Action, Button, Col, Tool } = List;

// ARGS
const ARGS = {
  label:    'dealership',
  idKey:    'dealer_id',
  endpoint: ENDPOINTS.record.dealership.list
}

// HEADER
const BUTTONS = [ <Button.Add /> ]

// TOOLBAR
const TOOLS = [ <Tool.ResultCount /> ]

// COLUMNS
const COLUMNS = ({
  name,
  address1,
  address2,
  city,
  state,
  zip_code,
  status,
  dealer_group_name,
  date_added
}) => [
  <Col
    cols={{ lg: 5 }}
    label="Dealership Name"
    value={ name }
  />,
  <Col
    cols={{ lg: 4 }}
    label="Address"
    value={ `${address1} ${address2}` }
  />,
  <Col
    cols={{ lg: 3 }}
    label="City"
    value={ city }
  />,
  <Col
    cols={{ lg: 2 }}
    label="State"
    value={ state }
  />,
  <Col
    cols={{ lg: 2 }}
    label="ZIP Code"
    value={ zip_code }
  />,
  <Col
      cols={{ lg: 4 }}
      label="Dealership Group"
      value={ dealer_group_name }
  />,
  <Col
    cols={{ lg: 2 }}
    label="Active?"
    as={ Badge.Active }
    value={ !!status }
  />,
  <Col
    cols={{ lg: 2 }}
    label="Date Added"
    value={formatDate(date_added)}
  />
]

// ACTIONS
const ACTIONS = () => [
  <Action.Edit />
]

// MAIN COMPONENT
const Dealerships = () => <List
    title="Dealerships"
    args={ARGS}
    buttons={BUTTONS}
    tools={TOOLS}
    columns={COLUMNS}
    actions={ACTIONS}
  />

export default Dealerships
