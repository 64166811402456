import React from 'react';

// DEPENDENCIES
import clsx from 'clsx';

// GLOBAL VARIABLES
import { PATHNAMES } from 'pathnames.js';

// REACT ROUTER PROPS
import { NavLink } from "react-router-dom";

// CORE COMPONENTS
import Sprite from 'core/tools/Sprite';

// STYLES
import styles from './menuItem.module.scss';
import { makePath } from 'functions';

// MAIN COMPONENT
const MenuItem = ({
  children,
  className,
  linkClassName,
  label,
  icon,
  before = (
    <Sprite
      className={styles.icon}
      use={icon}
      size="lg"
    />
  ),
  after,
  href,
  pathkey,
  to = PATHNAMES[pathkey],
  target,
  exact,
  onClick,
  as: Link = href ? 'a' : to ? NavLink : 'button',
  button
}) => (
  <div className={clsx(
    'nav-item',
    styles.item,
    button && styles.button,
    className
  )}>
    <Link
      className={clsx(
        'nav-link',
        styles.link,
        linkClassName
      )}
      href={href}
      to={makePath(to)}
      target={target}
      exact={exact}
      onClick={onClick}
    >
      {before}
      <span className={clsx(
        'menu-label',
        styles.label
      )}>
        {label}
      </span>
      {after}
    </Link>
    {children}
  </div>
)

// EXPORT
export default MenuItem;
