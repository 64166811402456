import React from 'react';

// GLOBAL VARIABLES
import { PATHNAMES } from 'pathnames.js';

// GLOBAL FUNCTIONS
import { logOut, makePath } from 'functions.js';

// LOCAL COMPONENTS
import Menu from './prototype/Menu';

// STYLES
import styles from './parts/menuItem.module.scss';

// MAIN COMPONENT
const UserMenu = ({
  as: Item = Menu.Item
}) => (<>
  <Item
    key="myAccount"
    label="My Account"
    icon="user"
    to={makePath(PATHNAMES.user, PATHNAMES.profile)}
  />
  <Item
    key="changePassword"
    label="Change Password"
    icon="lock"
    to={makePath(PATHNAMES.user, PATHNAMES.changePassword)}
  />
  <Item
    key="logout"
    className={styles.button}
    label="Log Out"
    icon="power-off"
    onClick={() => logOut()}
  />
</>)

// EXPORT
export default UserMenu;
