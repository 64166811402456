import React from 'react';

// CORE COMPONENTS
import Form from 'core/form/Form';

// PROTOTYPE
import EditPrototype from '../prototype/EditPrototype';

// TOOLS
import ApplicationSelect from 'tools/form/ApplicationSelect';
import CharKey from 'tools/form/CharKey';

// MAIN COMPONENT
const ApplicationConfiguration = () => (
  <EditPrototype
    routeKey="applicationConfiguration"
  >
    <ApplicationSelect />
    <Form.Control
      name="config_name"
      label="Configuration Name"
      required
    />
    <CharKey
      name="config_key"
      label="Configuration Key"
      schema={null}
    />
    <Form.Control
      name="config_value"
      label="Configuration Value"
      required
    />
  </EditPrototype>
)

export default ApplicationConfiguration;

