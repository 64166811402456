import React, { useCallback, useContext } from 'react';

// DEPENDENCIES
import { snakeCase } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';

// GLOBAL FUNCTIONS
import { fileBlob, fileDownload, fileFetch, format, getPathType, makePath } from 'functions.js';

// CONTEXT
import { ListContext } from '../../../helpers/getListData';

// CORE COMPONENTS
import Button from 'core/tools/Button';

import * as styles from './downloadButton.module.scss';

// MAIN COMPONENT
const DownloadButton = compose(
  connect(
    ({ mobile }) => ({ mobile })
  )
)(({
  className,
  // REDUX STATE
  mobile,
  // REST
  ...props
}) => {

  // CONTEXT
  const { endpoint, params } = useContext(ListContext) || {};

  // CALLBACKS
  const handleClick = useCallback(
    () => {
      fileFetch({
        endpoint: makePath(props.endpoint || endpoint, 'download-excel'),
        accept: false,
        contentType: 'application/json',
        params: {
          ...params,
          downloadExcel: true
        },
        loadingMessage: 'Downloading file...',
        errorMessage: 'Unable to download file.',
        onSuccess: file => {
          fileDownload(fileBlob(file, false), `${snakeCase(getPathType())}_${format.date(new Date(), 4, 0, '-')}.xlsx`);
        },
      })
    },
    [props.endpoint, endpoint, params]
  )

  // RENDER
  return mobile ? null : (
    <Button
      className={className}
      label="Download List"
      icon="download"
      onClick={handleClick}
      {...props}
    />
  )
})

// EXPORT
export default DownloadButton
