import React, { forwardRef, useMemo } from 'react';

// DEPENDENCIES
import { pick } from 'lodash';

// GLOBAL FUNCTIONS
import { format } from 'functions.js';
import { TODAY } from "globals.js";

// LOCAL VARIABLES
export const DATE_OPTIONS = {
  custom: {
    label: 'Custom'
  },
  today: {
    label: 'Today',
    date: format.date(TODAY)
  },
  yesterday: {
    label: 'Yesterday',
    date: format.date(new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate() - 1))
  },
  thisWeek: {
    label: 'This Week',
    startDate: format.date(new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate() - TODAY.getDay())),
    endDate:   format.date(new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate() - TODAY.getDay() + 6))
  },
  lastWeek: {
    label: 'Last Week',
    startDate: format.date(new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate() - TODAY.getDay() - 7)),
    endDate:   format.date(new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate() - TODAY.getDay() - 1))
  },
  thisMonth: {
    label: 'This Month',
    startDate: format.date(new Date(TODAY.getFullYear(), TODAY.getMonth(), 1)),
    endDate:   format.date(new Date(TODAY.getFullYear(), TODAY.getMonth() + 1, 0)),
  },
  lastMonth: {
    label: 'Last Month',
    startDate: format.date(new Date(TODAY.getFullYear(), TODAY.getMonth() - 1, 1)),
    endDate:   format.date(new Date(TODAY.getFullYear(), TODAY.getMonth(), 0))
  },
  thisQuarter: {
    label: 'This Quarter',
    startDate: format.date(new Date(TODAY.getFullYear(), Math.floor(TODAY.getMonth()/3) * 3, 1)),
    endDate:   format.date(new Date(TODAY.getFullYear(), Math.floor(TODAY.getMonth()/3) * 3 + 3, 0))
  },
  lastQuarter: {
    label: 'Last Quarter',
    startDate: format.date(new Date(TODAY.getFullYear(), Math.floor(TODAY.getMonth()/3) * 3 - 3, 1)),
    endDate:   format.date(new Date(TODAY.getFullYear(), Math.floor(TODAY.getMonth()/3) * 3, 0))
  },
  thisYear: {
    label: 'This Year',
    startDate: format.date(new Date(TODAY.getFullYear(), 0, 1)),
    endDate:   format.date(new Date(TODAY.getFullYear() + 1, 0, 0))
  },
  lastYear: {
    label: 'Last Year',
    startDate: format.date(new Date(TODAY.getFullYear() - 1, 0, 1)),
    endDate:   format.date(new Date(TODAY.getFullYear(), 0, 0))
  }
}

// MAIN COMPONENT
export const dateOptions = Component => {
  return forwardRef((props, ref) => {

    // PROPS
    const {
      range
    } = props;

    // MEMOS
    const dateOptions = useMemo(
      () => range ? DATE_OPTIONS : pick(DATE_OPTIONS, ['custom', 'today', 'yesterday']),
      [range]
    )
    const selectOptions = useMemo(
      () => Object.keys(dateOptions).map(key => ({
        label: dateOptions[key].label,
        value: key
      })),
      [dateOptions]
    )

    // RENDER
    return <Component
      {...props}
      dateOptions={dateOptions}
      selectOptions={selectOptions}
      ref={ref}
    />
  })
}
