import React from 'react';

// TOOLS
import Badge from 'tools/badge/Badge';

// PROTOTYPE
import ListPrototype from '../prototype/ListPrototype';

// PARTS
import List from 'parts/interface/list/List';
const { Col } = List;

// COLUMNS
const COLUMNS = ({
  site_domain_name,
  website_name,
  official_dealer_name,
  dealer_name,
  make_name,
  parts_store_online,
  active_flag,
  archive_flag
}) => [
  <Col
    cols={{
      lg: 4
    }}
    label="Domain Name"
    value={site_domain_name}
  />,
  <Col
    cols={{
      lg: 4
    }}
    label="Web Site Name"
    value={website_name}
  />,
  <Col
    cols={{
      lg: 4
    }}
    label="Organization Name"
    value={official_dealer_name}
  />,
  <Col
    cols={{
      lg: 4
    }}
    label="Dealership"
    value={dealer_name}
  />,
  <Col
    cols={{
      lg: 2
    }}
    label="Vehicle Make"
    value={make_name}
  />,
  <Col
    cols={{
      lg: 2
    }}
    as={Badge.YesNo}
    label="Online Parts Store?"
    value={parts_store_online}
  />,
  <Col
    cols={{
      lg: 2
    }}
    as={Badge.YesNo}
    label="Active?"
    value={active_flag}
  />,
  <Col
    cols={{
      lg: 2
    }}
    as={Badge.YesNo}
    label="Archive?"
    value={archive_flag}
  />
]

// MAIN COMPONENT
const WebsiteGeneralInfo = () => (
  <ListPrototype
    routeKey="websiteGeneralInfo"
    idKey="make_dealer_id"
    label="website"
    title="Website General Info"
    columns={COLUMNS}
  />
)

export default WebsiteGeneralInfo;
