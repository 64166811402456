// ROUTES
import applications from './types/Applications';
import applicationConfiguration from './types/ApplicationConfiguration';
import applicationRoles from './types/ApplicationRoles';
import dealerships from './types/Dealerships';
import dealershipHours from './types/DealershipHours';
import organizationTitles from './types/OrganizationTitles';
import requestPages from './types/RequestPages';
import seoMetadata from './types/SEOMetadata';
import serviceTransportation from './types/ServiceTransportation';
import leadForms from './types/LeadForms';
import websiteGeneralInfo from './types/WebsiteGeneralnfo';
import leadRouting from './types/LeadRouting';
import users from './types/Users';
import leadFormCategory from './types/LeadFormCategory';
import dealershipGroups from "./types/DealershipGroups";


// MAIN COMPONENT
const List = {
  applications,
  applicationConfiguration,
  applicationRoles,
  dealerships,
  dealershipHours,
  organizationTitles,
  requestPages,
  seoMetadata,
  serviceTransportation,
  leadForms,
  websiteGeneralInfo,
  leadRouting,
  users,
  leadFormCategory,
  dealershipGroups
}

// EXPORT
export default List;
