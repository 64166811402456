import React from 'react';

// GLOBAL VARIABLES
import { COPYRIGHT, VERSION } from 'globals.js';

// DEPENDENCIES
import clsx from 'clsx';

// STYLES
import styles from './pageFooter.module.scss';

// MAIN COMPONENT
const PageFooter = () => (
  <div className={clsx(
    'page-footer',
    styles.footer
  )}>
    <p>v{VERSION}</p>
    <p>©{new Date().getFullYear()}&nbsp;{COPYRIGHT}</p>
  </div>
)

// EXPORT
export default PageFooter;
