import React from 'react';

// GLOBAL VARIABLES
import { PATHNAMES } from 'pathnames.js';

// GLOBAL FUNCTIONS
import { makePath } from 'functions.js';

// HELPERS
import { checkRouting } from 'parts/authentication/helpers/checkRouting';

// ROUTER COMPONENTS
import { Route, Switch } from "react-router-dom";

// SCREENS
import ChooseDealership from './routes/ChooseDealership';
import EnterCode from './routes/EnterCode';
import Login from './routes/Login';
//import NewPassword from './routes/NewPassword';
import RequestCode from './routes/RequestCode';
import ResetPassword from './routes/ResetPassword';
import NewPassword from './routes/NewPassword';

// MAIN COMPONENT
const Authentication = () => (
  <Switch>
    <Route
      path={makePath(PATHNAMES.login)}
      component={Login}
    />
    <Route
      path={makePath(PATHNAMES.requestCode)}
      component={RequestCode}
    />
    <Route
      path={makePath(PATHNAMES.enterCode)}
      component={EnterCode}
    />
    <Route
      path={makePath(PATHNAMES.chooseDealership)}
      component={ChooseDealership}
    />
    <Route
      path={makePath(PATHNAMES.resetPassword, ':token')}
      component={NewPassword}
    />
    <Route
      path={makePath(PATHNAMES.resetPassword)}
      component={ResetPassword}
    />
  </Switch>
)

// EXPORT
export default checkRouting(Authentication);
