import React, { cloneElement, useContext } from 'react';

// DEPENDENCIES
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

// CONTEXT
import { ListContext } from '../../helpers/getListData';

// CORE COMOPNENTS
import Form from 'core/form/Form';

// STYLES
import styles from './listToolbar.module.scss';

// MAIN COMPONENT
const ListToolbar = compose(
  withRouter
)(({
  tools = [],
  filters,
  // REACT ROUTER
  history,
  location
}) => {

  // CONTEXT
  const { params } = useContext(ListContext) || {};

  // RENDER
  return !tools.length ? null : (
    <div className={styles.toolbar}>
      {/*
        <Filters
          filters={filters}
        />
      */}
      <Form
        initialValues={params}
      >
        <Form.Body.Inline size="sm">
          {tools.map((tool, i) => cloneElement(tool, {
            key: i,
            update: true,
            htmlSize: 12
          }))}
        </Form.Body.Inline>
      </Form>
    </div>
  )
})

// CHILD COMPONENTS
ListToolbar.Break = () => <div className={styles.break} />;
ListToolbar.Divider = () => <div className={styles.divider} />;
ListToolbar.Spacer = () => <div className={styles.spacer} />;

// EXPORT
export default ListToolbar;
