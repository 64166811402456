import React from 'react';

// CORE COMPONENTS
import Form from 'core/form/Form';

// LOCAL COMPONENTS
import ConfirmationModal from './ConfirmationModal';

// MAIN COMPONENT
const FormModal = ({
  body,
  args = {},
  fields,
  submitButton,
  ...props
}) => (
  <ConfirmationModal
    form={args}
    body={(<>
      {body}
      <Form.Body
        vertical
      >
        {fields}
      </Form.Body>
    </>)}
    continueButton={{
      type: 'submit',
      label: "Submit",
      ...submitButton
    }}
    {...props}
  />
)

// EXPORT
export default FormModal;
