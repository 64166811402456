import React from 'react';

// GLOBAL FUNCTIONS
import { isPermitted } from 'functions.js';

// MAIN COMPONENT
export const checkPermission = Component => ({
  permission,
  ...props
}) => !!permission && !isPermitted(permission) ? null : <Component {...props} />
