import React from 'react';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';

// PARTS
import List from 'parts/interface/list/List';
const { Action, Button, Col, Tool } = List;

// ARGS
const ARGS = {
  label:    'service-transportation',
  idKey:    'service_transportation_options_id',
  endpoint: ENDPOINTS.record.serviceTransportation.list
}

// HEADER
const BUTTONS = [ <Button.Add /> ]

// TOOLBAR
const TOOLS = [ <Tool.ResultCount /> ]

// COLUMNS
const COLUMNS = ({
    transportation_option,
    service_transportation_options_id
}) => [
  <Col
    cols={{ lg: 5 }}
    label="#"
    value={ service_transportation_options_id }
  />,
  <Col
    cols={{ lg: 6 }}
    label="Transportation Option"
    value={ transportation_option }
  />
]

// ACTIONS
const ACTIONS = () => [
  <Action.Edit />
]

// MAIN COMPONENT
const ServiceTransportation = () => <List
    title="Service Transportation"
    args={ARGS}
    buttons={BUTTONS}
    tools={TOOLS}
    columns={COLUMNS}
    actions={ACTIONS}
  />

export default ServiceTransportation
