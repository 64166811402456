import React, { forwardRef, useCallback, useState } from 'react';

// GLOBAL FUNCTIONS
import { doCallback, format } from 'functions.js';

// MAIN COMPONENT
export const dateFormat = Component => {
  return forwardRef((props, ref) => {

    const [ changed, setChanged ] = useState(false);

    // PROPS
    const {
      name,
      form: {
        setFieldValue
      } = {},
      onBlur,
      onChange,
      onDateChange
    } = props;

    // CALLBACKS
    const handleChange = useCallback(
      e => {
        doCallback(onChange, e);
        setChanged(true);
      },
      [onChange, setChanged]
    )
    const handleBlur = useCallback(
      e => {
        setChanged(false);
        if (!changed) return;
        const formattedDate = format.date(e.target.value);
        doCallback(onBlur, e);
        if (!formattedDate) return;
        doCallback(onDateChange, formattedDate);
        setFieldValue(name, formattedDate);
      },
      [name, changed, setChanged, onBlur, onDateChange, setFieldValue]
    )

    // RENDER
    return <Component
      {...props}
      onBlur={handleBlur}
      onChange={handleChange}
      ref={ref}
    />
  })
}
