import React, { forwardRef, useCallback, useEffect, useMemo } from 'react';

// DEPENDENCIES
import { get, identity, isString } from 'lodash';

// GLOBAL VARIABLES
import { FORM_CONTROL } from 'defaults.js';

// GLOBAL FUNCTIONS
import { bugLog, doCallback, valueLog } from 'functions.js';

// MAIN COMPONENT
export const inputState = Component => forwardRef(({
  value: defaultValue,
  formatValue = identity,
  onValueChange,
  ...props
}, ref) => {

  // PROPS
  let {
    name,
    disabled,
    clearDisabled = FORM_CONTROL.clearDisabled,
    debug,
    form: {
      values,
      errors,
      initialValues,
      setFieldValue,
      setFieldError,
      validateField
    } = {},
    meta: {
      onValidate
    } = {}
  } = props;

  // MEMOS
  const value = useMemo(
    () => {
      const value = get(values, name);
      return formatValue(value !== null && value !== undefined ? value : defaultValue)
    },
    [name, values, defaultValue, formatValue]
  )
  const error = useMemo(
    () => get(errors, name),
    [name, errors]
  )

  // CALLBACKS
  const initValue = useCallback(
    () => {
      if (!isString(name)) return;
      valueLog('init value', debug, name, value);
      doCallback(setFieldValue, name, value);
    },
    [name, value, debug, setFieldValue]
  )
  const clearValue = useCallback(
    () => {
      if (!isString(name)) return;
      bugLog('clear value', debug, name);
      doCallback(setFieldValue, name, undefined);
    },
    [name, debug, setFieldValue]
  )
  const clearErrors = useCallback(
    () => {
      if (!isString(name)) return;
      bugLog('clear errors', debug, name);
      doCallback(setFieldError, name, undefined);
    },
    [name, debug, setFieldError]
  )

  // LISTENERS
  useEffect(
    () => {
      initValue();
      return () => {
        clearValue();
        clearErrors();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues]
  )
  useEffect(
    () => {
      valueLog('value', debug, name, value);
      doCallback(onValueChange, {name, value});
      doCallback(setFieldValue, name, value);
      doCallback(validateField, name);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  )
  useEffect(
    () => {
      if (disabled && clearDisabled) clearValue();
      if (disabled) clearErrors();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disabled, clearDisabled]
  )
  useEffect(
    () => {
      if (!name || !error) return;
      const promise = doCallback(onValidate, value);
      if (promise) promise.then(error => setFieldError(name, error));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, value, error, onValidate]
  )

  // RENDER
  return <Component {...props} value={value} ref={ref} />
})
