import React from 'react';

// LOCAL COMPONENTS
import SelectFilter from './SelectFilter';


const FILTER_OPTIONS = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Active',
    value: true
  },
  {
    label: 'Inactive',
    value: false
  }
]

// MAIN COMPONENT
const ActiveFilter = ({
  name="activeStatus",
  label="Status",
  placeholder="Select active status",
  ...props
}) => (
  <SelectFilter
    name={name}
    label={label}
    placeholder={placeholder}
    options={FILTER_OPTIONS}
    {...props}
  />
)

// EXPORT
export default ActiveFilter
