import React, { useContext } from 'react';

// DEPENDENCIES
import { isFunction } from 'lodash';
import { compose } from 'redux';

// HELPERS
import { documentTitle } from 'helpers/documentTitle';

// CONTEXT
import { getRecordData, RecordContext } from 'parts/interface/helpers/getRecordData.js';

// PARTS
import Page from '../page/Page';

// TOOLS
import Card from 'tools/card/Card';

// PARTS
import EditCard from 'parts/interface/cards/EditCard';

// LOCAL TOOLS
import RecordToggle from './tools/RecordToggle';

// LOCAL PARTS
import RecordFooter from './parts/RecordFooter';
import RecordForm from './parts/RecordForm';

// MAIN COMPONENT
const Record = compose(
  documentTitle,
  getRecordData
)(({
  children
}) => {

  // CONTEXT
  const recordContext = useContext(RecordContext) || {};

  // RENDER
  return (<>
    {isFunction(children) ? children(recordContext) : children}
    <Page.Footer />
  </>);
})

// CHILD COMPONENTS
Record.Form      = RecordForm;
Record.Footer    = RecordFooter;
Record.Toggle    = RecordToggle;
Record.Card      = Card;
Record.Card.Edit = EditCard;

export default Record
