import React, { forwardRef, useMemo } from 'react';

// DEPENDENCIES
import { isObject, isString } from 'lodash';
import * as yup from 'yup';

// LOCAL COMPONENTS
import Confirmation from '../../parts/Confirmation';

// MAIN COMPONENT
export const inputConfirmation = Component => {
  return forwardRef(({
    value,
    confirmed,
    required,
    debug,
    useConfirmation = !!confirmed,
    confirmationProps = isObject(confirmed) ? confirmed : {},
    ...props
  }, ref) => {

    // MEMOS
    const label = useMemo(
      () => isObject(props.label) ? ({
        ...props.label,
        title: props.label.title ? `Confirm ${props.label.title}` : undefined
      }) : isString(props.label) ? `Confirm ${props.label}` : undefined,
      [props.label]
    )
    const validationSchema = useMemo(
      () => {
        if (!useConfirmation || value === undefined || value === null) return;
        return !value ? undefined : yup.string().required('Please confirm.').matches(value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), `Confirmation does not match`);
      },
      [value, useConfirmation]
    )

    // RENDER
    return (<>
      <Component
        {...props}
        value={value}
        required={required}
        debug={debug}
        ref={ref}
      />
      {useConfirmation &&
        <Confirmation
          {...props}
          name={`confirmation.${props.name}`}
          label={label}
          validationSchema={validationSchema}
          {...confirmationProps}
        />
      }
    </>)
  })
}
