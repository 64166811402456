import React, { useMemo } from 'react';

// DEPENDENCIES
import * as yup from 'yup';

// GLOBAL VARIABLES
import { DOLLARS } from 'defaults.js';
import { NUMBER_FORMATS } from 'numberFormats.js';

// GLOBAL FUNCTIONS
import { format } from 'functions.js';

// LOCAL COMPONENTS
import Control from '../Control';

// MAIN COMPONENT
const Dollar = ({
  min,
  max,
  useCents = DOLLARS.useCents,
  allowNegative = DOLLARS.allowNegative,
  numberFormat = {},
  ...props
}) => {

  // MEMOS
  const validationSchema = useMemo(
    () => yup.number().transform(value => {
      const float = parseFloat(value);
      return isNaN(float) ? undefined : float;
    })
    .nullable()
    .when('$min', (_value, schema) =>
      min !== undefined && !isNaN(min)
      ? schema.min(min, `Must be no less than ${format.dollar(min)}.`)
      : schema,
    )
    .when('$max', (_value, schema) =>
      max !== undefined && !isNaN(max)
      ? schema.max(max, `Must be no more than ${format.dollar(max)}.`)
      : schema,
    ),
    [min, max]
  )

  // RENDER
  return (
    <Control
      {...props}
      schema="dollars"
      numberFormat={{
        decimalScale: useCents ? 2 : 0,
        placeholder: useCents ? DOLLARS.centsPlaceholder : NUMBER_FORMATS.dollars.placeholder,
        allowNegative,
        ...numberFormat
      }}
      validationSchema={validationSchema}
    />
  )

}

// EXPORT
export default Dollar;
