import React, { useContext, useCallback } from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { isFunction, isString } from 'lodash';

// GLOBAL VARIABLES
import { UPLOAD } from 'defaults.js';

// GLOBAL FUNCTIONS
import { apiFetch, doCallback, makePath } from 'functions.js';

// CONTEXT
import { UploadContext } from '../helpers/uploadContext';
import { RecordContext } from 'parts/interface/helpers/getRecordData';

// CORE COMPONENTS
import Button from 'core/tools/Button';

// STYLES
import styles from './deleteButton.module.scss';

// MAIN COMPONENT
const DeleteButton = ({
  className,
  icon,
  uploadID,
  file,
  meta,
  fieldValue,
  success,
  deleteArgs = {},
  endpoints = UPLOAD.endpoints || {},
  endpoint = endpoints.delete,
  useRecordID = UPLOAD.useRecordID,
  disabled,
  readonly,
  onDelete,
  debug,
  ...props
}) => {

  // CONTEXT
  const { recordID = props.recordID } = useContext(RecordContext) || {};
  const {
    processing,
    setDeleting,
    setError,
    removeUpload
  } = useContext(UploadContext) || {};

  // CALLBACKS
  const handleClick = useCallback(
    e => {
      const { onFetch, onDelete, onSuccess, onError, useDelete = true, ...args } = isFunction(deleteArgs) ? deleteArgs(uploadID, file, meta, fieldValue) : deleteArgs;

      if (!!endpoint && success && useDelete) {
        apiFetch({
          method: 'POST',
          endpoint: useRecordID ? makePath(endpoint, recordID) : endpoint,
          errorMessage: 'Unable to delete upload.',
          onFetch: () => {
            doCallback(onFetch);
            setDeleting(uploadID);
          },
          onSuccess: data => {
            doCallback(onSuccess, data);
            doCallback(onDelete, uploadID, file, meta, fieldValue);
            removeUpload(uploadID);
          },
          onError: error => {
            doCallback(onError, error);
            setError(uploadID);
          },
          debug,
          ...args
        })
      } else {
        removeUpload(uploadID);
        doCallback(onDelete, uploadID, file, meta, fieldValue);
      }

    },
    [endpoint, recordID, useRecordID, deleteArgs, uploadID, file, meta, fieldValue, success, setDeleting, setError, removeUpload, debug]
  )

  // RENDER
  return disabled || readonly ? null : (
    <Button
      className={clsx(
        className,
        styles.button
      )}
      label={UPLOAD.deleteLabel}
      icon={Object.assign({
        use: UPLOAD.deleteIcon
      }, isString(icon) ? { use: icon } : icon)}
      onClick={handleClick}
      disabled={processing}
      {...props}
    />
  )
}

export default DeleteButton;
