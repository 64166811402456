import React, { Fragment } from 'react';

// PARTS
import Header from 'parts/interface/header/Header';

// LOCAL PARTS
import PageFooter from './parts/PageFooter';
import PageHeader from './parts/PageHeader';

// STYLES
import styles from './page.module.scss';

// MAIN COMPONENT
const Page = ({
  children,
  header = {},
  menu: Menu = Fragment
}) => (
  <div className={styles.page}>
    <Menu />
    <div className={styles.container}>
      <Header {...header} />
      <main className={styles.main}>
        {children}
      </main>
    </div>
  </div>
)

// CHILD COMPONENTS
Page.Header = PageHeader;
Page.Footer = PageFooter;

// EXPORT
export default Page;
