import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

// GLOBAL VARIABLES
import { TIMES } from 'globals.js';

// GLOBAL FUNCTIONS
import { sidebarActions } from 'actions.js';
import { doCallback } from 'functions.js';

// CORE COMPONENTS
import Scrollbox from 'core/tools/scrollbox/Scrollbox';

// PARTS
import SidebarForm from './parts/SidebarForm';
import SidebarHeader from './parts/SidebarHeader';

// STYLES
import styles from './sidebar.module.scss';

// MAIN COMPONENT
const Sidebar = compose(
  connect(
    ({ sidebar }) => ({ sidebar }),
    { ...sidebarActions }
  ),
  withRouter
)(({
  children,
  className,
  name,
  title,
  onClose,
  // REDUX STATE
  sidebar,
  // REDUX DISPATCH
  close,
  // REACT ROUTER
  location
}) => {

  // PROPS
  const active = useMemo(
    () => !!sidebar[name],
    [name, sidebar]
  )

  // STATE
  const [ mounted, setMounted ] = useState(false);
  const [ show, setShow ] = useState(false);

  // REFS
  const outerRef = useRef();

  // CALLBACKS
  const unMount = useCallback(
    () => {
      setMounted(false)
    },
    [setMounted]
  )
  const showSidebar = useCallback(
    () => {
      setShow(true)
    },
    [setShow]
  )

  // EFFECTS
  useEffect(
    () => {
      if (active && !mounted) {
        setMounted(true);
        clearTimeout(unMount, TIMES.transitionDuration);
        setTimeout(showSidebar, TIMES.transitionDuration);
        return () => clearTimeout(showSidebar, TIMES.transitionDuration);
      }
      if (!active && show) {
        setShow(false);
        clearTimeout(showSidebar, TIMES.transitionDuration);
        setTimeout(unMount, TIMES.transitionDuration);
        return () => clearTimeout(unMount, TIMES.transitionDuration);
      };
    },
    [active, show, mounted, setMounted, setShow, showSidebar, unMount]
  )
  useEffect(
    () => {
      if (!mounted) doCallback(onClose)
    },
    [mounted, onClose]
  )
  useEffect(
    () => {
      close()
    },
    [close, location]
  )

  // RENDER
  return !mounted ? null : (<>
    <div
      className={clsx(
        styles.sidebar,
        show && styles.show,
        className
      )}
      data-show={show}
      ref={outerRef}
    >
      <SidebarHeader
        title={title}
      />
      <div>
        <Scrollbox
          outerClassName={styles.outer}
          scrollbar
          preventDefault
          >
          <div className={styles.inner}>
            {children}
          </div>
        </Scrollbox>
      </div>
    </div>
    <div
      className={clsx(
        'sidebar-bg',
        styles.backdrop
      )}
      onClick={close}
    ></div>
  </>)
})

// CHILD PARTS
Sidebar.Form   = SidebarForm

// EXPORT
export default Sidebar;
