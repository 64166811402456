import React, { cloneElement } from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { isPlainObject } from 'lodash';

// BOOTSTRAP COMPONENTS
import { Card } from 'react-bootstrap';

// LOCAL COMPONENTS
import CardTitle from './CardTitle';

// STYLES
import styles from './cardHeader.module.scss';

// MAIN COMPONENT
const CardHeader = ({
  children,
  className,
  title,
  titleProps = isPlainObject(title) ? title : {
    children: title
  },
  tools = [],
  onClick
}) => !children && !title && !tools.length ? null : (
  <Card.Header
    className={clsx(
      styles.header,
      !!onClick && styles.pointer,
      className
    )}
    onClick={onClick}
  >
    {children}
    <CardTitle {...titleProps}/>
    {!!tools.length &&
      <div className={styles.tools}>
        {tools.map((tool, i ) => cloneElement(tool, {
          key: i,
          size: 'sm'
        }))}
    </div>
  }
  </Card.Header>
)

export default CardHeader;
