import React, { useCallback } from 'react';

// DEPENDENCIES
import { compose } from 'redux';
import { connect } from 'react-redux';

// GLOBAL FUNCTIONS
import { filterActions } from 'actions.js';
import { getPathType } from 'functions.js';

// CORE COMPONENTS
import Button from 'core/tools/Button';

// MAIN COMPONENT
export default compose(
  connect(
    ({ filters }) => ({ filters }),
    { ...filterActions }
  )
)(({
  size,
  // REDUX STATE
  filters,
  // REDUX DISPATCH
  addFilters
}) => {

  // PROPS
  const { order } = filters[getPathType()] || {};

  const arrow = order === 'A' ? 'up' : 'down';

  // CALLBACKS
  const handleClick = useCallback(
    () => {
      const value = order === 'D' ? 'A' : 'D';

      if (value) addFilters(getPathType(), {
        order: value
      })
    },
    [order, addFilters]
  )


  // RENDER
  return !order ? null : (
    <Button
      icon={`arrow-${arrow}`}
      onClick={handleClick}
      square
      size={size}
    />
  )
})
