import React from 'react';

// DEPENDENCIES
import { connect } from 'react-redux';
import { compose } from 'redux';

// PARTS
import UserMenu from 'parts/interface/menus/UserMenu';

// LOCAL COMPONENTS
import HeaderDropdown from '../parts/HeaderDropdown';

// STYLES
import styles from './userDropdown.module.scss';

// CHILD COMPONENTS
const DropdownItem = ({
  icon,
  ...props
}) => (
  <HeaderDropdown.Item
    className={styles.item}
    icon={{
      use: icon,
      size: 'lg',
      order: 1
    }}
    {...props}
  />
)

// MAIN COMPONENT
const UserDropdown = compose(
  connect(
    ({ user }) => ({ user })
  )
)(({
  // REDUX STATE
  user,
  // REST
  ...props
}) => (
  <HeaderDropdown
    className={styles.dropdown}
    toggle={{
      label: `${user.first_name} ${user.last_name}`
    }}
    {...props}
  >
    <UserMenu
      as={DropdownItem}
    />
  </HeaderDropdown>
))

export default UserDropdown
