import React, { forwardRef, useMemo } from 'react';

// MAIN COMPONENT
export const specialOptions = Component => {
  return forwardRef(({
    value,
    useNone,
    useAll,
    useOther,
    defaultNone,
    defaultAll,
    defaultOther,
    ...props
  }, ref) => {

    // MEMOS
    const optionValue = useMemo(
      () => ({
        none: '',
        all: props.options.map(({ value }) => value).join(','),
        other: 'other'
      }),
      [props.options]
    )
    const options = useMemo(
      () => {
        const options = [...props.options];
        const { none, all, other } = optionValue;
        if (useNone) options.unshift({
          label: typeof useNone === 'string' ? useNone : 'None',
          value: none,
          ...(typeof useNone === 'object' ? useNone : {})
        })
        if (useAll) options.unshift({
          label: typeof useAll === 'string' ? useAll : 'All',
          value: all,
          ...(typeof useAll === 'object' ? useAll : {})
        })
        if (useOther) options.push({
          label: typeof useOther === 'string' ? useOther : 'Other',
          value: other,
          ...(typeof useOther === 'object' ? useOther : {})
        })
        return options;
      },
      [props.options, useNone, useAll, useOther, optionValue]
    )

    // EFFECTS
    const defaultValue = useMemo(
      () => {
        const { none, all, other } = optionValue;
        if (defaultNone) return none;
        if (defaultAll) return all;
        if (defaultOther) return other;
      },
      [defaultNone, defaultAll, defaultOther, optionValue]
    )

    // RENDER
    return <Component
      {...props}
      options={options}
      value={value === undefined ? defaultValue : value}
      ref={ref}
    />
  })
}
