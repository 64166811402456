import React from 'react';

// CORE COMPONENTS
import Form from 'core/form/Form';

// LOCAL COMPONENTS
import SelectFilter from '../../filter/types/SelectFilter';
import SortOrder from './SortOrder';

// MAIN COMPONENT
const SortBy = props => (<>
    <Form.Group>
      <SelectFilter
        name="sort"
        label="Sort By"
        {...props}
      />
      <Form.Col
        as="div"
        cols={null}
      >
        <SortOrder {...props} />
      </Form.Col>
    </Form.Group>
</>)

// EXPORT
export default SortBy
