import React from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { compose } from 'redux';

// HELPERS
import { checkPermission } from 'parts/interface/helpers/checkPermission';
import { checkRole } from 'parts/interface/helpers/checkRole';

// CORE COMPONENTS
import Button from 'core/tools/Button';

// CORE FUNCTIONS
import Tooltip from 'core/tools/Tooltip';

// STYLES
import styles from './action.module.scss';

// MAIN COMPONENT
export default compose(
  checkPermission,
  checkRole
)(({
  className,
  variant = 'primary',
  label,
  // REST
  ...props
}) => (
  <Tooltip
    wrapperClassName={styles.tooltip}
    variant={variant}
    tip={props.disabled ? undefined : label}
  >
    <Button
      className={clsx(
        styles.button,
        styles[variant],
        className
      )}
      variant="custom"
      justify="center"
      link
      square
      {...props}
    />
  </Tooltip>
))
