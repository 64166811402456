import React, { useCallback, useContext } from 'react';

// DEPENDENCIES
import { useFormikContext } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

// GLOBAL VARIABLES
import { BUTTON } from 'defaults.js';
import { PATHNAMES } from 'pathnames.js';

// GLOBAL FUNCTIONS
import { doCallback, getPathType, makePath } from 'functions.js';

// HELPERS
import { RecordContext } from 'parts/interface/helpers/getRecordData';
import useRouteCheck from 'parts/interface/helpers/routeCheck';

// CORE COMPONENTS
import Form from 'core/form/Form';

// STYLES
import styles from './recordFooter.module.scss';

// MAIN COMPONENT
const RecordFooter = compose(
  connect(
    ({ mobile }) => ({ mobile })
  ),
  withRouter
)(({
  children,
  onClose,
  onSuccess,
  redirect,
  saveButton = {},
  cancelButton = {},
  showAddButton = true,
  // REDUX STATE
  mobile,
  // REACT ROUTER
  history
}) => {

  // FORMIK CONTEXT
  const { resetForm } = useFormikContext() || {};

  // CONTEXT
  const { idKey, fetchRecord } = useContext(RecordContext) || {};

  // VARIABLES
  const path = PATHNAMES[getPathType()];
  const isEdit = useRouteCheck('edit');
  const isAdd = useRouteCheck('add');
  const isView = useRouteCheck('view');

  // CALLBACKS
  const handleClose = useCallback(
    () => {
      doCallback(onClose);
      if (redirect !== false) history.push(redirect || makePath(path || '/'));
    },
    [path, onClose, redirect, history]
  )
  const handleSaveAndClose = useCallback(
    (data = {}) => {
      doCallback(onSuccess, data);
      handleClose();
    },
    [onSuccess, handleClose]
  )

  const handleSaveAndRefresh = useCallback(
    (data = {}) => {
      doCallback(onSuccess, data);
      fetchRecord();
    },
    [onSuccess,fetchRecord]
  )

  const handleSaveAndContinue = useCallback(
    (data = {}) => {
      doCallback(onSuccess, data);
      const newID = data[idKey];
      if (!!path && !!newID) history.push(makePath(path, PATHNAMES.edit, newID));
    },
    [path, idKey, onSuccess, history]
  )
  const handleSaveAndReset = useCallback(
    data => {
      doCallback(onSuccess, data);
      resetForm();
      document.documentElement.scrollTop = 0;
    },
    [onSuccess, resetForm]
  )

  // RENDER
  return (
    <div className={styles.footer}>
      {children || <>
        {cancelButton !== false &&
          <Form.Button.Cancel
            className={styles.cancelButton}
            label={mobile ? '' : isEdit ? 'Close' : isView ? 'Back' : BUTTON.cancel.label}
            icon={{
              use: 'arrow-left',
              order: 1
            }}
            onCancel={handleClose}
            outline
            {...cancelButton}
          />
        }
        {!isAdd &&
          <Form.Button.Save
            args={{
              onSuccess: (redirect === false)? handleSaveAndRefresh : handleSaveAndClose
            }}
            {...saveButton}
          />
        }
        {isAdd && <>
            {showAddButton && <Form.Button.Save
                args={{
                    onSuccess: handleSaveAndContinue
                }}
                {...saveButton}
            />}
          {!mobile && <>
            <Form.Button.Save
              label="Save and Close"
              icon="times-circle"
              args={{
                onSuccess: handleSaveAndClose
              }}
              {...saveButton}
            />
            <Form.Button.Save
              label="Save and Add Another"
              icon={BUTTON.add.icon}
              args={{
                onSuccess: handleSaveAndReset
              }}
              {...saveButton}
            />
          </>}
        </>}
      </>}
    </div>
  )
})

// EXPORT
export default RecordFooter;
