import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { BrowserRouter } from 'react-router-dom';
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

import { persistor, store } from 'store.js';

import { unregister } from './registerServiceWorker';

import 'icons.js';
import 'css/stylesheet.scss';

import App from 'App';

/* START Bugsnag code */
const bugsnagClient = bugsnag({
  apiKey: '4320be77b5372651840ceafe5ab0e1bc',
  beforeSend: function (report) {
    if (report.errorMessage === 'ResizeObserver loop limit exceeded') report.ignore();
  }
});
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');
//bugsnagClient.notify(new Error('Test error'));
/* END BugSnag code */

ReactDOM.render((
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
), document.getElementById('root'));

unregister();
