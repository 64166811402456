import React, { useEffect } from 'react';

import { compose } from "redux";
import { connect } from "react-redux";

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';

// TOOLS
import Badge from 'tools/badge/Badge';

// PARTS
import List from 'parts/interface/list/List';

import { filterActions } from "actions";
const { Action, Button, Col, Filter, Tool } = List;

// ARGS
const ARGS = {
  label: 'user',
  idKey: 'site_user_id',
  endpoint: ENDPOINTS.record.user.list
}

const SORT_BY_OPTIONS = [
  { label: "Full Name", value: "full_name" },
  { label: "First Name", value: "first_name" },
  { label: "Last Name", value: "last_name" },
  { label: "Username", value: "user_name" },
  { label: "Email Address", value: "email_address" },
  { label: "Active Flag", value: "active_flag" },
  { label: "Last Login Date", value: "last_login_date" },
]

const TOOLS = [
    <Tool.DebouncedSearch
        name="quickSearchText"
    />,
    <Tool.SortBy
      options={SORT_BY_OPTIONS}
      useSelect
    />,
    <Filter.Active
        name="search_active_flag"
        useSelect
    />
];

// HEADER
const BUTTONS = [ <Button.Add /> ];

// COLUMNS
const COLUMNS = ({
  last_name,
  first_name,
  user_name,
  role_name,
  active_flag,
  last_login_date
}) => [
  <Col
    cols={{lg: 3}}
    label="Name"
    value={`${last_name}, ${first_name}`}
  />,
  <Col
    cols={{lg: 6}}
    label="Username"
    value={user_name}
  />,
  <Col
    cols={{lg: 7}}
    label="Application Role"
    value={role_name}
  />,
  <Col
    cols={{lg: 2}}
    label="Active?"
    as={ Badge.YesNo }
    value={active_flag}
  />,
  <Col
    cols={{lg: 6}}
    label="Last Login"
    value={last_login_date}
  />,
];

// ACTIONS
const ACTIONS = () => [ <Action.Edit /> ];


// MAIN COMPONENT
const Users = compose(
    connect(
        null,
        { ...filterActions }
    )
)
(({
    clearFilters
  }) => {

  useEffect(() => {
    return () => {
      clearFilters('users')
    }
  }, [clearFilters])

  return (
      <List
          title="Manage Users"
          args={ARGS}
          buttons={BUTTONS}
          tools={TOOLS}
          columns={COLUMNS}
          actions={ACTIONS}
          usePagination={false}
          useInfiniteScroll={true}
      />
  )
});

export default Users;
