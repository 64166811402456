import React, { cloneElement, useContext } from 'react';

// DEPENDENCIES
import { connect } from 'react-redux';
import { compose } from 'redux';

// HELPERS/CONTEXT
import { documentTitle } from 'helpers/documentTitle';
import { getListData, ListContext } from '../helpers/getListData.js';

// PARTS
import Page from 'parts/interface/page/Page';
import FilterSidebar from 'parts/interface/sidebars/FilterSidebar';

// LOCAL COMPONENTS
import ListAction from './action/ListAction';
import ListButton from './button/ListButton';
import ListFilter from './filter/ListFilter';
import ListTool from './tool/ListTool';

// LOCAL PARTS
import ListCol from './parts/ListCol';
import ListHeader from './parts/ListHeader';
import ListHeaders from './parts/ListHeaders';
import ListPagination from './parts/ListPagination';
import ListRow from './parts/ListRow';
import ListToolbar from './parts/ListToolbar';

// STYLES
import styles from './list.module.scss';

// CHILD COMPONENTS
const ListContainer = props => <div className={styles.container} {...props} />;
const ListCard = props => <div className={styles.card} {...props} />;
const ListMessage = props => <div className={styles.message} {...props} />;
const ListRows = props => <div className={styles.rows} {...props} />;

// MAIN COMPONENT
const List = compose(
  documentTitle,
  getListData,
  connect(
    ({ mobile }) => ({ mobile })
  )
)(({
  showID,
  usePagination,
  // REDUX STATE
  mobile,
  // REST
  ...props
}) => {

  // CONTEXT
  const {
    plural,
    idKey,
    buttons = [],
    tools = [],
    searchFilters = [],
    sidebars,
    state,
    records: {
      total = 0,
      list = []
    }
  } = useContext(ListContext) || {};

  // RENDER
  return (<>
    {!!mobile && !!searchFilters.length &&
      <ListButton.FilterMobile className={styles.headerButton} />
    }
    {!!mobile && !!buttons.length &&
      cloneElement(buttons[0], { className: styles.topButton })
    }
    <ListHeader {...props} buttons={buttons} />
    <ListContainer>
      {!mobile &&
        <ListToolbar
          tools={tools}
        />
      }
      <ListCard>
        <ListHeaders showID={showID} />
        {!total ? state === 'fetching' ? (
          <ListMessage>Looking up {plural}...</ListMessage>
        ) : (
          <ListMessage>No {plural} found.</ListMessage>
        ) : (
          <ListRows>
            {list.map((record, i) => (
              <ListRow
                key={i}
                rowID={i + 1}
                showID={showID}
                record={record}
                recordID={record[idKey]}
              />
            ))}
          </ListRows>
        )}
      </ListCard>
    </ListContainer>
    {!!usePagination &&
      <ListPagination />
    }
    <Page.Footer />
    <FilterSidebar />
    {!!sidebars?.length &&
      sidebars.map((sidebar, i) =>cloneElement(sidebar, { key: i }))
    }
  </>)
})

// CHILD EXPORTS
List.Header    = ListHeader;
List.Container = ListContainer;
List.Toolbar   = ListToolbar;
List.Card      = ListCard;
List.Headers   = ListHeaders;
List.Message   = ListMessage;
List.Rows      = ListRows;
List.Row       = ListRow;
List.Col       = ListCol;
List.Action    = ListAction;
List.Button    = ListButton;
List.Filter    = ListFilter;
List.Tool      = ListTool;

// EXPORT
export default List;
