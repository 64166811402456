import React, { useCallback } from 'react';

// DEPENDENCIES
import { compose } from 'redux';
import { connect } from 'react-redux';

// GLOBAL VARIABLES
import { ROWS_ON_PAGE } from 'globals.js'

// GLOBAL FUNCTIONS
import { filterActions } from 'actions.js';

// LOCAL COMPONENT
import Form from 'core/form/Form';

// MAIN COMPONENT
const PerPage = compose(
  connect(
    null,
    { ...filterActions }
  )
)(({
  // REDUX DISPATCH
  addFilters,
  clearFilters,
  updateFilter,
  clearFilter,
  // REST
  ...props
}) => {

  // CALLBACKS
  const handleValueChange = useCallback(
    ({ value }) => {
      if (value) addFilters('global', {
        rowsOnPage: value
      })
    },
    [addFilters]
  )

  // RENDER
  return (
    <Form.Select
      {...props}
      name="rowsOnPage"
      label="Per Page"
      options={ROWS_ON_PAGE.options}
      onValueChange={handleValueChange}
      htmlSize={3}
    />
  )
})

// EXPORT
export default PerPage
