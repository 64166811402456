import React from 'react';

// DEPENDENCIES
import clsx from 'clsx';
import { compose } from 'redux';

// GLOBAL VARIABLES
import { DATE } from 'defaults.js';

// GLOBAL FUNCTIONS
import { controlName } from 'functions.js';

// HELPERS
import { dateProps } from './helpers/dateProps';
import { dateOptions } from './helpers/dateOptions';
import { dateValue } from './helpers/dateValue';
import { handleSelect } from './helpers/handleSelect';
import { formikField } from '../../helpers/formik/formikField';
import { formCollapse } from '../../helpers/layout/formCollapse';
import { formGroup } from '../../helpers/layout/formGroup';
import { inputLabel } from '../../helpers/layout/inputLabel';
import { inputText } from '../../helpers/layout/inputText';
import { useHidden } from '../../helpers/layout/useHidden';
import { inputState } from '../../helpers/state/inputState';

// LOCAL COMPONENTS
import DatePicker from './parts/DatePicker';
import TimePicker from './parts/TimePicker';
import Select from '../select/Select';
import Input from '../../parts/Input';
import Row from '../../../parts/Row';
import Group from '../../../parts/Group';

// STYLES
import styles from './date.module.scss';

// LOCAL VARIABES
const COLS = {
  inline: {
    xs: 'auto'
  },
  default: {
    xs: 12
  }
}

// MAIN COMPONENT
const Date = compose(
  dateProps,
  formikField,
  inputState,
  useHidden,
  formCollapse,
  formGroup,
  inputLabel,
  inputText,
  dateOptions,
  dateValue,
  handleSelect
)(({
  className,
  name,
  startName,
  endName,
  option,
  selectOptions,
  onOptionSelect,
  onDateSelect,
  currentDate,
  currentTime,
  dateRange,
  startDate,
  endDate,
  minDate,
  maxDate,
  time,
  range,
  required,
  label = time ? 'Date/Time' : range ? 'Date Range' : 'Date',
  useOptions = !time && DATE.useOptions,
  showPicker = !useOptions || option === 'custom',
  ...props
}) => {

  // PROPS
  const {
    inline,
    vertical,
    columns,
    plaintext,
    fullWidth,
    Container = inline ? Group : Row
  } = props;

  // RENDER
  return plaintext ? 
  !!range ? (
    <Input
      as={startDate && endDate ? 'span' : undefined}
      value={startDate && endDate ? <>{startDate}&nbsp;<span className={styles.muted}>&mdash;</span>&nbsp;{endDate}</> : undefined}
      {...props}
    />
  ) : !!time ? (
    <Input
      as={currentDate && currentTime ? 'span' : undefined}
      value={currentDate && currentTime ? <>{currentDate}&nbsp;<span className={styles.muted}>@</span>&nbsp;{currentTime}</> : undefined}
      {...props}
    />
  ) : (
    <Input value={currentDate} {...props} />
  ) : (
    <Container
      className={clsx(
        styles.outer,
        className
      )}
    >
      {useOptions &&
        <Select
          name={name || (startName && endName) ? controlName('option', 'dummy', name) : undefined}
          value={option}
          placeholder={`Choose ${label}`}
          onSelect={onOptionSelect}
          options={selectOptions}
          toggle={{
            input: {
              htmlSize: inline ? 12 : undefined
            }
          }}
          formGroup={{
            className: clsx(
              styles.select,
              inline && styles.inline,
              vertical && styles.vertical,
              columns && styles.columns,
              fullWidth && styles.fullWidth
            )
          }}
          required={required}
          {...props}
        />
      }
      {showPicker && (<>
        {!range ? (<>
          <DatePicker
            name={time ? controlName('date', 'dummy', name) : useOptions ? controlName('picker', 'dummy', name) : name ? controlName(name, 'dummy') : undefined}
            currentDate={currentDate}
            minDate={minDate}
            maxDate={maxDate}
            onSelect={onDateSelect}
            onDateChange={onDateSelect}
            formGroup={{
              className: clsx(
                styles.picker,
                time && styles.timePicker,
                inline && styles.inline,
                vertical && styles.vertical,
                columns && styles.columns,
                fullWidth && styles.fullWidth
              ),
              cols: time ? {
                xs: 12
              } : undefined
            }}
            dropdown={{
              align: time ? 'start' : undefined
            }}
            required={!!currentTime || !!required}
            {...props}
          />
          {!!time && 
            <TimePicker
              name={name ? controlName('time', 'dummy', name) : undefined}
              currentDate={currentDate}
              value={currentTime}
              minDate={minDate}
              maxDate={maxDate}
              formGroup={{
                className: clsx(
                  styles.timePicker,
                  inline && styles.inline,
                  vertical && styles.vertical,
                  columns && styles.columns,
                  fullWidth && styles.fullWidth
                ),
                cols: {
                  xs: 12
                }
              }}
              required={!!currentDate || !!required}
              {...props}
            />
          }
        </>) : (<>
          <DatePicker
            name={name ? controlName('start', 'dummy', name) : startName}
            formGroup={{
              className: clsx(
                styles.picker,
                styles.rangePicker,
                inline && styles.inline,
                vertical && styles.vertical,
                columns && styles.columns,
                fullWidth && styles.fullWidth
              ),
              cols: {
                xs: 12
              }
            }}
            prepend={{
              label: DATE.fromPrepend
            }}
            toggle={inline || vertical ? {
              icon: ''
            } : {}}
            dropdown={{
              align: 'start'
            }}
            cols={inline ? COLS.inline : COLS.default}
            currentDate={startDate}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={endDate || maxDate}
            required={!!endDate || !!required}
            onSelect={value => onDateSelect(value, 0)}
            {...props}
          />
          <DatePicker
            name={name ? controlName('end', 'dummy', name) : endName}
            formGroup={{
              className: clsx(
                styles.picker,
                styles.rangePicker,
                inline && styles.inline,
                vertical && styles.vertical,
                columns && styles.columns,
                fullWidth && styles.fullWidth
              ),
              cols: {
                xs: 12
              }
            }}
            prepend={{
              label: DATE.toPrepend
            }}
            toggle={inline || vertical ? {
              icon: ''
            } : {}}
            cols={inline ? COLS.inline : COLS.default}
            currentDate={endDate}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate || minDate}
            maxDate={maxDate}
            required={!!startDate || !!required}
            onSelect={value => onDateSelect(value, 1)}
            {...props}
          />
        </>)}
      </>)}
    </Container>
  )
})

// CHILD COMPONENTS
Date.Time = props => <Date time {...props} />;
Date.Range = props => <Date range {...props} />;

// EXPORT
export default Date;
