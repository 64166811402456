import React from 'react';

// DEPENDENCIES
import { connect } from 'react-redux';

// GLOBAL FUNCTIONS
import { sidebarActions } from 'actions.js';

// PARTS
import HeaderButton from 'parts/interface/header/parts/HeaderButton';

// MAIN COMPONENT
const FilterHeaderButton = connect(
  ({ mobile }) => ({ mobile }),
  { ...sidebarActions }
)(({
  className,
  // REDUX STATE
  mobile,
  // REDUX DISPATCH
  toggle
}) => !mobile ? null : (
  <HeaderButton
    className={className}
    icon="search"
    onClick={() => toggle('filters')}
  />
))

// EXPORT
export default FilterHeaderButton
