import React from 'react';

// CORE COMPONENTS
import Form from 'core/form/Form';

// PROTOTYPE
import EditPrototype from '../prototype/EditPrototype';

// TOOLS
import CharKey from 'tools/form/CharKey';

// MAIN COMPONENT
const Applications = () => (
  <EditPrototype
    routeKey="application"
  >
    <Form.Control
      name="application_name"
      label="Application Name"
      required
    />
    <CharKey />
    <Form.YesNo
      name="sms_code_verify"
      label="SMS Code Verification"
      required
    />
  </EditPrototype>
)

export default Applications;

