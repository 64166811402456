// ROUTES
import ChangePassword from './routes/ChangePassword';
import Profile from './routes/Profile';

// MAIN COMPONENT
const User = {
  Profile,
  ChangePassword
};

// EXPORT
export default User
