import React, { useCallback } from 'react';

// DEPENDENCIES
import { compose } from 'redux';
import { connect } from 'react-redux';

// GLOBAL FUNCTIONS
import { filterActions } from 'actions.js';
import { getPathType } from 'functions.js';

// CORE FUNCTIONS
import Form from 'core/form/Form';

// MAIN COMPONENT
const SearchFilter = compose(
  connect(
    null,
    { ...filterActions }
  )
)(({
  name = 'searchKeyword',
  label = 'Keyword Search',
  placeholder = 'Enter Keyword',
  htmlSize,
  update,
  // REDUX DISPATCH
  addFilters,
  clearFilters,
  updateFilter,
  clearFilter,
  // REST
  ...props
}) => {

  // CALLBACKS
  const handleKeyDown = useCallback(
    e => {
      const { key, target: { name, value } } = e;
      if (key === 'Enter') {
        addFilters(getPathType(), {
          [name]: value
        })
        e.target.blur();
      }
    }, [addFilters]);

  const handleClear = useCallback(
    () => {
      clearFilter(getPathType(), name)
    }, [name, clearFilter])

  // RENDER
  return (
    <Form.Control
      name={name}
      label={props.size === 'sm' ? undefined : label}
      placeholder={props.size === 'sm' ? 'Quick Search' : placeholder}
      prepend={props.size !== 'sm' ? undefined : {
        use: 'search'
      }}
      onKeyDown={update ? handleKeyDown : undefined}
      onClear={update ? handleClear : undefined}
      clearButton
      {...props}
    />
  )
})

// EXPORT
export default SearchFilter
