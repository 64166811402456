import React, {useCallback, useEffect, useMemo, useState} from 'react';

import { useSelector } from 'react-redux'
// GLOBAL VARIABLES
import { PATHNAMES } from 'pathnames.js';

// GLOBAL FUNCTIONS
import { makePath } from 'functions.js';

// CORE COMPONENTS
import Form from 'core/form/Form';
import Record from 'parts/interface/record/Record';


import useRouteCheck from 'parts/interface/helpers/routeCheck';

// PROTOTYPE
import EditPrototype from '../prototype/EditPrototype';
import LeadRoutingChangeLog from '../../../routes/list/types/LeadRoutingChangeLog';

const EMAIL_FORMAT = [
  { label: 'ADF', value: 'ADF'},
  { label:'Plain Text', value: 'PLN' }
];

const FROM_EMAIL_ADDR = 'leads@genevamedia.com';

const INITIAL_VALUES = {
  from_email_address: FROM_EMAIL_ADDR
}

// MAIN COMPONENT
const LeadRouting = ({ location }) => {

  const isEdit = useRouteCheck('edit');
  const [from_email_address, setFromEmailOptions] = useState(FROM_EMAIL_ADDR);
  const [other_from_email_address, setOtherEmailAddress] = useState();
  const [selectedEmailOption, setSelectedEmailOption] = useState();
  const [ selectedCategoryId, setSelectedCategoryId ] = useState();

  const setCurrentSelection = useCallback((email_address, other_email_address)=> {
    if ( other_email_address && other_email_address.length) {
      setSelectedEmailOption( 3 );
    } else if (email_address === FROM_EMAIL_ADDR)  {
      setSelectedEmailOption( 1 );
    } else {
      setSelectedEmailOption( 2 );
    }
  }, [])

  const { fromEmailAddress, otherEmailAddress, leadCategoryId } = useSelector((state) => { 
    
    const fromEmailAddress = state.record.from_email_address? state.record.from_email_address : '';
    const otherEmailAddress = state.record.from_email_address? state.record.other_from_email_address :'';
    const leadCategoryId = state.record.lead_category_id? state.record.lead_category_id :'';

    return {
      fromEmailAddress,
      otherEmailAddress,
      leadCategoryId
    }
  });

  useEffect(() => { 

    setFromEmailOptions(fromEmailAddress);
    setOtherEmailAddress(otherEmailAddress);
   
    if (!isEdit) {
      const params = new URLSearchParams(location.search);
      setSelectedCategoryId( params.get('leadCategoryId') );
    } else {
      setSelectedCategoryId(leadCategoryId);
    }


  }, [fromEmailAddress, otherEmailAddress, leadCategoryId, location, isEdit]);

  // MEMOS
  const fromEmailAddressChoiceOptions = useMemo(
    () => {
      setCurrentSelection(from_email_address, other_from_email_address);

      return [
        { label: ((from_email_address === '')? FROM_EMAIL_ADDR : from_email_address) , value: 1 }, 
        { label: 'Sender\'s Email Address', value: 2 },
        { label:'Other', value: 3 }
      ]},
    [from_email_address, other_from_email_address, setCurrentSelection]
  )

  
  return(
    <>
      <EditPrototype
        routeKey="leadRouting"
        idKey="lead_routing_id"
        redirect={makePath(PATHNAMES.leadRouting)+'?leadCategoryId='+selectedCategoryId}
        form={{
          initialValues: {
            ...INITIAL_VALUES,
            lead_category_id: selectedCategoryId
          },
          showCreatedDate: false
        }}
      >
    <Form.Select
        name="lead_category_id"
        label="Category"
        lookup="leadCategories"
        optionKeys={{label: 'category_name', value: 'lead_category_id'}}
        value={selectedCategoryId}
        useFormik={false}
        disabled={true}
        required
      />
      <Form.Control
        name="name"
        label="Name"
        required
      />
      <Form.Control
        name="email_address"
        label="To Email Address"
        required
      />
      <Form.Checklist
          name="fromEmailAddressChoice"
          label="From Email Address"
          type="radio"
          options={ fromEmailAddressChoiceOptions }
          onChange={({ target: { value } }) => {
            setSelectedEmailOption(value)
          }}
          value={selectedEmailOption}
          required
        />
        <Form.Control
          name="other_from_email_address"
          label="Other Email Address"
          disabled={Number(selectedEmailOption) < 3}
          required={Number(selectedEmailOption) === 3}
        />
      <Form.Checklist
        name="lead_form_id"
        label="Form Types"
        type="checkbox"
        lookup="leadFormTypes"
        optionKeys={{ label: 'lead_name', value: 'lead_form_id'}}
        required
      />
      <Form.Checklist
        name="email_format"
        label="Email Format"
        type="radio"
        options={EMAIL_FORMAT}
        required
      />
      <Record.Card.Divider />
      {
        isEdit &&
        <LeadRoutingChangeLog  />
      }
    </EditPrototype>
  </>
  )
}

export default LeadRouting;
