import React, { useContext, useMemo } from 'react';

// DEPENDENCIES
import { identity, isEmpty, isFunction, lowerCase, startCase } from 'lodash';

// GLOBAL FUNCTIONS
import { makePath } from 'functions.js';

// CONTEXT
import { RecordContext } from 'parts/interface/helpers/getRecordData';

// CORE COMPONENTS
import Form from 'core/form/Form';

// LOCAL COMPONENTS
import RecordFooter from './RecordFooter';
import useRouteCheck from "../../helpers/routeCheck";

// MAIN COMPONENT
const RecordForm = ({
  children,
  method,
  endpoint,
  useRecordID,
  initialValues,
  initializeRecord = identity,
  footer,
  ...props
}) => {

  // CONTEXT
  const {
    label = 'entry',
    recordID,
    record,
    idKey,
  } = useContext(RecordContext) || {};

  // HOOKS
  const isEdit = useRouteCheck('edit');

  // MEMOS
  const initialValuesMemo = useMemo(
    () => {
      if (isEmpty(record)) {
        return initialValues;
      }
      
      let newRecord = initializeRecord(record);

      if (useRecordID || isEdit) {
        delete newRecord[idKey];
      }
      
      return newRecord;
    },
    [record, idKey, useRecordID, isEdit, initialValues, initializeRecord]
  )
  const methodMemo = useMemo(
    () => !!method ? method : isEdit ? "PUT" : "POST",
    [isEdit, method]
  )
  
  // RENDER
  return !endpoint ? children : (
    <Form
      initialValues={initialValuesMemo}
      method={methodMemo}
      endpoint={useRecordID || isEdit ? makePath(endpoint, recordID) : endpoint}
      loadingMessage={`${!recordID ? 'Add' : 'Updat'}ing ${lowerCase(label)}`}
      errorMessage={`Unable to ${!recordID ? 'add' : 'update'} ${lowerCase(label)}.`}
      successMessage={`${startCase(label)} ${!recordID ? 'add' : 'updat'}ed.`}
      {...props}
    >
      {formikBag => (<>
        {isFunction(children) ? children(formikBag) : children}
        {footer || <RecordFooter {...props} />}
      </>)}
    </Form>
  )
}

// EXPORT
export default RecordForm;
