import React, { useCallback, useContext, useMemo } from 'react';

// DEPENDENCIES
import { isObject } from 'lodash';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';

// GLOBAL FUNCTIONS
import { apiFetch, doCallback, makePath } from 'functions.js';

// CONTEXT
import { ListContext } from '../../../helpers/getListData';

// LOCAL COMPONENTS
import Action from '../prototype/Action';

// STYLES
import styles from '../prototype/action.module.scss';

// MAIN COMPONENT
export default ({
  recordID,
  direction,
  record: {
    sort_order
  }
}) => {

  // MEMOS
  const propsMemo = useMemo(
    () => {
      switch(direction) {
        case 'A':
          return {
            label: 'Move Up',
            icon: 'arrow-up'
          }
        case 'D':
          return {
            label: 'Move Down',
            icon: 'arrow-down'
          }
        default:
          return {}
      }
    },
    [direction]
  )

  // CONTEXT
  const { routeKey, records, fetchRecords } = useContext(ListContext) || {};

  // CALLBACKS
  const handleClick = useCallback(
    () => {
      const endpoint = isObject(ENDPOINTS.record[routeKey]) ? ENDPOINTS.record[routeKey].sort : ENDPOINTS.record[routeKey];
      if (!endpoint) return;
      apiFetch({
        method: 'PUT',
        endpoint: makePath(endpoint, recordID),
        params: {
          sort_direction: direction
        },
        onSuccess: () => {
          doCallback(fetchRecords);
        }
      })
    },
    [recordID, routeKey, direction, fetchRecords]
  )

  // RENDER
  const hide = (direction === 'A' && sort_order === 1) || (direction === 'D' && sort_order === records.total);
  return !!hide ? <div className={styles.button} /> : (
    <Action
      {...propsMemo}
      onClick={handleClick}
    />
  )
}
