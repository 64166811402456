// TYPES
import Filters from './tools/Filters';
import ResultCount from './tools/ResultCount';
import PerPage from './tools/PerPage';
import SortBy from './tools/SortBy';
import RecordsInfo from "./tools/RecordsInfo";
import DebouncedSearch from "./tools/custom/DebouncedSearch";

// MAIN OBJECT
const ListTool = {
  Filters,
  ResultCount,
  PerPage,
  SortBy,
  RecordsInfo,

  // CUSTOM
  DebouncedSearch,
}

// EXPORT
export default ListTool
