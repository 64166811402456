import React, { useMemo } from 'react';

// PROTOTYPE
import Badge from '../prototype/Badge';

// LOCAL COMPONENTS
import NumberBadge from './NumberBadge';

// MAIN COMPONENT
const AgeBadge = ({
  children,
  record,
  ageRanges,
  value = children,
  ...props
}) => {

  // MEMOS
  const variant = useMemo(
    () => {
      let variant = 'success';
      if (typeof ageRanges === 'object') {
        for (var key in ageRanges) {
          if (ageRanges.hasOwnProperty(key)) {
            if (parseInt(value, 0) > ageRanges[key]) {
              variant = key;
            }
          }
        }
      } else if (typeof ageRanges === 'function') {
        variant = ageRanges(record);
      }
      return variant;
    },
    [value, ageRanges, record]
  )

  // RENDER
  return value === 0 ? (
    <Badge
      variant="success"
      label="Now"
      {...props}
    />
  ) : (
    <NumberBadge
      variant={variant}
      schema="age"
      value={value}
      {...props}
    />
  )
}

// EXPORT
export default AgeBadge;
