import React from 'react';

// PARTS
import Dashboard from 'parts/interface/dashboard/Dashboard';

// MAIN COMPONENT
const DashboardRoute = () => (
  <Dashboard.Container
    title="Dashboard"
  >
    <Dashboard.Row>
      <Dashboard.Col>
        <Dashboard.Card
          title="Manage Users"
          icon="user"
          children={[
              {
                  label: 'Manage Users',
                  routeKey: 'users'
              }
          ]}
        />
        <Dashboard.Card
          title="Website Management"
          icon="globe"
          children={[
            'seoMetadata',
            'requestPages',
            'dealershipHours',
          ]}
        />
        <Dashboard.Card
          title="Leads / Forms"
          icon="clipboard-list"
          children={[
            'leadForms',
            'leadRouting',
          ]}
        />
      </Dashboard.Col>
      <Dashboard.Col xs={24} md={12}>
        <Dashboard.Card
          title="Admin Configuration"
          icon="cog"
          children={[
            'leadFormCategory',
            'dealershipGroups',
            'dealerships',
            'websiteGeneralInfo',
            'applications',
            'applicationRoles',
            'applicationConfiguration',
            'organizationTitles',
            'serviceTransportation'
          ]}
        />
      </Dashboard.Col>
    </Dashboard.Row>
  </Dashboard.Container>
)

// EXPORT
export default DashboardRoute
