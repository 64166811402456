import React from 'react';

// PROTOTYPE
import ListPrototype from '../prototype/ListPrototype';

// PARTS
import List from 'parts/interface/list/List';
const { Col } = List;

// COLUMNS
const COLUMNS = ({
  page_title,
  seo_main_header,
  metadata_title,
  page_file_name
}) => [
  <Col
    cols={{ lg: 6 }}
    label="Page Title"
    value={page_title}
  />,
  <Col
    cols={{ lg: 6 }}
    label="Site Header"
    value={seo_main_header}
  />,
  <Col
    cols={{ lg: 6 }}
    label="Metadata Title"
    value={metadata_title}
  />,
  <Col
    cols={{ lg: 6 }}
    label="File Name"
    value={page_file_name}
  />
]

// MAIN COMPONENT
const SEOMetadata = () => <ListPrototype
  routeKey="seoMetadata"
  idKey="seo_metadata_header_id"
  label="SEO metadata"
  plural="SEO metadata"
  buttons={[]}
  columns={COLUMNS}
/>
SEOMetadata.canAdd = false;

export default SEOMetadata;
