import React from 'react';

// DEPENDENCIES
import clsx from 'clsx';

// GLOBALS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// STYLES
import styles from './fontAwesome.module.scss';

// MAIN COMPONENT
const FontAwesome = ({
  className,
  prefix = 'fas',
  use,
  size,
  fixedWidth
}) => (
  <FontAwesomeIcon
    className={clsx(
      styles.icon,
      styles[size],
      className
    )}
    icon={[prefix, use]}
    size={size === 'md' ? undefined : size}
    fixedWidth={fixedWidth}
  />
)

// EXPORT
export default FontAwesome;
