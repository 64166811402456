import React from 'react';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';

// CORE COMPONENTS
import Form from 'core/form/Form';

// PARTS
import Record from 'parts/interface/record/Record';

// MAIN COMPONENT
const ChangePassword = () => (
  <Record
    documentTitle="Password"
    recordID="password"
    label="password"
  >
    <Record.Form
      method="PUT"
      endpoint={ENDPOINTS.user.password}
      cancelButton={false}
    >
      <Record.Card
        title="Change Password"
      >
        <Record.Card.Body>
          <Form.Body>
            <Form.Control
              name="current_password"
              label="Current Password"
              type="password"
              required
            />
            <Form.Control
              name="new_password"
              label="New Password"
              type="password"
              schema="passkey"
              confirmed
              required
            />
          </Form.Body>
        </Record.Card.Body>
      </Record.Card>
    </Record.Form>
  </Record>
)

export default ChangePassword
