import React, { useMemo } from 'react';

// DEPENDENCIES
import { trim } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';
import { PATHNAMES } from 'pathnames.js';

// GLOBAL FUNCTIONS
import { sessionActions } from 'actions.js';
import { makePath } from 'functions.js';

// CORE COMPONENTS
import Form from 'core/form/Form';

// PARTS
import Page from 'parts/authentication/Page';

// VARIABLES
const STEPS = [
  PATHNAMES.add,
  PATHNAMES.view,
  PATHNAMES.edit,
  PATHNAMES.email,
  PATHNAMES.text,
  PATHNAMES.emailText,
  PATHNAMES.assignment,
  PATHNAMES.signatures,
  PATHNAMES.verification
]

// MAIN COMPONENT
const Login = compose(
  connect(
    ({ configuration, referrer }) => ({ configuration, referrer }),
    { ...sessionActions }
  )
)(({
  // REDUX STATE
  configuration,
  referrer,
  // REDUX DISPATCH
  login
}) => {


  const parameters = useMemo(
    () => {
      if (!referrer) return {};
      const step = STEPS.find(step => referrer.indexOf(`/${step}/`) > -1);
      const recordID = step ? trim(referrer.split(`/${step}/`)[1], '/') : undefined;
      return {
        ro_id: recordID
      }
    },
    [referrer]
  )

  // RETURN
  return (
    <Page
      documentTitle="Login"
      title="Log in to your account"
      subtitle="Please enter your information."
      formArgs={{
        endpoint: ENDPOINTS.session.login,
        initialValues: {parameters},
        onSuccess: login,
        isLogin: true,
        loadingMessage: 'Logging in',
        errorMessage: 'Unable to log in.'
      }}
    >
      <Form.Body.Vertical
        disabled={!configuration}
      >
        <Form.Control
          name="username"
          label="Username"
          autoComplete={true}
          required
        />
        <Form.Control
          type="password"
          name="password"
          label="Password"
          autoComplete="current-password"
          required
        />
        <Form.Text>
          <Page.Button.Link
            label="Forgot Password?"
            to={makePath(PATHNAMES.resetPassword)}
            variant="primary"
          />
        </Form.Text>
        <Form.Text className="text-secondary">
          <strong>WARNING</strong>: The information in this site is confidential and may be legally privileged. Do not share your login information. You van be personally liable for any violation of the company’s computer data policies.
        </Form.Text>
      </Form.Body.Vertical>
      <Page.Footer>
        <Page.Button.Submit
          label="Log In"
        />
      </Page.Footer>
    </Page>
  )
})

// EXPORT
export default Login;
