import React, { forwardRef, useEffect, useMemo } from 'react';

// GLOBAL FUNCTIONS
import { bugLog } from 'functions';

// MAIN COMPONENT
export const selectedOption = Component => {
  return forwardRef((props, ref) => {

    // PROPS
    const {
      name,
      value = '',
      options,
      allowCustom,
      form: {
        setFieldValue
      } = {},
      debug
    } = props;

    // MEMOS
    const selection = useMemo(
      () => {
        if (!Array.isArray(options)) return;
        const selection = options[options.findIndex(
          o => o.value !== undefined && o.value.toString() === value.toString()
        )];
        bugLog('selection', debug, name);
        bugLog(selection, debug)
        return selection;
      },
      [name, value, options, debug]
    )

    // EFFECTS
    useEffect(
      () => {
        //if (value && !!options.length && !selection && !allowCustom) doCallback(setFieldValue, name, undefined);
      },
      [name, value, options, selection, allowCustom, setFieldValue]
    )

    // RENDER
    return <Component
      {...props}
      selection={selection}
      ref={ref}
    />
  })
}
