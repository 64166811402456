import React, { forwardRef, useMemo } from 'react';

// DEPENDENCIES
import * as yup from 'yup';

// GLOBAL FUNCTIONS
import { safeDate, format } from 'functions.js';

// MAIN COMPONENT
export const dateValidation = Component => {
  return forwardRef((props, ref) => {

    // PROPS
    const {
      minDate,
      maxDate
    } = props;

    // MEMO
    const safeMinDate = useMemo(
      () => safeDate(minDate),
      [minDate]
    )
    const safeMaxDate = useMemo(
      () => safeDate(maxDate),
      [maxDate]
    )
    const validationSchema = useMemo(
      () => {
        let validationSchema = yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr).typeError('Invalid date.');
        if (safeMinDate) validationSchema = validationSchema.min(safeMinDate, `Must be no earlier than ${format.date(safeMinDate)}`);
        if (safeMaxDate) validationSchema = validationSchema.max(safeMaxDate, `Must be no later than ${format.date(safeMaxDate)}`);
        return validationSchema;
      },
      [safeMinDate, safeMaxDate]
    )

    // RENDER
    return <Component
      {...props}
      validationSchema={validationSchema}
      ref={ref}
    />
  })
}
