import React from 'react';

// DEPENDENCIES
import clsx from 'clsx';

// GLOBAL FUNCTIONS
import { doCallback } from 'functions.js';

// CORE COMPONENTS
import Sprite from 'core/tools/Sprite';

// LOCAL COMPONENTS
import DeleteButton from './DeleteButton';

// STYLES
import styles from './uploadItem.module.scss';

// COMPONENTS
export const Info = ({ children, className }) => <div className={clsx(styles.info, className)}>{children}</div>;
export const Label = ({ children, className }) => <div className={clsx(styles.label, className)}>{children}</div>;
export const Tools = ({
  upload = {},
  tools,
  deleteButton = {},
  plaintext,
  ...props
}) => (
  <div className={styles.tools}>
    {upload.uploading || upload.deleting ? (
      <Sprite.Loader />
    ) : (<>
      {doCallback(tools, upload, props)}
      {!plaintext &&
        <DeleteButton
          {...props}
          {...deleteButton}
          {...upload}
        />
      }
    </>)}
  </div>
)

// MAIN COMPONENT
const UploadItem = ({
  as: Component = 'div',
  className,
  ...props
}) => (
  <Component
    className={clsx(
      'upload-item',
      styles.item,
      props.upload.error && styles.error,
      props.plaintext && styles.plaintext,
      className
    )}
    {...props}
  >
    <Info>
      <Label>{props.upload.meta.fileName}</Label>
    </Info>
    <Tools {...props} />
  </Component>
)

export default UploadItem;
