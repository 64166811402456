import React from 'react';

// GLOBAL VARIABLES
import { ENDPOINTS } from 'endpoints.js';
import { PATHNAMES } from 'pathnames.js';

// CORE COMPONENTS
import Form from 'core/form/Form';

// PARTS
import Record from 'parts/interface/record/Record';
import useRouteCheck from 'parts/interface/helpers/routeCheck';

// TOOLS
import CharKey from 'tools/form/CharKey';

const DEFAULT_DATA = {
  char_key: '',
  org_title: ''
}

// MAIN COMPONENT
const OrganizationTitles = ({
  form = {},
  card = {},
  // REACT ROUTER
  location,
  // REST
  ...props
}) => {
  const isEdit = useRouteCheck('edit');
  
  // RENDER
  return (
    <Record
      label="organization-title"
      idKey="org_title_id"
      documentTitle="Organization Titles"
      endpoint={ENDPOINTS.record.organizationTitle.get}
      {...props}
    >
      <Record.Form
        initialValues={DEFAULT_DATA}
        endpoint={isEdit
          ? ENDPOINTS.record.organizationTitle.put
          : ENDPOINTS.record.organizationTitle.add
        }
        redirect={PATHNAMES.organizationTitles}
        {...form}
      >
        <Record.Card.Edit>
          <Form.Body>
            <Form.Control
              name="org_title"
              label="Organization Title"
              required
            />
            <CharKey />
          </Form.Body>
        </Record.Card.Edit>
      </Record.Form>
    </Record>
  )
}

export default OrganizationTitles;

