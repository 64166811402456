import React from 'react';

// DEPENDENCIES
import { connect } from 'react-redux';

// GLOBAL FUNCTIONS
import { sidebarActions } from 'actions.js';

// CORE COMPONENTS
import Button from 'core/tools/Button';

// MAIN COMPONENT
const FilterButton = connect(
  ({ mobile }) => ({ mobile }),
  { ...sidebarActions }
)(({
  className,
  // REDUX STATE
  mobile,
  // REDUX DISPATCH
  toggle
}) => mobile ? null : (
  <Button
    className={className}
    label="Advanced Search"
    icon="search"
    onClick={() => toggle('filters')}
  />
))

// EXPORT
export default FilterButton
