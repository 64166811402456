import React, { useContext, useMemo } from 'react';

// CONTEXT
import { ListContext } from '../../helpers/getListData.js';

// PARTS
import Page from 'parts/interface/page/Page';

// LOCAL COMPONENTS
import ListButton from '../button/ListButton';

// MAIN COMPONENT
const ListHeader = ({
  buttons = [],
  ...props
}) => {

  // CONTEXT
  const { searchFilters = [] } = useContext(ListContext) || {};

  // MEMOS
  const tools = useMemo(
      () => {
        let tools = [...buttons];
        if (searchFilters.length) {
            tools.push(<ListButton.Filter />);
        }

        return tools;
      },
      [buttons, searchFilters]
  )

  // RENDER
  return (
    <Page.Header
      tools={tools}
      {...props}
    />
  )
}

// EXPORT
export default ListHeader;
